<template>
  <component
    :is="isMegaMenuMobile ? LazyCmsMegaMenuL1Mobile : LazyCmsMegaMenuL1Desktop"
    v-bind="{ content }"
    @click="handleClick"
    @expand="handleClickCollapse($event as string)"
  />
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey, MegaMenuMobileContextKey } from './context'
import { LazyCmsMegaMenuL1Desktop, LazyCmsMegaMenuL1Mobile } from '#components'
import type { MenuItem } from '#types/components/cms/mega-menu'

const props = defineProps<{ content: MenuItem }>()

const { $gtm } = useNuxtApp()

provide(MegaMenuL1ContextKey, props.content.title)

const isMegaMenuMobile = inject(MegaMenuMobileContextKey, false)

const handleClick = (label: string) => $gtm.push('topNav.onClickTopNavLink', capitalize(label))
const handleClickCollapse = (label: string) => $gtm.push('topNav.onClickMobileMenuSection', capitalize(label))
</script>
