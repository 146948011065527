<template>
  <div
    ref="elementRef"
    class="relative"
    :class="{ 'children:h-inherit': isFullHeight }"
    :style="{
      height: isFullHeight
        ? `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})`
        : undefined,
    }"
    @click="handlePromotionClick"
  >
    <define-heading-template>
      <div>
        <div v-if="eyebrowText" v-style:c="eyebrowTextColor" class="uppercase">
          {{ eyebrowText }}
        </div>
        <base-picture
          v-if="images"
          v-bind="images"
          class="max-h-38"
          fit="contain"
          :position="{ sm: textAlignmentSmall, md: textAlignmentLarge }"
        />
        <base-picture
          v-else-if="eyebrowLogo"
          :alt="eyebrowLogo.alt"
          class="max-h-38"
          fit="contain"
          :position="{ sm: textAlignmentSmall, md: textAlignmentLarge }"

          :src="eyebrowLogo.url"
        />
        <h1 v-if="title" v-style:c="titleColor" :class="titleStyle">
          <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h1>
      </div>
    </define-heading-template>
    <div
      class="relative grid"
      :class="{ 'children:h-inherit': isFullHeight }"
    >
      <div
        v-style:h="variant === 'productOverlayHero' && { sm: '31rem', md: '42rem', lg: '50rem' }"
        class="relative flex grid-area-stack"
      >
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          class="full"
          fit="cover"
          :lazy
          :props-img="{ class: 'h-full' }"
        />
        <vf-video
          v-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          class="full"
          :controls
          fit="cover"
          loop
          :meta="responsiveMedia.video.meta?.sm"
          muted
        />
        <base-link
          v-if="mediaLink"
          aria-hidden="true"
          class="absolute-0"
          tabindex="-1"
          :target="mediaLink.targetAttribute"
          :to="mediaLink.url"
        />
        <div v-style="gradient" class="absolute bottom-0 w-full" />
        <div class="absolute bottom-0 left-0 w-full flex items-end gap-4 p-4 md:pt-12">
          <heading-template
            v-if="variant === 'productOverlayHero'"
            class="hidden grow md:inline"
          />
          <base-picture
            v-if="responsiveOverlayMedia?.images"
            v-style:mb="{ sm: '-4rem', md: '-13.5rem', lg: '-10.125rem' }"
            v-bind="responsiveOverlayMedia.images"
            class="shrink-0"
            :height="{ sm: 343, md: 467, lg: 538 }"
            :width="{ sm: 196, md: 271, lg: 344 }"
          />
        </div>
      </div>
      <div
        :class="[
          brandClasses.contentContainer,
          { '$pb': firstElement && isFullHeight },
          contentAlignmentSmall[overlaySmall],
          contentSpacingSmall[overlaySmall],
          contentAlignmentLarge[overlayLarge],
          { 'md:grid-area-stack': variant === 'standardHero' },
          { 'md:py-8': variant === 'blockBelowHero' },
          { '<md:mt-8 md:pt-4': variant === 'productOverlayHero' },
                  ]"
        :style="{ '--pb': firstElement ? '6.75rem' : '' }"
      >
        <div
          v-style:bg="backdrop"
          class="space-y-2 md:p-6 md:-m-6 lg:space-y-4 "
          :class="[
            { '<md:pt-8': hasCaption },
            contentSize[overlayWidth!] || 'w-full',
            overlaySmall?.includes('overlay') && backdrop ? 'p-2' : '<md:bg-transparent',
          ]"
        >
          <heading-template :class="{ 'md:hidden': variant === 'productOverlayHero' }" />
          <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle">
            {{ subtitle }}
          </p>
          <cms-rich-text
            v-if="richText"
            v-style:c="richTextColor || subtitleStyle"
            class="pointer-within"
            :content="{ richText }"
          />
          <div
            v-if="linkType !== 'No-CTA' && targets.length"
            class="pointer-within gap-4 i-flex <md:w-full lg:gap-6 not-first:pt-4 "
            :class="{ '<md:hidden': !overlaySmall?.includes('overlay') }"
          >
            <div
              class="flex gap-4 wrap lg:gap-6 "
              :class="[ctaAlignmentLarge[overlayLarge], equalTargetSizeClass]"
              :style="`--cols: repeat(${targets.length},minmax(0,1fr))`"
            >
              <cms-shared-button
                v-for="(target, i) in targets"
                :key="i"
                                :class="{ '': overlaySmall === 'center-overlay' }"
                v-bind="{ ...target, ...getTextLinkStyles(content) }"
                :size
              />
            </div>
          </div>
          <cms-rich-text
            v-if="richTextBelowTargets"
            v-style:c="richTextBelowTargetsColor || subtitleStyle"
            class="pointer-within"
            :class="{ '<md:hidden': !overlaySmall?.includes('overlay') }"
            :content="{ richText: richTextBelowTargets }"
          />
        </div>
      </div>
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleStyle"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
    </div>
    <div
      v-if="linkType !== 'No-CTA' && targets.length && !overlaySmall?.includes('overlay')"
      class="f-col gap-4 px-4 md:!hidden"
      :class="[
        equalTargetSize?.sm ? '' : ctaAlignmentSmall[overlaySmall],
        variant === 'standardHero' ? 'mt-6' : 'mt-2',
        equalTargetSizeClass,
      ]"
      :style="`--cols: repeat(${targets.length},minmax(0,1fr))`"
    >
      <cms-shared-button
        v-for="(target, i) in targets"
        :key="i"
        v-bind="{ ...target, ...getTextLinkStyles(content) }"
        :size
      />
    </div>
    <cms-rich-text
      v-if="richTextBelowTargets"
      v-style:c="richTextBelowTargetsColor || subtitleStyle"
      class="pointer-within mt-2 px-4 md:hidden"
      :class="[
        contentAlignmentSmall[overlaySmall],
        { '<md:hidden': overlaySmall?.includes('overlay') },
      ]"
      :content="{ richText: richTextBelowTargets }"
    />
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { HeroContent, OverlayLarge, OverlaySmall } from '#types/components/cms/hero'
import type { Responsive } from '#types/common'

const props = withDefaults(defineProps<{
  content: HeroContent
  controls?: boolean
  paused?: boolean
  firstElement?: boolean
}>(), { controls: true })

const {
  backdrop,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowLogoResponsive,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = { sm: true },
  gradientStyle,
  gradientTone,
  isFullHeight,
  linkType,
  media,
  mediaTarget,
  name,
  overlayLarge,
  overlaySmall,
  overlayWidth,
  overlayPicture,
  promotionTracking,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
  variant = 'standardHero',
  mediaList
} = props.content

const [DefineHeadingTemplate, HeadingTemplate] = createReusableTemplate()

const { size } = useAppConfig().components.cms.sharedButton
const { brandClasses } = useAppConfig().components.cms.hero
const { getMedia } = useCms()
const header = useHeaderStore()

const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref<HTMLVideoElement>()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = (mediaList || [media]).map(getMedia)[0]
const responsiveOverlayMedia = overlayPicture && getMedia(overlayPicture)
const { images } = getMedia(eyebrowLogoResponsive)

const contentAlignmentLarge: Record<OverlayLarge, string> = {
  'left-left-top': 'md:items-start',
  'left-left-middle': 'md:items-center',
  'left-left-bottom': 'md:items-end',
  'left-right-middle': 'md:justify-end md:items-center',
  'center-left-middle': 'md:text-center md:items-center',
  'center-center-top': 'md:text-center md:justify-center md:items-start',
  'center-center-middle': 'md:text-center md:center',
  'center-center-bottom': 'md:text-center md:justify-center md:items-end',
  'center-right-middle': 'md:text-center md:justify-end md:items-center'
}

const contentAlignmentSmall: Record<OverlaySmall, string | any[]> = {
  'left-overlay': [{ '<md:grid-area-stack': variant === 'standardHero' }, '<md:pointer-events-none <md:items-center'],
  'left-bottom-overlay': [{ '<md:grid-area-stack': variant === 'standardHero' }, '<md:pointer-events-none <md:items-end'],
  'left-below': '<md:mt-6 <md:items-start',
  'center-overlay': [{ '<md:grid-area-stack': variant === 'standardHero' }, '<md:pointer-events-none <md:center <md:text-center'],
  'center-below': '<md:center <md:text-center'
}

const contentSpacingSmall: Record<OverlaySmall, string> = {
  'left-overlay': '<md:p-4',
  'left-bottom-overlay': '<md:p-4',
  'left-below': '<md:mt-6 <md:px-4',
  'center-overlay': '<md:p-4',
  'center-below': '<md:mt-6 <md:px-4'
}

const contentSize = {
  xs: 'w-10/12 md:w-1/3',
  sm: 'w-10/12 md:w-1/2',
  md: 'w-11/12 md:w-2/3',
  lg: 'w-full md:w-11/12'
}

const ctaAlignmentLarge: Partial<Record<OverlayLarge, string>> = {
  'center-left-middle': 'md:justify-center',
  'center-center-top': 'md:justify-center',
  'center-center-middle': 'md:justify-center',
  'center-center-bottom': 'md:justify-center',
  'center-right-middle': 'md:justify-center'
}

const ctaAlignmentSmall: Record<OverlaySmall, string> = {
  'left-overlay': '<md:items-start',
  'left-bottom-overlay': '<md:items-start',
  'left-below': '<md:items-start',
  'center-overlay': '<md:items-center',
  'center-below': '<md:items-center'
}

const textAlignmentLarge = overlayLarge?.split('-')[0] || 'left'
const textAlignmentSmall = overlaySmall?.split('-')[0] || 'center'

const gradientStyles = { full: 35, focused: 50, off: 0 }

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))

watch(() => props.paused, (paused) => paused ? videoRef.value?.pause() : videoRef.value?.play())
</script>
