/**
 * How to display the currency in currency formatting. Possible values are:
 * - "code" to use the ISO currency code,
 * - "symbol" to use a localized currency symbol such as €, this is the default value,
 * - "narrowSymbol" to use a narrow format symbol ("$100" rather than "US$100"),
 * - "name" to use a localized currency name such as "dollar",
 * @param currency
 */
export const getCurrencyDisplay = (currency: string): Intl.NumberFormatOptions['currencyDisplay'] => {
  switch (currency) {
    case 'CAD':
      return 'code'
    default:
      return 'symbol'
  }
}

/**
 * returns the currency symbol
 * @param {string} locale - User Locale
 * @param {string} currency - Product currency passed from API
 * @category Utils
 */
export const getCurrencySymbol = (locale: string, currency: string) => new Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: getCurrencyDisplay(currency), currency })
  .formatToParts(1)
  .find((x: Intl.NumberFormatPart) => x.type === 'currency')
  ?.value || ''
