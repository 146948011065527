/**
 * @remark Store could be anything like user, cart, screen.
 * The first argument is a unique id of the store across your application
 */
import { skipHydrate } from 'pinia'
import { ApiErrorCode } from '#root/enums/api'

// helper to reduce cognitive complexity
const isEmployeeDiscountTermsNotAccepted = (consumerType, isEmployeeDiscountTCAccepted) =>
  consumerType === 'EMPLOYEE' && !isEmployeeDiscountTCAccepted

export const useAuthStore = (countryCode = useCountryCode()) => defineStore(`auth_${countryCode}`, () => {
  const { $t } = useNuxtApp()
  const { enableLoyaltyFeatures } = useFeatureFlags()
  const { DialogEmployeeTerms } = useDialogsStore()
  const { authentication, consumer } = useApi()
  const userStore = useUserStore()
  const cartStore = useCartStore()
  const loyaltyStore = useLoyaltyStore()
  const route = useRoute()
  const { allowSharedCookies } = useAppConfig().api
  const favoritesStore = useFavoritesStore()

  const consumerNo = ref()
  const consumerType = ref()
  const xUsidCookie = useCookie('x-usid')
  const loggedInCookie = useApiCookie(cookiesPostfix.Login)
  const loggedIn = skipHydrate(computed(() => import.meta.client ? !!loggedInCookie.value : undefined))
  const inStoreEmployeeLoggedInCookie = useApiCookie(cookiesPostfix.Employee)
  const inStoreEmployeeLoggedIn = skipHydrate(computed(() => import.meta.client ? !!inStoreEmployeeLoggedInCookie.value : undefined))
  const memberId = ref()
  const refreshToken = ref()
  const isEmployeeDiscountTCAccepted = skipHydrate(useLocalStorage('isEmployeeDiscountTCAccepted', false))

  const setAccess = async () => {
    // request a guest access token if it does not exist
    const accessToken = useApiCookie(cookiesPostfix.Access)

    if (loggedIn.value || accessToken.value) {
      // When sharing cookies, we always need to update our cart when we hit the page because sometimes users could have changed their carts on the other website.
      // We are ignoring this logic on the cart and checkout because these pages already load the cart
      if (allowSharedCookies && !route.fullPath.includes('cart') && !route.fullPath.includes('checkout'))
        await cartStore.get()

      return
    }

    try {
      await authentication.$token({
        type: 'Guest'
      })
    }
    catch (err: any) {
      log.error(`[@domains/commerce/stores/auth.ts::setAccess] Error type:<${err.status} ${err.statusText}>`)
      if (err.status !== 403)
        assertApiError(err)
    }

    refreshCookie('x-usid')
  }

  const login = async (
    username: string,
    password: string,
    captchaResponse: string,
    options: { allowRedirect: boolean } = { allowRedirect: true }
  ) => {
    // capture data from the order to be provided when signing in
    const { order: { orderNumber = '', st = '' } } = useCheckoutStore()

    const codeVerifier = base64URLEncode(generateRandomBytes(32))
    const codeChallenge = base64URLEncode(await sha256(codeVerifier))

    const signInData = await authentication.$signIn({
      type: 'Registered',
      username,
      password,
      codeChallenge,
      recaptcha_response: captchaResponse,
    })

    const { authorizationCode } = signInData

    // register token
    let authTokenResponse
    try {
      // interim solution for GLOBAL15-62534
      authTokenResponse = await authentication.$token({
        type: 'Registered',
        authorizationCode,
        codeVerifier,
        guestObjects: {
          basketId: cartStore.basketId || '',
          favoriteId: favoritesStore.favoriteId,
          saveForLaterId: cartStore.savedItems.saveForLaterId || ''
        },
        ...(orderNumber
          ? {
              orderNo: orderNumber,
              st,
              action: 'postLogin'
            }
          : {})
      }, {
        retry: 1,
        retryDelay: 2000,
        retryStatusCodes: [409]
      })
      // redirect is not allowed while user links order from order-confirmation
      if (orderNumber && options.allowRedirect) {
        // we need to wait until refreshApiCookie succeed
        // to pass through account auth guard
        watchOnce(loggedIn, () => {
          navigateTo('/account')
        })
      }
    }
    catch (err: any) {
      log.error(`[@domains/commerce/stores/auth.ts::login] Error type:<${err.status} ${err.statusText}>`)
      if (err.status !== 403) {
        throw new Error($t.apiMessages[ApiErrorCode.DUPLICATED_TOKEN], {
          cause: err
        })
      }
    }

    const {
      basketId: userBasketId,
      consumerNo: userConsumerNo,
      consumerType: userConsumerType,
      favorites,
      isEmployeeDiscountTCAccepted: isTCAccepted,
      memberId: userMemberId,
      refreshToken: userRefreshToken
    } = authTokenResponse

    isEmployeeDiscountTCAccepted.value = isTCAccepted

    if (userBasketId) cartStore.updateCartId(userBasketId, true)

    // this also triggers the favorites load, so favorites merge is obtained at sign in
    if (favorites) {
      favoritesStore.setFavoriteId(favorites.favoriteId)
      favorites.favStoreId && cartStore.setFavoriteStoreId(favorites.favStoreId)
    }
    // saved for later list
    cartStore.fetchSavedCartItems()
    // Needed to determine whether athlete credit is available so we know whether loyalty rewards are allowed
    cartStore.getPaymentMethod()
    consumerNo.value = userConsumerNo
    consumerType.value = userConsumerType
    memberId.value = userMemberId
    refreshToken.value = userRefreshToken

    refreshCookie('x-usid')
    refreshApiCookie(cookiesPostfix.Login)

    // Await openEmployeeTermsModal to resolve, prevent the modal be closed by page redirection
    if (isEmployeeDiscountTermsNotAccepted(userConsumerType, isTCAccepted))
      isEmployeeDiscountTCAccepted.value = await DialogEmployeeTerms.open()
  }

  // user logout
  const logout = async () => {
    const router = useRouter()
    const toast = useToaster()

    // We need to redirect to home page if we're on these routes
    const routesToRedirectFrom = ['cart', 'checkout', 'favorites', 'account']
    route.path?.split('/').some((p) => routesToRedirectFrom.includes(p))
    && await router.push(useLocalisedRoute('/'))

    await authentication.$signOut({
      refreshToken: refreshToken.value
    })

    // clear token cookie
    loggedInCookie.value = null
    inStoreEmployeeLoggedInCookie.value = null
    xUsidCookie.value = null

    // clear user data
    consumerNo.value = null
    consumerType.value = null
    memberId.value = null
    refreshToken.value = null
    isEmployeeDiscountTCAccepted.value = false

    // clear user
    userStore.reset()

    // clear cart
    cartStore.reset()

    // clear loyalty
    loyaltyStore.reset()

    // clear the favorites
    favoritesStore.setFavoriteId(null)
    favoritesStore.setFavoritesCount(0)

    // request new guest token
    try {
      await authentication.$token({
        type: 'Guest'
      })
    }
    catch (err: any) {
      log.error(`[@domains/commerce/stores/auth.ts::logout] Error type:<${err.status} ${err.statusText}>`)
      if (err.status !== 403)
        assertApiError(err)
    }

    toast.add({
      props: {
        title: 'Success',
        message: $t.signOutSuccess,
        type: 'success'
      }
    })
  }

  /**
   * Get member ID from loyalty token API
   * When not a loyalty member, return undefined
   * @returns {string} memberId
   */
  const getMemberId = async () => {
    if (enableLoyaltyFeatures && userStore.isLoyaltyMember && !memberId.value) {
      const loyaltyResponse = await consumer.$getLoyaltyToken({
        usid: xUsidCookie.value || uuid()
      }, {
        onRequest: (context) => {
          if (route.query.leadSource) {
            context.options.headers = {
              ...context.options.headers,
              source: route.query.leadSource as string
            }
          }
        }
      })
      memberId.value = loyaltyResponse.memberId
    }

    return memberId.value
  }

  return {
    consumerId: computed(() => loggedInCookie.value || xUsidCookie.value),
    consumerNo,
    consumerType,
    inStoreEmployeeLoggedIn, // by in store we mean an employee logged in by poslogon
    isEmployee: computed(() => consumerType.value === 'EMPLOYEE'), // employee is logged with a default login form
    getMemberId,
    isEmployeeDiscountTCAccepted,
    loggedIn,
    login,
    logout,
    memberId,
    setAccess
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    paths: ['consumerNo', 'consumerType']
  }
})()
