import type { UseFormattedPriceOptions } from '#types/composables/useFormattedPrice'
import { getCurrencyDisplay } from '#commerce/utils/getCurrencySymbol'

/**
 * Formats a localized price.
 * @param price - Price to format
 * @param currency - Currency to apply
 * @example
 * useFormattedPrice(50.32, 'USD')
 * returns $50.32
 * @category Utils
 */
// TODO: Refactor to return formatter function
export const useFormattedPrice = (price: number, currency = 'USD', options: UseFormattedPriceOptions = {}) => {
  // if currency is falsy, return the value
  if (!currency) return price
  return new Intl.NumberFormat(useLocale(), { style: 'currency', currency, currencyDisplay: getCurrencyDisplay(currency), ...options }).format(0 + price)
}
