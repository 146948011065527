import { getLogConfig } from '#core/utils/logger'

export const pwa = {
  enabled: process.env.FEATURE_PWA !== 'false',
  installPrompt: process.env.PWA_INSTALL_PROMPT === 'true',
  dev: process.env.PWA_DEV === 'true'
}

export const prometheus = {
  enabled: process.env.FEATURE_PROMETHEUS !== 'false',
  host: process.env.PROMETHEUS_HOST || '0.0.0.0',
  port: Number.parseInt(process.env.PROMETHEUS_PORT || '9091'),
  customMetrics: {
    // NOTE Look into moving these to feature flags
    render: {
      ssr: process.env.PROMETHEUS_CUSTOM_METRICS_RENDER_SSR !== 'false',
      csr: process.env.PROMETHEUS_CUSTOM_METRICS_RENDER_CSR !== 'false',
      webVitals: process.env.PROMETHEUS_CUSTOM_METRICS_WEB_VITALS !== 'false',
      hydration: process.env.PROMETHEUS_CUSTOM_METRICS_RENDER_HYDRATION !== 'false'
    }
  }
}

export const htmlValidator = {
  enabled: process.env.FEATURE_HTML_VALIDATOR !== 'false'
}

export const gtm = {
  enabled: process.env.FEATURE_GTM !== 'false'
}

export const devtools = {
  enabled: process.env.FEATURE_DEVTOOLS !== 'false'
}

export const performanceLogging = {
  enabled: process.env.FEATURE_PERFORMANCE_LOGGING !== 'false'
}

export const logger = getLogConfig()

export const storybook = {
  enabled: process.env.STORYBOOK === 'true'
}
