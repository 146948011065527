<template>
  <div
    ref="elementRef"
    v-style:bg="backgroundColor"
    class="relative grid"
    :class="{ 'children:h-inherit': isFullHeight }"
    :style="{
      height: isFullHeight
        ? `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})`
        : undefined,
    }"
    @click="handlePromotionClick"
  >
    <div class="relative md:grid-area-stack">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        class="full"
        fit="cover"
        :lazy
        :props-img="{ class: 'h-full' }"
      />
      <vf-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        class="full"
        :controls
        fit="cover"
        loop
        muted
      />
      <div v-style="gradient" class="absolute bottom-0 w-full" />
      <base-link
        v-if="mediaLink"
        aria-hidden="true"
        class="absolute-0"
        tabindex="-1"
        :target="mediaLink.targetAttribute"
        :to="mediaLink.url"
      />
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleColor"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
    </div>
    <div
      class="z-1 md:pointer-events-none md:flex md:grid-area-stack <md:my-6"
      :class="[
        contentAlignmentSmall[alignmentSmall],
        contentAlignmentLarge[alignmentLarge],
        textsAlignmentSmall[textAlignmentSmall],
        textsAlignmentLarge[textAlignmentLarge],
        { 'items-center': !!contentAlignmentLargeOld[alignmentLarge] },
        brandClasses.containerWrapper,
      ]"
    >
      <div
        v-style:bg="backdrop"
        :class="[
          overlayWidth ? contentSize[overlayWidth] : brandClasses.containerWidth,
          brandClasses.container,
                  ]"
      >
        <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
          {{ eyebrowText }}
        </div>
        <base-picture
          v-if="images"
          v-bind="images"
          class="max-h-38"
          fit="contain"
          :props-img="{ class: 'object-left' }"
        />
        <base-picture
          v-else-if="eyebrowLogo"
          :alt="eyebrowLogo.alt"
          class="max-h-38"
          fit="contain"
          :props-img="{ class: 'object-left' }"
          :src="eyebrowLogo.url"
        />
        <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
          {{ overline }}
        </p>
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="[titleStyle, { 'sr-only': hideTitle }]"
          data-test-id="cms-banner-title"
        >
          <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h2>
        <p
          v-if="subtitle"
          v-style:c="subtitleColor"
          :class="subtitleStyle"
          data-test-id="cms-banner-text"
        >
          {{ subtitle }}
        </p>
        <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="pointer-within f-col gap-4 md:flex-row md:items-center md:wrap lg:gap-6 not-first:pt-4 "
          :class="[
            ctaAlignmentSmall[alignmentSmall],
            ctaAlignmentSmall[textAlignmentSmall],
            ctaAlignmentLarge[alignmentLarge],
            ctaAlignmentLarge[textAlignmentLarge],
            equalTargetSizeClass,
          ]"
          :style="{ '--cols': cols }"
        >
          <cms-shared-button
            v-for="(target, i) in targets"
            :key="i"
            v-bind="{ ...target, ...getTextLinkStyles(content) }"
            :size
            :text-link-color="textLinkColor"
            :text-link-hover-color="textLinkHoverColor"
            :text-link-style="textLinkStyle"
            :text-link-underline="textLinkUnderline"
          />
        </div>
        <cms-rich-text
          v-if="richTextBelowTargets"
          v-style:c="richTextBelowTargetsColor || subtitleColor"
          class="pointer-within"
          :content="{ richText: richTextBelowTargets }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type {
  BannerImageAlignmentLarge,
  BannerImageAlignmentSmall,
  BannerImageContent
} from '#types/components/cms/banner-image'
import type { TextAlignment } from '#types/components/cms/text-alignment'
import type { Responsive } from '#types/common'
import type { Target } from '#types/target'

const props = withDefaults(defineProps<{
  content: BannerImageContent
  controls?: boolean
  paused?: boolean
  firstElement?: boolean
}>(), { controls: true })

const {
  alignmentLarge,
  alignmentSmall,
  backdrop,
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowLogoResponsive,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  isFullHeight,
  linkType,
  media,
  mediaTarget,
  overlayWidth,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [] as Target[],
  textAlignmentLarge,
  textAlignmentSmall,
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  textLinkUnderline,
  title,
  titleColor,
  titleStyle,
  hideTitle,
} = props.content

const { size } = useAppConfig().components.cms.sharedButton
const { brandClasses } = useAppConfig().components.cms.imageBanner
const { getMedia } = useCms()
const header = useHeaderStore()

const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, props.content.name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const { images } = getMedia(eyebrowLogoResponsive)

const contentAlignmentLargeOld: Partial<Record<BannerImageAlignmentLarge, string>> = {
  'left-center': 'md:justify-center',
  'left-right': 'md:justify-end',
  'center-left': 'md:text-center',
  'center-center': 'md:text-center md:justify-center',
  'center-right': 'md:text-center md:justify-end',
}

const contentAlignmentLarge: Partial<Record<BannerImageAlignmentLarge, string>> = {
  // old
  ...contentAlignmentLargeOld,
  // new
  'bottom-center': 'md:items-end md:justify-center',
  'bottom-left': 'md:items-end',
  'bottom-right': 'md:items-end md:justify-end',
  'middle-center': 'md:items-center md:justify-center',
  'middle-left': 'md:items-center',
  'middle-right': 'md:items-center md:justify-end',
  'top-center': 'md:items-start md:justify-center',
  'top-left': 'md:items-start',
  'top-right': 'md:items-start md:justify-end',
}

const contentAlignmentSmall: Partial<Record<BannerImageAlignmentSmall, string>> = {
  center: '<md:items-center <md:text-center',
  overlay: '<md:items-end <md:absolute <md:bottom-0 <md:h-a <md:w-full',
}

const textsAlignmentSmall: Record<TextAlignment, string> = {
  left: 'text-start',
  center: 'text-center',
  right: 'text-end'
}

const textsAlignmentLarge: Record<TextAlignment, string> = {
  left: 'md:text-start',
  center: 'md:text-center',
  right: 'md:text-end'
}

const ctaAlignmentLarge: Partial<Record<BannerImageAlignmentLarge | TextAlignment, string>> = {
  'center-left': 'md:text-center md:justify-center',
  'center-center': 'md:text-center md:justify-center',
  'center-right': 'md:text-center md:justify-center',
  'left': 'md:text-start md:justify-start',
  'center': 'md:text-center md:justify-center',
  'right': 'md:text-end md:justify-end'
}

const ctaAlignmentSmall: Record<BannerImageAlignmentSmall | TextAlignment, string> = {
  left: '<md:items-start',
  center: '<md:items-center <md:text-center',
  right: '<md:items-end',
  overlay: ''
}

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-$cols <md:thenothface:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr'
].filter(Boolean).join(' ')

const gradientStyles = { full: 35, focused: 50, off: 0 }

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const hasCaption = captionText && captionPosition !== 'off'

const contentSize = {
  xs: 'md:w-1/3',
  sm: 'md:w-1/2',
  md: 'md:w-2/3',
  lg: 'md:w-11/12'
}

const cols = targets.map((target) => target.style?.sm === 'text-link' ? 'max-content' : 'minmax(0,1fr)').join(' ')

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))

watch(() => props.paused, (paused) => paused ? videoRef.value?.pause() : videoRef.value?.play())
</script>
