<template>
  <base-grid class="container" :cols="cols" :gap-x="grid.gaps.x" :gap-y="grid.gaps.y">
    <product-card-skeleton v-for="el in 12" :key="el" :show-swatches="$feature.showSwatchesInProductList" />
    <div
      v-for="section in map"
      :key="section.name"
      v-style="{
        'grid-col': section.column,
        'row-start': section.row,
        'display': section.display,
      }"
    >
      <cms-banner-espot :content="component as BannerEspotContent" />
    </div>
  </base-grid>
</template>

<script setup lang="ts">
import type { Responsive } from '#types/common'
import type { BannerEspotContent } from '#types/components/cms/banner-espot'
import type { InlineGridItem } from '#types/components/product/list'

const props = defineProps<{ content: InlineGridItem }>()

const { component } = props.content

const cols = { sm: 2, md: 3, lg: 4 }
const grid = {
  gaps: {
    x: { sm: 'sm' },
    y: { sm: 'md', md: 'lg', lg: 'xl' }
  }
}

const inlineSectionMap = (cols: Responsive<number>, c: InlineGridItem[]) => {
  return getResponsivePosition(c, cols, 12)
}

const map = inlineSectionMap(cols, [props.content])
</script>
