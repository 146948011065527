<template>
  <component
    :is="youtubeId ? BaseYoutube : BaseVideo"
    v-bind="video"
    :id="youtubeId"
    :autoplay
    class="w-full"
    :class="{ 'aspect-video': youtubeId }"
    :controls="!hideControls"
    :loop
    :muted
  />
</template>

<script lang="ts" setup>
import { BaseVideo, BaseYoutube } from '#components'
import type { VideoPlayerContent } from '#types/components/cms/video-player'

const { content } = defineProps<{
  content: VideoPlayerContent
}>()

const {
  media,
  playerSettings: { autoplay, hideControls, loop, muted } = {}
} = content

const { getMedia } = useCms()

const getYoutubeId = (url: string): string | undefined => {
  const regex = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))(?<id>[^?&"'>]+)/
  return url.match(regex)?.groups?.id
}

const source = media && (media.sm?.url || media.md?.url || media.lg?.url)
const youtubeId = source && getYoutubeId(source)

const video = youtubeId ? null : getMedia(media).video
</script>
