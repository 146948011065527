export const enum EnrollmentState {
  LOOKUP,
  SIGNIN,
  SIGNUP,
  FORGOT_PASSWORD,
  COMPLETE_PROFILE_0_NON_LOYALTY,
  COMPLETE_PROFILE_1_WELCOME,
  COMPLETE_PROFILE_2_MOBILE,
  COMPLETE_PROFILE_3_PROFILE,
  COMPLETE_PROFILE_4_INTERESTS,
  COMPLETE_PROFILE_5_SUCCESS
}
