<template>
  <cms-section
    v-if="displayed"
    :first-section="firstElement"
    page-context
    :section="{ name: 'filter-by', items: components }"
  />
</template>

<script setup lang="ts">
import type { FilterBy } from '#types/components/cms/filter-by'

const { content } = defineProps<{
  content: FilterBy
  firstElement?: boolean
}>()

const { variant, filterBy, operator, components } = content

const route = useRoute()

const variantsMap = {
  bySku: getUrlId(route.path, 'Product'),
  byCategory: getUrlId(route.path, 'Category')
}

const operatorsMap = {
  'IN': (data: string) => filterBy.includes(data),
  'NOT IN': (data: string) => !filterBy.includes(data)
}

const displayed = variantsMap[variant] && operatorsMap[operator]?.(variantsMap[variant])
</script>
