<template>
  <div>
    <vf-loader v-if="loading" class="container" style="height: 25rem" />
    <div v-else-if="data">
      <div v-if="data.result?.length" class="divide-y divide-grey-70">
        <div v-for="item in data.result" :key="item.id">
          <pre class="py-4 text-center container">{{ item.id }} | {{ item.name }}</pre>
          <component
            :is="component"
            :content="item"
            page-context
            :section="{ name: 'preview', items: [item] }"
          />
        </div>
      </div>
      <div v-else class="text-center">
        No results.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import previewMappings from '#content/mappings/preview'
import { CmsSection } from '#components'

const { attachListeners } = defineProps<{
  attachListeners?: boolean
}>()

const route = useRoute()

const { getShowcase } = useCms()

const data = ref()
const component = shallowRef()
const loading = ref(false)

watchImmediate(() => route.query, async (value, oldValue) => {
  if (value.docType && JSON.stringify(value) !== JSON.stringify(oldValue)) {
    loading.value = true
    component.value = previewMappings[value.docType.toString()] || CmsSection
    try {
      data.value = await getShowcase(value.docType.toString(), value.layoutVariant?.toString(), +(value.limit || '20'))
    }
    catch {
      data.value = undefined
    }
    finally {
      loading.value = false
    }
  }
})

const onScroll = useDebounceFn(() => {
  parent.postMessage(
    {
      type: 'syncScroll',
      scrollTop: window.scrollY
    }
  )
})

const onMessage = (event: MessageEvent) => {
  if (event.data.type === 'syncScroll')
    window.scrollTo({ top: event.data.scrollTop })
}

onMounted(() => {
  if (attachListeners) {
    window.addEventListener('message', onMessage)
    window.addEventListener('scroll', onScroll)
  }
})

onUnmounted(() => {
  if (attachListeners) {
    window.removeEventListener('message', onMessage)
    window.removeEventListener('scroll', onScroll)
  }
})
</script>
