export const useLoyaltyStore = defineStore('loyalty', () => {
  const cart = useCartStore()
  const user = useUserStore()
  const { consumer } = useApi()
  const {
    data: vouchers,
    error: vouchersError,
    pending: vouchersPending,
    execute: getVouchers
  } = consumer.getLoyaltyVouchers({ immediate: false })

  const currentReward = computed(() => {
    const currentPoints = vouchers.value?.currentLoyaltyPoints || 0
    const amount = vouchers.value?.applicableLoyaltyVouchers?.reduce((acc, voucher) => {
      return currentPoints >= voucher.points ? Number(voucher.amountInCurrency) : acc
    }, 0) || 0

    return useFormattedPrice(amount, useRuntimeConfig().public.currency[useLocale()], { minimumFractionDigits: 0 })
  })

  const reset = () => {
    vouchers.value = null
  }

  const {
    data: rewardCodes,
    pending: rewardCodesPending,
    error: rewardCodesError,
    execute: getRewardCodes
  } = consumer.getPaymentInstruments({ payment_method_id: 'REWARD_CODE' }, { key: 'REWARD_CODE', immediate: false })

  const {
    data: rewardCards,
    pending: rewardCardsPending,
    error: rewardCardsError,
    execute: getRewardCards
  } = consumer.getPaymentInstruments({ payment_method_id: 'REWARD_CARD' }, {
    immediate: false,
    transform: (rewards) => rewards.map(({ card }) => card)
  })

  const getRewards = async () => Promise.all([getRewardCodes(), getRewardCards()])

  const rewardsAvailable = computed(() => [
    ...rewardCodes.value || [],
    ...rewardCards.value || []
  ])

  const rewardsPending = computed(() => rewardCodesPending || rewardCardsPending)

  const rewardsError = computed(() => rewardCodesError || rewardCardsError)

  const athletePayment = computed(() => {
    const athleteMethod = cart.paymentMethods.find(({ code }) => code === 'ATHLETES_PAYMENT')

    const remainingAmount = athleteMethod?.additionalData?.remainingAmount ?? 0
    const payableAmount = athleteMethod?.additionalData?.payableAmount ?? 0

    return user.isAthleteProfile ? { remainingAmount, payableAmount } : undefined
  })

  const rewardsPaymentAllowed = computed(() => !athletePayment.value || athletePayment.value.remainingAmount === 0)

  return {
    athletePayment,
    currentReward,
    getVouchers,
    getRewards,
    reset,
    rewardsAvailable,
    rewardsError,
    rewardsPaymentAllowed,
    rewardsPending,
    vouchers,
    vouchersError,
    vouchersPending
  }
})
