import type { Translations } from '#brand/types/i18n'
import type { Shipments } from '#types/components/checkout/order'
import type {
  ApplicableShippingMethod,
  CartItem,
  ShippingMethod,
  ShippingMethodCode,
  ShippingMethodItem,
  ShippingOption
} from '#types/cart'
import type { TrackOrderData } from '#root/api/clients/consumer/data-contracts'
import type { PlaceOrderData } from '#root/api/clients/cart/data-contracts'

type ShippingItem = ShippingMethodItem & { deliveryTime: string }

type TrackOrderShipment = TrackOrderData[number]['shipments'][number][number]
type PlaceOrderShipment = PlaceOrderData['shipments'][number]

type ShippingInput =
  | TrackOrderShipment
  | PlaceOrderShipment
  | ShippingOption
  | ApplicableShippingMethod
  | ShippingMethod
  | ShippingItem
  | ShippingMethodCode
  | undefined

/**
 * Retrieves the code from the provided shipping input.
 * @param input The shipping input object.
 * @returns The code extracted from the input.
 * @category Utils
 */
const getCode = (input: ShippingInput) =>
  (input as Shipments | PlaceOrderShipment | ShippingOption)?.shippingMethod?.code
  || (input as ApplicableShippingMethod | ShippingMethod | ShippingItem)?.code
  || (input as TrackOrderShipment)?.shipmentGroupId
  || input

/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code involves delivery to store
 * @param input - the item to check
 * @returns True if the shipping method code is to store
 * @category Utils
 */
export const isPickup = (input: ShippingInput) => {
  const code = getCode(input)
  return code === 'pickup' || code === 'pudo'
}
/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code involves delivery to store
 * @param input - the item to check
 * @returns True if the shipping method code is to store
 * @category Utils
 */
export const isSts = (input: ShippingInput) => getCode(input) === 'sts'
/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code involves delivery to store
 * @param input - the item to check
 * @returns True if the shipping method code is to store
 * @category Utils
 */
export const isPickupOrSts = (input: ShippingInput) => {
  const code = getCode(input)
  return isPickup(code) || isSts(code)
}
/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code involves digital delivery
 * @param input - the item to check
 * @returns True if the shipping method code is digital
 * @category Utils
 */
export const isDigitalDelivery = (input: ShippingInput) => getCode(input) === 'digital'

/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code does not involve digital delivery
 * @param input - the item to check
 * @returns True if the shipping method code is not digital
 * @category Utils
 */
export const isPhysicalDelivery = (input: ShippingInput) => !isDigitalDelivery(input)

/**
 * Checks whether the provided shipment, shipping option,
 * shipping method or shipping methods code does not involve digital delivery
 * @param input - the item to check
 * @returns True if the shipping method code is customs
 * @category Utils
 */
export const isCustomsDelivery = (input: ShippingInput) => getCode(input) === 'customs'

/**
 * Determines what summary label should be displayed for a given shipment or shipping method based on its type
 * @param $t = the translations object to get the label from
 * @param input - the item to check
 * @returns The shipment type summary label
 * @category Utils
 */
export const getShippingMethodLabel = ($t: Translations['texts'], input: ShippingInput) => {
  if (isPickup(input))
    return $t.pickupInStore

  if (isSts(input))
    return $t.shipToStore

  if (isDigitalDelivery(input))
    return $t.giftCardAsProduct.shippingLabel

  return $t.shipToHome
}

/**
 * Extracts the shipping method for any digital cart items
 * @param items - the items to check for digital delivery
 * @returns The shipping method for digital items, if any
 * @category Utils
 */
export const getDigitalShippingMethodFromItems = (items: CartItem[]) => {
  const digitalItem = items.find(({ isDigitalProduct }) => isDigitalProduct)
  const digitalOption = digitalItem?.shippingOptions.find(isDigitalDelivery)
  return digitalOption?.shippingMethod
}

/**
 * Extracts the shipping method for any digital shipments
 * @param shipments - the shipments to check for digital delivery
 * @returns The shipping method for digital shipment, if any
 * @category Utils
 */
export const getDigitalShippingMethodFromShipments = (shipments: PlaceOrderShipment[]) => {
  return shipments.find(isDigitalDelivery)?.shippingMethod
}

/**
 * Receives the shipments from the order confirmation or shipping methods from the cart to show the real shipping price adding the promotions
 * @param shipments - shipments from the order confirmation or shipping methods from the cart
 * @returns Total shipping methods for digital teams.
 * @category Utils
 */
export const getTotalShippingPrice = (shipments: Shipments[] | ShippingMethod[]) => {
  return shipments.map((sh) => {
    const promoDiscount = Math.abs(sh.shippingPromotions?.find((promo) => promo.appliedDiscount)?.price || 0)
    return promoDiscount + (sh.cost || sh.shippingMethod?.price.amount || 0)
  }).reduce((curr, agg) => curr + agg, 0)
}

/**
 * Extracts an applicable shipping method details from a shipping method object
 * @param shippingMethod - shipping method from the cart
 * @returns applicable shipping method object.
 * @category Utils
 */
export const getApplicableShippingMethod = (shippingMethod: ShippingMethod): ApplicableShippingMethod | undefined => {
  if (!shippingMethod.applicableShippingMethods) return
  const { applicableShippingMethods, code } = shippingMethod
  return applicableShippingMethods.find((method) => method.code === code)
}

/**
 * Extracts applicableShippingMethod from a list containing digital shipping method
 * @param shippingMethods - the shippingMethods to check for digital delivery
 * @returns The applicable shipping method for digital shipment, if any
 * @category Utils
 */
export const getDigitalShippingMethodFromShippingMethods = (shippingMethods: ShippingMethod[]) => {
  const digitalShippingMethod = shippingMethods.find(isDigitalDelivery)
  return digitalShippingMethod && getApplicableShippingMethod(digitalShippingMethod)
}
