<template>
  <div
    class="w-full"
    :class="brandClasses.container"
    style="min-height: 5rem"
    @click="$emit('recClick', upsell.id)"
  >
    <p v-if="title" class="mb-4" :class="[brandClasses.title, classTitle]">
      {{ title }}
    </p>
    <div class="flex">
      <div class="h-full flex">
        <vf-checkbox
          v-if="showCheckbox"
          class="self-center pr-4"
          :model-value="modelValue"
          :size="checkboxSize"
          :value="upsell.sku"
          @click="handleGtmEvent($event)"
          @update:model-value="$emit('update:modelValue', $event)"
        />
        <component
          :is="showModal ? BaseButton : BaseLink"
          :to="upsell.url"
          @click="showModal ? openQuickshop() : upsellDetail()"
        >
          <base-picture
            :alt="upsell.name"
            :class="brandClasses.details.image"
            :height="image.height"
            :src="getImageTransformations(upsell.image, image)"
            :style="`min-width: ${pxToRem(image.width)}`"
            :width="image.width"
          />
        </component>
      </div>
      <div class="mx-4 <lg:space-y-4">
        <div class="text-base space-y-2">
          <p :class="brandClasses.details.text">
            {{ upsellText }}
            <span v-if="showDetails">
              <base-button
                v-if="showModal"
                :class="brandClasses.details.button"
                @click="openQuickshop"
              >
                {{ $t.productUpsellDetails }}
              </base-button>
              <vf-link
                v-else
                :class="brandClasses.details.link"
                :size="detailsLinkSize"
                :to="upsell.url"
                @click="upsellDetail"
              >
                {{ $t.productUpsellDetails }}
              </vf-link>
            </span>
          </p>
          <product-pricing :class="brandClasses.details.price" :currency :price="upsell.price" />
        </div>
        <div v-if="showButton" class="mt-3 ">
          <vf-button v-if="!isInMiniCart" :loading :size="buttonSize" :variant="buttonVariant" @click="handleAddToCart">
            {{ $t.productUpsellAddToCart }}
          </vf-button>
          <base-button v-else :class="brandClasses.details.link" :loading @click="handleAddToCart">
            {{ $t.productUpsellAddToCart }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BaseButton, BaseLink } from '#components'
import type { Product, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { CSSClass } from '#types/common'
import type { ProductUpsell, ProductUpsellSelected } from '#types/product'
import type { ProductClickAction } from '#types/gtm'

const props = defineProps<{
  classTitle?: CSSClass
  isInMiniCart?: boolean
  modelValue: string[]
  showButton?: boolean | null
  showCheckbox?: boolean | null
  showDetails?: boolean | null
  showModal?: boolean
  title?: string
  upsell: ProductUpsell
}>()

const emit = defineEmits<{
  'update:modelValue': [payload: ProductUpsellSelected]
  'recClick': [payload: string]
  'openQuickshop': [payload: Product]
}>()

const {
  brandClasses,
  buttonSize,
  buttonVariant,
  checkboxSize,
  detailsLinkSize,
  image
} = useAppConfig().components.product.upsell

const cart = useCartStore()
const { $gtm, $locale, $t, $sendExtraMonetateEvents } = useNuxtApp()
const currency = useCurrencyCode($locale)
const toast = useToaster()

const { error: cartError, errorMessage } = storeToRefs(cart)
const loading = ref(false)
const upsellText = computed(() =>
  props.isInMiniCart
    ? props.upsell.name
    : $t.productUpsellCopy.replaceAll('{upsellName}', props.upsell.name)
)

const handleAddToCart = async () => {
  loading.value = true

  setInteractionOrigin('up-sell-complete-the-look', true)
  setGtmCartProductsMap(props.upsell.sku, { ...props.upsell, list: 'UpSellCompleteTheLook' })
  await cart.add({ product: props.upsell.sku })

  if (cartError.value) {
    toast.add({
      props: {
        type: 'error',
        title: $t.somethingWentWrong,
        message: errorMessage.value
      }
    })
  }

  $sendExtraMonetateEvents()

  loading.value = false
}

const handleGtmEvent = (event: { target: { checked: boolean } }) =>
  event.target.checked
    ? $gtm.push('upsell.onCrossSellSelect', props.upsell.id)
    : $gtm.push('upsell.onCrossSellDeselect', props.upsell.id)

const upsellDetail = (action: ProductClickAction = 'Navigate To PDP') => {
  $gtm.push('upsell.onUpsellClick', props.upsell, { action })
  $gtm.push('upsell.onCrossSellDetails', props.upsell.id)
}
const openQuickshop = () => {
  upsellDetail('Open Quick Shop')
  emit('openQuickshop', {
    ...props.upsell,
    currency,
    attributes: [],
    variants: {} as ProductVariants
  })
}
</script>
