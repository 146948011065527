<template>
  <base-picture
    :alt="content.alt"
    :height="content.height"
    :src="getMediaUrl(content.url)"
    :width="content.width"
  />
</template>

<script setup lang="ts">
import type { CMSPicture } from '#types/media'

defineProps<{ content: CMSPicture }>()
const { getMediaUrl } = useCms()
</script>
