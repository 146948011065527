<template>
  <div v-style="{ bg: backgroundColor, h: bottomMargins }" />
</template>

<script setup lang="ts">
import type { Spacer } from '#types/components/cms/spacer'

const { content } = defineProps<{ content: Spacer }>()

const { backgroundColor, bottomMargins } = content
</script>
