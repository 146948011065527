/**
 * Capitalizes the first letter of each word in the input string.
 *
 * @param sentence - The string to be capitalized. Default is an empty string.
 * @param options - Capitalization options
 * @param options.split - Delimiter to split words. Default is space.
 * @param options.join - Delimiter to join words. Default is space.
 * @param options.isCamel - Whether to convert to camelCase (first word lowercase). Default is false.
 * @returns A string with the first letter of each word capitalized.
 */
export const capitalize = (sentence: string = '', { split = ' ', join = ' ', isCamel = false } = {}): string => {
  const splitPattern = split === ' ' ? /\s+/ : new RegExp(`[\\s${split.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}]+`)

  const words = sentence.split(splitPattern)
    .filter((word) => word.length)
    .map((word) => {
      const firstLetterMatch = word.match(/\p{L}/u)
      if (!firstLetterMatch) return word

      const i = firstLetterMatch.index!
      return word.slice(0, i) + word.charAt(i).toUpperCase() + word.slice(i + 1).toLowerCase()
    })

  if (isCamel && words.length) {
    const firstLetterMatch = words[0].match(/\p{L}/u)
    if (firstLetterMatch) {
      const i = firstLetterMatch.index!
      words[0] = words[0].slice(0, i) + words[0].charAt(i).toLowerCase() + words[0].slice(i + 1)
    }
  }

  return words.join(join)
}
