/**
 * @remark Store could be anything like user, cart, screen.
 * The first argument is a unique id of the store across your application
 */
import type { AddAddressPayload, UpdateAddressPayload } from '#root/api/clients/consumer/data-contracts'
import { ApiErrorCode } from '#root/enums/api'
import { EnrollmentState } from '#root/enums/profile'
import type { BaseAddress } from '#types/components/account/address'

// TODO better type support for Profile Payloads GLOBAL15-106267. ProfileInfoData['consumerProfile']['consumerDetails'] can't be used because it's optional
type ConsumerDetails = {
  firstName?: string
  lastName?: string
  phone?: string
  phoneCode?: string
  postalCode?: string
  gender?: 'M' | 'F' | 'NA' | string
}

export const useUserStore = defineStore('user', () => {
  const auth = useAuthStore()
  const { enableLoyaltyFeatures, showLoyaltyRewardsMilestoneBar } = useFeatureFlags()
  const { consumer } = useApi()
  const queryParams = ref({
    includeLoyaltySummary: enableLoyaltyFeatures && !showLoyaltyRewardsMilestoneBar ? true : undefined
  })

  const {
    data,
    pending,
    error,
    execute
  } = consumer.getProfileInfo(queryParams.value, { immediate: false })
  // setting to false because with { immediate: false } pending is still initially true: https://github.com/nuxt/nuxt/issues/19333
  pending.value = false

  const { $t } = useNuxtApp()
  const profile = computed(() => data.value?.consumerProfile)
  const details = computed(() => profile.value?.consumerDetails)
  const isLoyaltyMember = computed(() => profile.value?.isLoyaltyMember)
  const loyaltyOptInDate = computed(() => profile.value?.loyaltyOptInDate)
  const loyaltyPointsBalance = computed(() => profile.value?.loyaltySummary?.points?.balance || 0)
  const addresses = computed(() => details.value?.address || [])
  const shippingAddresses = computed(() => sortAddressByDefault(addresses.value.filter((address) => address.approachType === 'S')))
  const billingAddresses = computed(() => sortAddressByDefault(addresses.value.filter((address) => address.approachType === 'B')))
  const interests = computed(() => (profile.value?.preferences?.interests?.split('|').filter(Boolean) || []))
  const defaultShippingAddress = computed(() => shippingAddresses.value.find((address) => address.main))
  const defaultBillingAddress = computed(() => billingAddresses.value.find((address) => address.main))
  const preferredShoeSize = computed(() => profile.value?.preferences?.preferredShoeSize)
  const subscriptions = computed(() => profile.value?.subscriptions
    ? profile.value.subscriptions
      .filter((subscription) => subscription.optin === true)
      .reduce((reducer, subscription) => {
        reducer[subscription.channel] = subscription
        return reducer
      }, {})
    : {}
  )
  const hasNewsletterSubscription = computed(() => profile.value?.subscriptions?.some(
    (subscription) =>
      subscription.type === 'Newsletter'
      && subscription.channel === 'email'
      && subscription.doubleOptin === true
  ))

  const isPartiallyEnrolled = computed(() => {
    if (!details.value)
      return false

    const { birthDate, email, firstName, lastName, phone, postalCode } = details.value

    return (email && !(phone && firstName && lastName && postalCode && birthDate))
  })

  const isAthleteProfile = computed(() => !!details.value?.athlete)
  const isEmployeeProfile = computed(() => !!details.value?.employee)
  const isWranxProfile = computed(() => details.value?.identity?.some(({ type }) => type === 'WRANX'))
  const isIpaProfile = computed(() => details.value?.identity?.some(({ type }) => type === 'IPA'))
  const isWranxOrIpaProfile = computed(() => isWranxProfile.value || isIpaProfile.value)

  const employeeDiscountDetails = computed(() => details.value?.employee && replaceAll($t.associateDiscount, {
    currentSpend: useFormattedPrice(details.value.employee.currentSpend, useCurrencyCode()),
    annualCap: useFormattedPrice(details.value.employee.annualCap, useCurrencyCode()),
    Brand: $t.brandName
  }))

  const athleteCreditDetails = computed(() => details.value?.athlete && replaceAll($t.athleteCredit, {
    currentSpend: useFormattedPrice(details.value.athlete.currentSpend, useCurrencyCode()),
    annualCap: useFormattedPrice(details.value.athlete.annualCredit, useCurrencyCode()),
    Brand: $t.brandName
  }))

  const ipaDiscountDetails = computed(() => details.value?.prosumer && replaceAll($t.ipaDiscount, {
    currentSpend: useFormattedPrice(details.value.prosumer.currentSpend, useCurrencyCode()),
    annualCap: useFormattedPrice(details.value.prosumer.annualCredit, useCurrencyCode()),
    Brand: $t.brandName
  }))

  const wranxDiscountDetails = computed(() => details.value?.prosumer && replaceAll($t.wranxDiscount, {
    currentSpend: useFormattedPrice(details.value.prosumer.currentSpend, useCurrencyCode()),
    annualCap: useFormattedPrice(details.value.prosumer.annualCredit, useCurrencyCode()),
    Brand: $t.brandName
  }))

  const getInitialEnrollmentState = () => {
    if (!auth.loggedIn)
      return EnrollmentState.LOOKUP

    if (!details.value?.phone)
      return EnrollmentState.COMPLETE_PROFILE_2_MOBILE

    return EnrollmentState.COMPLETE_PROFILE_3_PROFILE
  }

  const enrollmentState = ref(getInitialEnrollmentState())

  const spendBannerMessage = computed(() => {
    if (isEmployeeProfile.value && auth.isEmployeeDiscountTCAccepted)
      return employeeDiscountDetails.value
    else if (isAthleteProfile.value)
      return athleteCreditDetails.value
    else if (isIpaProfile.value)
      return ipaDiscountDetails.value
    else if (isWranxProfile.value)
      return wranxDiscountDetails.value
  })

  async function updateBasicInfo(consumerDetails: ConsumerDetails) {
    if (!profile.value) throw new Error('Profile not loaded')
    // check if the consumerProfile is empty, don't run update since it will cause an additional call that will trigger double emails
    if (!Object.values(consumerDetails).some(Boolean)) return

    if (typeof consumerDetails.phone === 'string')
      consumerDetails.phone = formatE164phone(consumerDetails.phone, consumerDetails.phoneCode)

    await consumer.$updateProfile({
      consumerProfile: { consumerDetails }
    })
    profile.value.consumerDetails = { ...profile.value.consumerDetails, ...consumerDetails }
  }

  async function updateInterests(payload) {
    if (!profile.value) throw new Error('Profile not loaded')
    const preferences = {
      ...profile.value.preferences,
      interests: payload.interests.join('|'),
      preferredShoeSize: payload.preferredShoeSize
    }
    await consumer.$updateProfile({
      consumerProfile: { consumerDetails: {}, preferences }
    })
    profile.value.preferences = preferences
  }

  function setDefaultAddress(addressId: string) {
    if (!profile.value) throw (new Error('Profile not loaded'))

    const approachType = addressId.substring(0, 1)

    const currentDefaultAddress = profile.value.consumerDetails.address
      .find((address) => address.id !== addressId && address.approachType === approachType && address.main)

    if (currentDefaultAddress)
      currentDefaultAddress.main = false

    profile.value.consumerDetails.address.find((address) => address.id === addressId).main = true
  }

  /**
   * add the from api and update the store
   * add address the Nuxt server endpoint or endpoint from LaunchDarkly config.
   * @param {BaseAddress} payload
   * @example useLazyApi('/consumers/v3/consumer/address')
   */
  async function addAddress(payload: BaseAddress) {
    if (!profile.value) throw (new Error('Profile not loaded'))

    // ensure the address is flagged as default if the user does not have one already of the same type
    // even if the user does not check the "default" checkbox
    const shouldBeMain = !addresses.value.some(({ approachType }) => approachType === payload.approachType)

    const address = {
      ...payload,
      recipientContactPhone: formatE164phone(payload.phone, payload.phoneCode),
      main: payload.main || shouldBeMain
    } as AddAddressPayload['address']

    const { addressId } = await consumer.$addAddress({ address })

    // First time adding address to My account
    if (!profile.value.consumerDetails.address)
      profile.value.consumerDetails.address = [{ ...address, id: addressId }]
    else
      profile.value.consumerDetails.address.unshift({ ...address, id: addressId })

    if (payload.main)
      setDefaultAddress(addressId)

    return addressId
  }

  // "recaptcha_response" is not camelCase to match the API spec
  async function saveCreditCard(payload, recaptcha_response) {
    try {
      const { card } = await consumer.$savePaymentInstrument({
        ...payload,
        recaptcha_response
      })

      if (card.paymentInstrumentId) return card.paymentInstrumentId
    }
    catch (error) {
      assertApiError(error)
      const toast = useToaster()
      toast.add({
        autoClose: true,
        props: {
          message: error.errorId === ApiErrorCode.CC_ALREADY_EXISTS
            ? error.message
            : $t.creditCardSaveFailed,
          type: 'error'
        }
      })
    }
  }

  async function saveGiftCard(payload) {
    try {
      const { card } = await consumer.$savePaymentInstrument(payload)

      return card.paymentInstrumentId
    }
    catch (error) {
      assertApiError(error)
      const toast = useToaster()
      toast.add({
        autoClose: true,
        props: {
          message: error.errorId === ApiErrorCode.CC_ALREADY_EXISTS
            ? $t.giftCardAlreadyExist
            : $t.giftCardSaveFailed,
          type: 'error'
        }
      })
    }
  }

  /**
   * update address by {addressId} and update the store
   * @method PUT
   * @param {UpdateAddressPayload['address']} payload
   * @example useLazyApi('/consumers/v3/consumer/address/{addressId}')
   * This function expects a payload containing either a 'recipientContactPhone', or both 'phone' and 'phoneCode'.
   * When just changing the default address flag, 'recipientContactPhone' is present,
   * while when fully editing the address, 'phone' and 'phoneCode' are present instead.
   */
  async function updateAddress(payload: UpdateAddressPayload['address']) {
    if (!profile.value) throw new Error('Profile not loaded')

    const recipientContactPhone = formatE164phone(
      payload.recipientContactPhone || payload.phone,
      payload.phoneCode
    )
    const addressDetails = { ...payload, recipientContactPhone }
    // remove unnecessary data from the body
    delete addressDetails.phone
    delete addressDetails.phoneCode

    const { addressId } = await consumer.$updateAddress(payload.id, {
      address: addressDetails
    })
    const addressIndex = profile.value.consumerDetails.address.findIndex(
      (address) => address.id === addressId
    )
    if (addressIndex !== -1)
      profile.value.consumerDetails.address[addressIndex] = addressDetails

    if (payload.main)
      setDefaultAddress(addressId)

    return addressId
  }

  /**
   * delete the from api and update the store
   * delete address by {addressId} the Nuxt server endpoint or enpoint from LauchDarkly config.
   * @method DELETE
   * @param {addressId} id
   * @example useLazyApi('/consumers/v3/consumer/address/{addressId}')
   */
  async function deleteAddress(id: string) {
    if (!profile.value) throw (new Error('Profile not loaded'))
    const filteredAddresses = profile.value.consumerDetails.address.filter((address) => address.id !== id)
    await consumer.$deleteAddress(id)
    profile.value.consumerDetails.address = filteredAddresses
  }

  // Clear user data from the store
  const reset = () => {
    data.value = null
  }

  return {
    get: execute,
    pending,
    error,
    profile,
    details,
    enrollmentState,
    getInitialEnrollmentState,
    shippingAddresses,
    billingAddresses,
    defaultShippingAddress,
    defaultBillingAddress,
    interests,
    preferredShoeSize,
    subscriptions,
    hasNewsletterSubscription,
    spendBannerMessage,
    isAthleteProfile,
    isPartiallyEnrolled,
    isLoyaltyMember,
    loyaltyOptInDate,
    loyaltyPointsBalance,
    isWranxProfile,
    isIpaProfile,
    isWranxOrIpaProfile,
    reset,
    updateBasicInfo,
    updateInterests,
    addAddress,
    updateAddress,
    deleteAddress,
    saveCreditCard,
    saveGiftCard
  }
})
