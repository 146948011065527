import { getUrl } from '#content/utils'
import type { Content, ResponsiveMedia } from '#types/content'
import type { PageContent } from '#types/page'
import type { ContentPage, PageChildrenData } from '#root/api/clients/content/data-contracts'
import type { Content as ContentNamespace } from '#root/api/clients/content/ContentRoute'
import type { LocaleCode } from '#types/locale'
import type { UseCms } from '#types/composables/useCms'

export const useCms = (): UseCms => {
  const { brand, cmsApiRoutePrefix, isDev } = useRuntimeConfig().public
  const { exclusionList } = useAppConfig().pages.cms
  const { cmsSiteId } = useAppConfig().api
  const { $t, $seo, $feature } = useNuxtApp()
  const { content: contentApi } = useApi()
  const route = useRoute()
  const host = useHost()
  const {
    enableFContentProxy,
    configApiDomains,
    showcase,
    inheritedFirstEspotsOnRoutes
  } = useFeatureFlags()

  const cmsBaseUrl = (import.meta.server && configApiDomains?.cmsCutoverUrlSSR) || configApiDomains?.cmsBaseUrl

  const locale = route.query.localePath as LocaleCode || useLocale()
  const siteId = computed(() => route.query.siteId as string || `${cmsSiteId || brand}-${locale}`)

  // Preview mode is defined once per application loading. To change the mode, the application must be reloaded.
  const isPreview = route.query.preview === 'true'
  const isDisablePreview = route.query.preview === 'false'
  const previewDateTime = route.query.previewDateTime?.toString()
  const viewMode = isPreview ? 'preview' : 'publish'

  const baseUrl = `${(isDev || enableFContentProxy) ? cmsApiRoutePrefix : cmsBaseUrl}/${viewMode}`

  const getMediaUrl = (url: string | undefined) => getUrl(url, baseUrl)

  const getMedia = (media: ResponsiveMedia | undefined) => getResponsiveMedia(media, baseUrl)

  const getSeoMetadata = (page: PageContent) => {
    const { title, name, metadata } = page
    const countryCode = locale.split('-')[1]

    const {
      htmlTitle,
      htmlDescription,
      ogTitle,
      ogDescription,
      ogImage,
      canonical,
      robots
    } = metadata

    const seoFallbackPlaceholderValues = {
      brand: $t.brand,
      brandName: $t.brandName,
      pageName: name || ''
    }

    const image = getUrl(ogImage?.[0]?.url, baseUrl) || `/img/logos/${brand}/default.svg`

    return {
      metadata: {
        title: htmlTitle || title || replaceAll($seo.page.title, seoFallbackPlaceholderValues),
        description: htmlDescription || replaceAll($seo.page.description, seoFallbackPlaceholderValues),
        ogImage: image,
        ogType: 'website',
        ogSiteName: `${brand}-${countryCode}`,
        twitterCard: 'summary',
        twitterImage: image,
        twitterSite: `@${brand}`,
        ...(ogTitle && { ogTitle }),
        ...(ogDescription && { ogDescription }),
        ...(robots && { robots })
      },
      link: [
        {
          rel: 'canonical',
          href: canonical
        }
      ]
    }
  }

  const getFooter = async () => {
    const { data, error } = await contentApi.footer(viewMode, siteId.value)

    if (error.value) return

    return data.value?.content
  }

  const getFragment = async (id: string): Promise<Content | undefined> => {
    const { data, error } = await contentApi.fragment(viewMode, id)

    if (error.value) {
      showError({
        statusCode: error.value.statusCode,
        statusMessage: error.value.message,
        data: {
          path: error.value.data?.path,
          host,
        }
      })
    }

    return data.value?.content
  }

  const getMegaMenu = async () => {
    let megamenuContent

    try {
      const { data, error } = await contentApi.menu(viewMode, $feature.tempMegaMenuIdOverride || siteId.value)

      if (!error.value) megamenuContent = data.value?.content
    }
    catch (e) {
      if (!(import.meta.server))
        throw e
    }

    if (import.meta.server) {
      const megaMenuKey = `megamenu-${viewMode}-${siteId.value}`

      if (!megamenuContent)
        megamenuContent = await $fetch(`/fapi/cached-data/${megaMenuKey}`)
      else
        await $fetch(`/fapi/cached-data/${megaMenuKey}`, { method: 'POST', body: megamenuContent })
    }

    return megamenuContent
  }

  const getPage = async (slug?: string | string[]): Promise<PageContent | undefined> => {
    let url = ''

    if (slug) {
      const strSlug = Array.isArray(slug) ? slug.join('/') : slug
      if (exclusionList.find((item) => strSlug.includes(item))) return
      url += `/${strSlug}`
    }

    const { data, error } = await contentApi.page(viewMode, siteId.value, url)

    if (error.value)
      throw error.value

    const content = data.value?.content || {} as ContentPage

    return {
      ...content,
      padding: content.mainContentPadding,
      sections: (content.sections || []).reduce((acc, { name, items = [] }) => ({
        ...acc,
        [name]: { name, items, lazy: name.includes('lazy') }
      }), {})
    }
  }

  const getPopUps = async () => {
    // Revert back after https://github.com/nuxt/nuxt/issues/23349 will be resolved
    const data = await contentApi.$popups(viewMode, siteId.value)
    return data.content
  }

  // Remove and replace with getFragment after https://github.com/nuxt/nuxt/issues/23349 will be resolved
  const getModal = async <T extends Content>(id: string): Promise<T> => {
    const data = await contentApi.$fragment(viewMode, id) as any
    return data.content
  }

  const getPageChildren = async (parentPageSlug: string, query?): Promise<PageChildrenData['content']> => {
    const data = await contentApi.$pageChildren(viewMode, siteId.value, parentPageSlug, { query })
    return data.content
  }

  const handleResponse = (response) => {
    mergeInheritedEspotSections(response?.data ?? response, route, inheritedFirstEspotsOnRoutes)
    return response
  }

  const getSectionsOpts = {
    transform: handleResponse
  }

  const getSections = {
    account: () => contentApi.espotsAccount(viewMode, siteId.value, getSectionsOpts),
    accountProfile: () => contentApi.espotsAccountProfile(viewMode, siteId.value, getSectionsOpts),
    basecamp: () => contentApi.basecamp(viewMode, siteId.value, getSectionsOpts),
    cart: () => contentApi.espotsCart(viewMode, siteId.value, getSectionsOpts),
    category: (categoryId: string | Ref<string>) =>
      contentApi.espotsCategory(viewMode, siteId.value, categoryId, getSectionsOpts),
    checkout: () => contentApi.espotsCheckout(viewMode, siteId.value, getSectionsOpts),
    contactUs: () => contentApi.espotsContactUs(viewMode, siteId.value, getSectionsOpts),
    error: () => contentApi.espotsError4Xx(viewMode, siteId.value, getSectionsOpts),
    generic: (page: string) => contentApi.espotsGeneric(viewMode, siteId.value, page, getSectionsOpts),
    giftCard: () => contentApi.espotsGiftCard(viewMode, siteId.value, getSectionsOpts),
    loyaltyModal: () => contentApi.espotsLoyaltyModal(viewMode, siteId.value, getSectionsOpts),
    orderConfirmation: () => contentApi.espotsOrderConfirmation(viewMode, siteId.value, getSectionsOpts),
    product: (productId: MaybeRef<string>) =>
      contentApi.espotsProduct(viewMode, siteId.value, productId, getSectionsOpts),
    promotions: () => contentApi.espotsPromotion(viewMode, siteId.value, getSectionsOpts),
    search: () => contentApi.espotsSearch(viewMode, siteId.value, getSectionsOpts),
    signUp: () => contentApi.espotsSignUp(viewMode, siteId.value, getSectionsOpts),
    signUpModal: () => contentApi.espotsSignUpModal(viewMode, siteId.value, getSectionsOpts),
    $signUpModal: () => contentApi.$espotsSignUpModal(viewMode, siteId.value).then(handleResponse),
    storeLocator: () => contentApi.espotsStoreLocator(viewMode, siteId.value, getSectionsOpts),
    westend: () => contentApi.westend(viewMode, siteId.value, getSectionsOpts)
  }

  const getSearch = async (
    searchType: ContentNamespace.Search.RequestParams['searchType'],
    query: ContentNamespace.Search.RequestQuery
  ) => {
    const data = await contentApi.$search(viewMode, siteId.value, searchType, query)

    return data?.content
  }

  const getShowcase = async (
    docType: string,
    layoutVariant?: string | undefined,
    limit?: number | undefined
  ) => {
    const { cmsEnv } = showcase
    const data = await contentApi.$showcase(
      viewMode,
      siteId.value,
      docType,
      { layoutVariant, limit, ...(cmsEnv && { cms: cmsEnv }) }
    )

    return data?.content
  }

  return {
    getFooter,
    getFragment,
    getMedia,
    getMediaUrl,
    getMegaMenu,
    getModal,
    getPage,
    getPageChildren,
    getPopUps,
    getSearch,
    getSections,
    getSeoMetadata,
    getShowcase,
    isDisablePreview,
    isPreview,
    previewDateTime,
    siteId,
  }
}
