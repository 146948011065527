<template>
  <div ref="elementRef" class="f-col" @click="handlePromotionClick">
    <div class="relative">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        class="full"
        fit="cover"
        :lazy
      />
      <vf-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        class="full"
        fit="cover"
        loop
        muted
      />
      <div v-style="gradient" class="absolute bottom-0 w-full" />
      <base-link
        v-if="mediaLink"
        aria-hidden="true"
        class="absolute-0"
        tabindex="-1"
        :target="mediaLink.targetAttribute"
        :to="mediaLink.url"
      />
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleColor"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
    </div>
    <div v-style:bg="backgroundColor">
      <div
        class="flex grow "
        :class="[contentAlignmentSmall[textAlignmentSmall], contentAlignmentLarge[textAlignmentLarge]]"
      >
        <div :class="brandClasses.container">
          <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
            {{ eyebrowText }}
          </div>
          <base-picture
            v-if="images"
            v-bind="images"
            fit="contain"
            :props-img="{ class: 'object-left' }"
            style="max-height:9.5rem"
          />
          <base-picture
            v-else-if="eyebrowLogo"
            :alt="eyebrowLogo.alt"
            fit="contain"
            :props-img="{ class: 'object-left' }"
            :src="eyebrowLogo.url"
            style="max-height:9.5rem"
          />
          <div class="space-y-2">
            <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
              {{ overline }}
            </p>
            <h2
              v-if="title"
              v-style:c="titleColor"
              :class="[titleStyle, { 'sr-only': hideTitle }]"
              data-test-id="cms-banner-title"
            >
              <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
                {{ title }}
              </base-link>
              <template v-else>
                {{ title }}
              </template>
            </h2>
            <p
              v-if="subtitle"
              v-style:c="subtitleColor"
              :class="subtitleStyle"
              data-test-id="cms-banner-text"
            >
              {{ subtitle }}
            </p>
            <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
          </div>

          <div
            v-if="linkType !== 'No-CTA' && targets.length"
            class="pointer-within f-col gap-4 md:flex-row md:wrap"
            :class="[buttonsAlignmentSmall[textAlignmentSmall], buttonsAlignmentLarge[textAlignmentLarge], equalTargetSizeClass]"
            :style="`--cols: repeat(${targets.length},minmax(0,1fr))`"
          >
            <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="{ ...target, ...getTextLinkStyles(content) }" :size />
          </div>
          <cms-rich-text
            v-if="richTextBelowTargets"
            v-style:c="richTextBelowTargetsColor || subtitleColor"
            class="pointer-within"
            :content="{ richText: richTextBelowTargets }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { Responsive } from '#types/common'
import type { BannerBlockBelowContent } from '#types/components/cms/banner-block-below'
import type { TextAlignment } from '#types/components/cms/text-alignment'

const { content } = defineProps<{
  content: BannerBlockBelowContent
}>()

const { brandClasses } = useAppConfig().components.cms.blockBelow

const {
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowLogoResponsive,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  textAlignmentLarge,
  textAlignmentSmall,
  title,
  titleColor,
  titleStyle,
  hideTitle,
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const { images } = getMedia(eyebrowLogoResponsive)

const gradientStyles = { full: 35, focused: 50, off: 0 }

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const contentAlignmentLarge: Record<TextAlignment, string> = {
  left: 'md:justify-start md:text-start',
  center: 'md:justify-center md:text-center',
  right: 'md:justify-end md:text-end'
}

const contentAlignmentSmall: Record<TextAlignment, string> = {
  left: 'justify-start text-start',
  center: 'justify-center text-center',
  right: 'justify-end text-end'
}

const buttonsAlignmentLarge: Record<TextAlignment, string> = {
  left: 'md:justify-start',
  center: 'md:justify-center',
  right: 'md:justify-end'
}

const buttonsAlignmentSmall: Record<TextAlignment, string> = {
  left: 'items-start',
  center: 'items-center',
  right: 'items-end'
}

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
