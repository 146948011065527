import { injectHead } from '@unhead/vue'
import type { DetailsData, ProductCardExtended, ProductSetItem, ProductUpsell } from '#root/api/clients/product/data-contracts'
import type { CartItem, PriceCart } from '#types/cart'
import type {
  InteractionOrigin,
  MonetateProductObject,
  P13nImpressionContext,
  ProductContext,
  ProductObject,
  SetItemObject,
  ShippingMethodContext,
  ShopTheLookContext
} from '#types/gtm'
import type { ProductAttribute } from '#types/product'
import type { MonetateRecommendedProduct } from '#types/p13n'

const gtmCartProductsMap = useLocalStorage('gtmCartProductsMap', {}, { mergeDefaults: true }).value
const gtmInteractionOrigin = useLocalStorage<InteractionOrigin>('gtmInteractionOrigin', '', { mergeDefaults: true })
let gtmInteractionForcedOrigin = false

export const interactionOriginMap: Record<InteractionOrigin, string> = {
  '': '',
  'search': 'Search',
  'grid': 'Grid',
  'jacket-finder': 'Jacket Finder',
  'backpack-finder': 'Backpack Finder',
  'footwear-finder': 'Footwear Finder',
  'explore-collection': 'Explore Collection',
  'product-bundles': 'Product Bundles',
  'shoe-finder-retail': 'Shoe Finder Retail',
  'product-compare': 'Product Compare',
  'tent-sleepingbag-guide': 'Tent and Sleepingbag Guide',
  'shop-the-look': 'Shop the Look',
  'direct-to-pdp': 'Direct to PDP',
  'recommendation-carousel': 'Product Recommendation Carousel',
  'up-sell-complete-the-look': 'UpSellCompleteTheLook',
  'customizer': 'Customizer',
  'fit-analytics': 'Fit Analytics'
}

export const getPageBreadcrumb = (crumbs) => {
  return crumbs?.map(({ label }) => label) || undefined
}

export const serializeAnalyticsBreadcrumbs = (crumbs) => crumbs.map(({ label }) => label).join(',')

export const deserializeAnalyticsBreadcrumbs = (serializedCrumbs) => serializedCrumbs?.split(',').map((label) => ({ label }))

export const getPageTitle = async () => {
  const titleTag = (await injectHead()?.resolveTags())?.find(({ tag }) => tag === 'title')
  return titleTag?.textContent || ''
}

/**
 * Many values needed for analytics are not available immediately on page load.
 * Here we wait for the values to be available before resolving.
 */
export const getUserEventData = async (overrideEmail?: string, consumerId?: string) => {
  const { details, hasNewsletterSubscription, get: getProfile } = useUserStore()
  const { loggedIn, memberId, getMemberId } = useAuthStore()
  let loyaltyMemberId = memberId
  try {
    if (!loyaltyMemberId) loyaltyMemberId = await getMemberId()
  }
  catch (err) {
    log.error(`[@domains/commerce/utils/gtm.ts] Error: ${err}`)
  }

  if (loggedIn && !details) await getProfile()
  const email = overrideEmail || details?.email

  return {
    ...(loyaltyMemberId && { 'loyaltyMember-id': loyaltyMemberId }),
    ...(email && {
      'encoded-email': btoa(email),
      'encoded-email-sha': await generateHashHex(email)
    }),
    ...(loggedIn && {
      // if the subscription with the type "Newsletter" has an doubleOptin value as true, we can use this identifier to push data to the data layer https://digital.vfc.com/jira/browse/GLOBAL15-100442
      'nl-subscription': hasNewsletterSubscription
    }),
    ...(overrideEmail && {
      'consumer-id': consumerId
    })
  }
}

const getColorAttributes = (product: CartItem) => {
  const defaultColor = getDefaultColorOption(product.attributes, product.id)
  const colorId = (defaultColor?.id || product.masterId)?.slice(-3)

  return {
    colorId,
    colorLabel: defaultColor?.label || product.colorDescription,
    id: defaultColor?.id
  }
}

export const getGTMProduct = (id: string): any => gtmCartProductsMap[id]

const findGTMProduct = (product: Record<any, any>): any => {
  return gtmCartProductsMap[product.masterId || product.id]
    || Object.values(gtmCartProductsMap).find(
      (item: any) =>
        (product.masterId && item.masterId === product.masterId) || (product.id && item.productId === product.id)
    )
}

export const getRecommendationListDescription = ({
  list,
  title,
}: {
  list: string
  title?: string
}): string => title ? `${list}: ${title}` : list

export const getListDescription = ({ breadcrumbs, searchTerm = '', appSource = '', productId = '' }): string => {
  const gtmProduct = findGTMProduct({ masterId: productId, id: productId })
  const interactionOrigin = appSource || gtmProduct?.interactionOrigin || gtmInteractionOrigin.value || 'direct-to-pdp'
  const isProductList = ['grid', 'search'].includes(interactionOrigin)
  const interactionTitle = interactionOriginMap[interactionOrigin]
  const pageBreadcrumbs = gtmProduct?.breadcrumbs?.map(({ label }) => label).join(' - ') || breadcrumbs
  const pageSearchTerm = gtmProduct?.searchTerm || searchTerm

  if (interactionOrigin === 'recommendation-carousel') return getRecommendationListDescription({ list: gtmProduct?.list })

  return `${interactionTitle}${isProductList ? `: PLP | ${pageSearchTerm || pageBreadcrumbs}` : ''}`
}

const getAvailableSizes = (attributes: ProductAttribute[]) => {
  const options = attributes?.find((attribute) => attribute.label === 'Size')?.options
  return options?.filter((size) => size.available).map((size) => size.label) || []
}

const getSelectedVariants = (attributes) => {
  let counter = 0
  return (Array.isArray(attributes) ? attributes : Object.values(attributes))
    .reduce((obj, { code, value }) => {
      if (code !== 'color')
        obj[`sizeCode${++counter}`] = value
      return obj
    }, { sizeCode1: '', sizeCode2: '', sizeCode3: '' })
}

const getDiscountTypesByPrice = (price: Partial<PriceCart>): string | undefined => {
  const discountTypes: string[] = []
  const current = price.highCurrent || price.current || 0
  const original = price.highOriginal || price.original || 0

  current === 0 && discountTypes.push('Free Gift')
  current < original && discountTypes.push('Marked Down')
  return discountTypes.length ? discountTypes.join(',') : undefined
}

// Add persistent storage to cart items to save context attributes needed for analytics
export const setGtmCartProductsMap = (id: string, product) => {
  const existingContext = findGTMProduct(product) as any

  const persistentValues = {
    productId: product.id,
    masterId: product.masterId,
    avgRating: product.rating?.score || 0,
    availSizes: getAvailableSizes(product.attributes),
    historicalCategory: history.state.category,
    lowOnStock: product.eyebrow?.id?.toUpperCase() === 'LOW STOCK',
    badge: product.badge?.label || product.eyebrow?.label,
    breadcrumbs: existingContext?.breadcrumbs
    || deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
    productBreadcrumbs: product.breadcrumbs,
    numReviews: product.rating?.reviewsCount || 0,
    searchTerm: existingContext?.searchTerm || (history.state.searchTerm ?? history.state.suggestedPhrase),
    interactionOrigin: existingContext?.interactionOrigin || gtmInteractionOrigin.value,
    list: existingContext?.list || product.list
  }

  gtmCartProductsMap[id] = persistentValues
  gtmCartProductsMap[product.id] = persistentValues
}

export const setGtmCartProductVariant = (
  variantId: string,
  { appSource, category, list }: { appSource?: InteractionOrigin, category?: string, list?: string } = {}
) => {
  gtmCartProductsMap[variantId] = {
    ...gtmCartProductsMap[variantId],
    ...(appSource && { interactionOrigin: interactionOriginMap[appSource] }),
    ...(category && { category }),
    ...(list && { list })
  }
}

export const removeGtmCartProductsMap = (id: string, productId: string) => {
  delete gtmCartProductsMap[id]
  if (Object.values(gtmCartProductsMap).filter((gtmProduct: any) => gtmProduct.productId === productId).length === 1)
    delete gtmCartProductsMap[productId]
}

export const setInteractionOrigin = (interaction?: InteractionOrigin, force?: boolean) => {
  const historyState = history.state
  const productId = historyState?.pageKey
  const existingContext = productId && findGTMProduct({ masterId: productId, id: productId })
  gtmInteractionForcedOrigin = !!force

  gtmInteractionOrigin.value = existingContext?.interactionOrigin
  || ((historyState.searchTerm || historyState.breadcrumbs) && !force)
    ? historyState.searchTerm ? 'search' : 'grid'
    : interaction || 'direct-to-pdp'
}

export const resetInteractionOrigin = () => (gtmInteractionOrigin.value = '')
export const getInteractionOrigin = () => gtmInteractionOrigin.value

const getCustomItemsAttributes = (product) => {
  const { enableCustoms } = useFeatureFlags()
  return enableCustoms
    ? {
        customized: !!product.recipeId,
        preCreatedCustomCode: product.precreatedCustomsCode || undefined,
        recipeID: product.recipeId || undefined
      }
    : {}
}

/*
  Due to specific financial reporting needs, "price" shall reflect prorated
  amounts which includes cart-level discounts. All other attributes shall
  only reflect product-level pricing and discounts (GLOBAL15-56246)
*/
const getCartProductObject = (product, context) => {
  const price = product.price
  const totalPrice = Math.min(price.rowTotal, price.priceAfterItemDiscount, price.priceAfterOrderDiscount)
  const saleDiscountAmount: number = (price.original - price.current) * product.qty
  return {
    onSale: !!saleDiscountAmount,
    originalPrice: +product.price.original,
    price: (totalPrice - product.taxTotal) / product.qty,
    quantity: context.quantity || product.qty,
    saleDiscountAmount,
    taxTotal: product.taxTotal,
    vf_itemID: product.productId,
    virtualStyleCode: product.masterId || product.id,
    ...getSelectedVariants(product.variants),
    ...getCustomItemsAttributes(product)
  }
}

const getProductObject = (product, context) => ({
  avgRating: product.rating?.score || 0,
  availSizes: getAvailableSizes(product.attributes),
  numReviews: product.rating?.reviewsCount || 0,
  onSale: product.price.highCurrent !== product.price.highOriginal,
  originalPrice: +product.price.highOriginal,
  price: +product.price.highCurrent,
  saleDiscountAmount: +(product.price.highOriginal - product.price.highCurrent).toFixed(2),
  searchTerm: context.searchTerm,
  ...getCustomItemsAttributes(product)
})

const getGtmProductBrand = (item) => {
  const { brand } = useRuntimeConfig().public
  const countryCode = useCountryCode()
  return `${brand === 'otw' && item.salesChannel === 'Mainline' ? 'vans' : brand}-${countryCode}`.toUpperCase()
}

const getShippingTrack = (product: CartItem):
  (Omit<ShippingMethodContext, 'shippingWindow'> & { shippingWindow: string }) | undefined => {
  const shipping = product.shippingOptions?.find(({ selected }) => selected)
  if (!shipping) return
  const shippingGTM = {
    shippingStoreId: undefined,
    shippingWindow: shipping.shippingMethod.deliveryTime,
  }
  if (isPickupOrSts(shipping.shippingMethod.code)) {
    return {
      ...shippingGTM,
      shippingMethod: shipping.shippingMethod.code === 'sts'
        ? 'BOPIS_STS'
        : 'BOPIS_SD',
      shippingStoreId: shipping.storeInfo?.id,
    }
  }
  return {
    ...shippingGTM,
    shippingMethod: 'STH',
  }
}

const getGtmCartProduct = (productData = {}) => {
  return Object.entries(productData).reduce((product, [key, value]) => {
    if (value) product[key] = value
    return product
  }, {})
}

export const getProductObjectList
  = (products: CartItem[] | ProductCardExtended[] | DetailsData[], context: ProductContext) => {
    return products?.map((product, i) => {
      const trace = JSON.parse(sessionStorage.gtmTrace || '{}')

      const pid = product.productId || product.id
      const gtmCartProduct = findGTMProduct({ id: pid })
      const isStyle = PRODUCT_STYLE_ID_REGEX.test(pid)
      const colorAttributes = getColorAttributes(product)

      const pageBreadcrumbs = getPageBreadcrumb(context?.breadcrumbs || gtmCartProduct?.breadcrumbs || []).join(' - ')
      const productBreadcrumbs
        = getPageBreadcrumb(product.breadcrumbs || gtmCartProduct?.productBreadcrumbs) || []

      const searchTerm = context.searchTerm || gtmCartProduct?.searchTerm
      const interactionOrigin = interactionOriginMap[gtmCartProduct?.interactionOrigin || gtmInteractionOrigin.value || 'direct-to-pdp']

      const catalogCategory = productBreadcrumbs.at(-1)
      const forcedCategory = gtmInteractionForcedOrigin ? interactionOrigin : null

      const list = context.list || getListDescription({
        breadcrumbs: pageBreadcrumbs,
        searchTerm,
        appSource: gtmCartProduct?.interactionOrigin,
        productId: pid
      })

      const recommendationCategory = gtmCartProduct?.interactionOrigin === 'recommendation-carousel' && list

      const category = gtmCartProduct?.category
        || recommendationCategory
        || forcedCategory
        || context?.enCategoryNameWithId
        || context.category
        || pageBreadcrumbs
        || gtmCartProduct?.historicalCategory
        || interactionOrigin

      const { styleId, hasMasterIdFromStyle, hasProductSeason } = useAppConfig().components.gtm
      const masterId = product.masterId ?? product.styleId?.toUpperCase()
      const getProductId = () => {
        if (isStyle) return colorAttributes?.id
        if (context.isCartItem) return product.masterId ?? product.id
        return product.id ?? product.masterId
      }

      return {
        badge: product.badge?.label || product.eyebrow?.label,
        bundleId: context.bundleId,
        brand: getGtmProductBrand(product),
        colorCode: colorAttributes?.colorId,
        colorDescription: colorAttributes?.colorLabel,
        coupon: product.productPromotions?.map(({ couponCode, promotionId }) => couponCode || promotionId).join('|'),
        couponDiscountAmount: product.productPromotions?.reduce((acc, { price }) => acc + Math.abs(price), 0),
        couponType: product.productPromotions?.map(({ couponCode }) => couponCode ? 'CODE' : 'AUTO').join('|'),
        onCoupon: !!product.productPromotions,
        discountType: getDiscountTypesByPrice(product.price),
        id: getProductId(),
        lowOnStock: product.eyebrow?.id?.toUpperCase() === 'LOW STOCK',
        masterId: hasMasterIdFromStyle
          ? masterId?.match(styleId)[0]
          : masterId,
        merchProductId: product.merchantProductId ?? product.id,
        name: product.name.trim(),
        styleCode: (product.masterId || product.id)?.match(styleId)?.[1],
        list,
        category,
        catalogCategory,
        searchTerm,
        stockStatus: context.stockStatus,
        ...(hasProductSeason && product.season ? { season: product.season } : {}),
        ...(!context.omitPosition ? { position: (context.offset || 0) + i + 1 } : {}),
        ...(context.isCartItem
          ? getCartProductObject(product, context)
          : getProductObject(product, context)),
        ...getGtmCartProduct(gtmCartProductsMap[pid]),
        ...getShippingTrack(product),
        ...trace[pid]
      }
    }) || []
  }

export const getMonetateProductObjectList = (
  products: MonetateRecommendedProduct[],
  context?: Partial<P13nImpressionContext>
): MonetateProductObject[] => {
  const list = getRecommendationListDescription({ list: context?.list || '', title: context?.title })

  return products.map((product, i) => ({
    id: product.itemGroupId || product.id,
    name: product.title,
    avgRating: Number.parseFloat(product.stars || '0'),
    brand: getGtmProductBrand(product), // Will be inaccurate for mainline products on OTW, sales channel not available in Monetate data
    colorCode: product.id.split(':')[2], // That's the best we can do with Monetate data
    list,
    category: list,
    onSale: product.price !== product.salePrice,
    originalPrice: product.price,
    position: i + 1,
    price: product.salePrice,
    saleDiscountAmount: product.price - product.salePrice,
    ...(context?.experience
      ? {
          experience_id: context.experience.id,
          experience_label: context.experience.label,
          experience_name: context.experience.name,
          recs_set: context.experience.component,
          recs_set_id: context.experience.actionId
        }
      : {})
  }))
}

export const getProductSetItemObjectList = (
  products: ProductSetItem[],
  { productSet }: ShopTheLookContext
): SetItemObject[] => products.map((setItem) => ({
  id: setItem.id,
  name: setItem.name,
  category: 'Shop the Look',
  list: 'Shop the Look',
  bundleId: productSet.productSetId,
  position: productSet.products!.indexOf(setItem) + 1
}))

export const getProductUpsellItemObjectList = (
  upsells: ProductUpsell[]
): Partial<ProductObject>[] => upsells.map((upsell, i) => {
  const splitSku = upsell.sku.split(':')
  const masterId = splitSku[0] + splitSku[1]
  return {
    avgRating: undefined,
    badge: undefined,
    brand: getGtmProductBrand(upsell),
    bundleId: undefined,
    catalogCategory: undefined,
    category: 'UpSellCompleteTheLook',
    colorCode: splitSku[2],
    colorDescription: undefined,
    discountType: getDiscountTypesByPrice(upsell.price),
    id: upsell.id,
    list: 'UpSellCompleteTheLook',
    masterId,
    name: upsell.name,
    numReviews: undefined,
    onSale: upsell.price.highCurrent !== upsell.price.highOriginal,
    originalPrice: upsell.price.highOriginal,
    position: i + 1,
    price: upsell.price.highCurrent,
    saleDiscountAmount: +(upsell.price.highOriginal - upsell.price.highCurrent).toFixed(2),
    searchTerm: undefined,
    styleCode: masterId
  }
})

export const formatTrackingCategory = (name: string | undefined, url?: string | undefined) =>
  `${name?.toLowerCase().trim().replace(/\s+/g, '-')}_${url?.match(/\d+$/)?.[0] || ''}`
