/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  GetCollectionsData,
  GetFootSizesData,
  GetFooterData,
  GetLocalesData,
  GetSearchData,
  GetSizeChartData,
  GetStaticPageData,
  GetUtilityData,
} from "./data-contracts";

export class Internal extends HttpClient {
  static paths = {
    getCollections: "/fapi/components/collections",
    getFooter: "/fapi/components/footer",
    getFootSizes: "/fapi/components/foot-sizes",
    getSearch: "/fapi/components/search",
    getSizeChart: "/fapi/components/size-chart/:productType",
    getUtility: "/fapi/components/utility",
    getLocales: "/fapi/pages/location-selector",
    getStaticPage: "/fapi/pages/:page",
  };
  static mockDataPaths = {};
  static mockedPaths = {
    getCollections: false,
    getFooter: false,
    getFootSizes: false,
    getSearch: false,
    getSizeChart: false,
    getUtility: false,
    getLocales: false,
    getStaticPage: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "internal");
  }

  /**
   * No description
   *
   * @tags Internal
   * @name GetCollections
   * @summary Get Mega Menu Collections Data
   * @request GET:/fapi/components/collections
   */
  getCollections = <
    ResT = GetCollectionsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/collections")
        ? computed(() => mockPath("Internal", "getCollections", ``))
        : computed(() => `/fapi/components/collections`),
      method: "GET",
      key: "/fapi/components/collections",
      apiName: "Internal.getCollections",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetCollections
   * @summary Get Mega Menu Collections Data
   * @request GET:/fapi/components/collections
   */
  $getCollections = (requestParams: ApiOptions = {}) =>
    this.$request<GetCollectionsData>({
      path: isMocked("/fapi/components/collections")
        ? mockPath("Internal", "getCollections", ``)
        : `/fapi/components/collections`,
      method: "GET",
      apiName: "Internal.getCollections",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetFooter
   * @summary Get Footer Data
   * @request GET:/fapi/components/footer
   */
  getFooter = <ResT = GetFooterData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/footer")
        ? computed(() => mockPath("Internal", "getFooter", ``))
        : computed(() => `/fapi/components/footer`),
      method: "GET",
      key: "/fapi/components/footer",
      apiName: "Internal.getFooter",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetFooter
   * @summary Get Footer Data
   * @request GET:/fapi/components/footer
   */
  $getFooter = (requestParams: ApiOptions = {}) =>
    this.$request<GetFooterData>({
      path: isMocked("/fapi/components/footer") ? mockPath("Internal", "getFooter", ``) : `/fapi/components/footer`,
      method: "GET",
      apiName: "Internal.getFooter",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetFootSizes
   * @summary Get Foot Sizes Data
   * @request GET:/fapi/components/foot-sizes
   */
  getFootSizes = <
    ResT = GetFootSizesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/foot-sizes")
        ? computed(() => mockPath("Internal", "getFootSizes", ``))
        : computed(() => `/fapi/components/foot-sizes`),
      method: "GET",
      key: "/fapi/components/foot-sizes",
      apiName: "Internal.getFootSizes",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetFootSizes
   * @summary Get Foot Sizes Data
   * @request GET:/fapi/components/foot-sizes
   */
  $getFootSizes = (requestParams: ApiOptions = {}) =>
    this.$request<GetFootSizesData>({
      path: isMocked("/fapi/components/foot-sizes")
        ? mockPath("Internal", "getFootSizes", ``)
        : `/fapi/components/foot-sizes`,
      method: "GET",
      apiName: "Internal.getFootSizes",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetSearch
   * @summary Get Search Data
   * @request GET:/fapi/components/search
   */
  getSearch = <ResT = GetSearchData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/search")
        ? computed(() => mockPath("Internal", "getSearch", ``))
        : computed(() => `/fapi/components/search`),
      method: "GET",
      key: "/fapi/components/search",
      apiName: "Internal.getSearch",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetSearch
   * @summary Get Search Data
   * @request GET:/fapi/components/search
   */
  $getSearch = (requestParams: ApiOptions = {}) =>
    this.$request<GetSearchData>({
      path: isMocked("/fapi/components/search") ? mockPath("Internal", "getSearch", ``) : `/fapi/components/search`,
      method: "GET",
      apiName: "Internal.getSearch",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetSizeChart
   * @summary Get Size Chart Data
   * @request GET:/fapi/components/size-chart/{productType}
   */
  getSizeChart = <
    ResT = GetSizeChartData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    productType: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/size-chart/:productType")
        ? computed(() => mockPath("Internal", "getSizeChart", `productType:${toValue(productType)}`))
        : computed(() => `/fapi/components/size-chart/${toValue(productType)}`),
      method: "GET",
      key: "/fapi/components/size-chart/:productType",
      apiName: "Internal.getSizeChart",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetSizeChart
   * @summary Get Size Chart Data
   * @request GET:/fapi/components/size-chart/{productType}
   */
  $getSizeChart = (productType: string, requestParams: ApiOptions = {}) =>
    this.$request<GetSizeChartData>({
      path: isMocked("/fapi/components/size-chart/:productType")
        ? mockPath("Internal", "getSizeChart", `productType:${toValue(productType)}`)
        : `/fapi/components/size-chart/${productType}`,
      method: "GET",
      apiName: "Internal.getSizeChart",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetUtility
   * @summary Get Utility Navigation Data
   * @request GET:/fapi/components/utility
   */
  getUtility = <ResT = GetUtilityData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/components/utility")
        ? computed(() => mockPath("Internal", "getUtility", ``))
        : computed(() => `/fapi/components/utility`),
      method: "GET",
      key: "/fapi/components/utility",
      apiName: "Internal.getUtility",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetUtility
   * @summary Get Utility Navigation Data
   * @request GET:/fapi/components/utility
   */
  $getUtility = (requestParams: ApiOptions = {}) =>
    this.$request<GetUtilityData>({
      path: isMocked("/fapi/components/utility") ? mockPath("Internal", "getUtility", ``) : `/fapi/components/utility`,
      method: "GET",
      apiName: "Internal.getUtility",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetLocales
   * @summary Get Locales Data
   * @request GET:/fapi/pages/location-selector
   */
  getLocales = <ResT = GetLocalesData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/pages/location-selector")
        ? computed(() => mockPath("Internal", "getLocales", ``))
        : computed(() => `/fapi/pages/location-selector`),
      method: "GET",
      key: "/fapi/pages/location-selector",
      apiName: "Internal.getLocales",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetLocales
   * @summary Get Locales Data
   * @request GET:/fapi/pages/location-selector
   */
  $getLocales = (requestParams: ApiOptions = {}) =>
    this.$request<GetLocalesData>({
      path: isMocked("/fapi/pages/location-selector")
        ? mockPath("Internal", "getLocales", ``)
        : `/fapi/pages/location-selector`,
      method: "GET",
      apiName: "Internal.getLocales",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetStaticPage
   * @summary Get Static Page Data
   * @request GET:/fapi/pages/{page}
   */
  getStaticPage = <
    ResT = GetStaticPageData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    page: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/fapi/pages/:page")
        ? computed(() => mockPath("Internal", "getStaticPage", `page:${toValue(page)}`))
        : computed(() => `/fapi/pages/${toValue(page)}`),
      method: "GET",
      key: "/fapi/pages/:page",
      apiName: "Internal.getStaticPage",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Internal
   * @name GetStaticPage
   * @summary Get Static Page Data
   * @request GET:/fapi/pages/{page}
   */
  $getStaticPage = (page: string, requestParams: ApiOptions = {}) =>
    this.$request<GetStaticPageData>({
      path: isMocked("/fapi/pages/:page")
        ? mockPath("Internal", "getStaticPage", `page:${toValue(page)}`)
        : `/fapi/pages/${page}`,
      method: "GET",
      apiName: "Internal.getStaticPage",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
