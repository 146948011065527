import type { TextLinkStyle } from '#types/styles'

export const getTextLinkStyles = ({
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  textLinkUnderline
}: TextLinkStyle) => ({
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  textLinkUnderline,
})
