/**
 * - Converts a wildcard pattern into a regular expression.
 *
 * The pattern supports:
 * - `*` to match a single path segment (anything except `/`).
 * - `**` to match zero or more characters, including `/`.
 *
 * @param {string} pattern - The wildcard pattern to convert.
 * @returns {RegExp} - The generated regular expression.
 */
export const convertPatternToRegex = (pattern) => {
  // We apply this replacement in order to have different logic between * and **
  const tempPattern = pattern.replace(/\*\*/g, '§§')

  const escapedPattern = tempPattern.replace(/[.+?^${}()|[\]\\]/g, '\\$&')

  const singleStarPattern = escapedPattern.replace(/\*/g, '[^/]*')

  const finalPattern = singleStarPattern.replace(/§§/g, '.*')
  return new RegExp(`^${finalPattern}$`)
}
