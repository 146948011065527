import type { InjectionKey } from 'vue'
import type { InlineGridSection } from '#types/components/product/list'
import type { Section } from '#types/page'

export interface SectionProvider {
  fullHeight: boolean
  lazy: boolean
  name: string
  section: Section | InlineGridSection | undefined
}

export const SectionContextKey: InjectionKey<SectionProvider> = Symbol('SectionContextKey')
