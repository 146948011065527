<template>
  <div v-style:bg="containerBackgroundColor">
    <h2
      v-if="title"
      v-style:c="titleColor"
      class="pb-4 "
      :class="titleStyle"
      data-test-id="cms-icon-and-text-collection-title"
    >
      {{ title }}
    </h2>
    <div
      v-style="{
        'grid-cols': { md: isGridLayout && iconAndText.length === 4 && 'repeat(2, minmax(0, 30rem ))' },
        'gap': { sm: '1rem', md: `${gapMap[variant]}rem` },
      }"
      class="f-col"
      :class="{
        'md:flex-row md:justify-center md:wrap': isGridLayout && iconAndText.length !== 4,
        'md:grid md:justify-center': isGridLayout && iconAndText.length === 4,
      }"
    >
      <component
        :is="target ? BaseLink : 'div'"
        v-for="({
          icon,
          richText,
          target,
          backgroundColor,
          textColor,
        }, key) in iconAndText"
        :key
        v-style="{
          bg: backgroundColor,
          w: { md: isGridLayout && iconAndText.length !== 4 && `calc((100% - ${gapMap[variant] * 2}rem) / 3)` },
          c: textColor,
        }"
        class="flex items-center gap-2 text-sm"
        :class="{
          'w-fit': variant === 'one-column',
          'f-col px-4 py-7.5 text-center text-balance md:py-15': variant === 'icon-above-text',
        }"
        :target="target?.targetAttribute"
        :to="target?.url"
      >
        <vf-icon
          v-if="icon"
          :dir="icon.dir"
          :name="icon.icon"
          :size="iconSizeMap[iconSize]"
        />
        <cms-rich-text
          :class="{
            'fw-light': variant === 'icon-left',
            'text-md fw-medium': variant === 'icon-above-text',
          }"
          :content="{ richText }"
        />
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BaseLink } from '#components'
import type { IconSizes } from '#types/sizes'
import type { IconAndTextCollectionContent } from '#types/components/cms/icon-and-text-collection'

const { content } = defineProps<{
  content: IconAndTextCollectionContent
}>()

const {
  backgroundColor: containerBackgroundColor,
  iconAndText,
  iconSize,
  title,
  titleStyle,
  titleColor,
  variant
} = content

const iconSizeMap = {
  small: 'md',
  medium: 'lg',
  large: '3xl'
} satisfies Record<typeof iconSize, IconSizes>

const gapMap = {
  'icon-above-text': 1,
  'icon-left': 2,
  'one-column': 1,
}

const isGridLayout = computed(() => variant === 'icon-left' || variant === 'icon-above-text')
</script>
