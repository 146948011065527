/**
 * Enum to use in very specific scenarios when certain error code should get special treatment
 */
export const enum ApiErrorCode {
  INVALID_CUSTOMER = 'AUT498',
  INVALID_PRICE = 'PRC400',
  ITEM_NO_LONGER_AVAILABLE = 'ORD430',
  CART_NOT_FOUND = 'BSK404',
  ORDER_SHIP_TO_PICK = 'ORD516',
  ORDER_PICK_TO_SHIP = 'ORD517',
  ORDER_PICK_TO_STS = 'ORD518',
  NO_INVENTORY = 'INV701',
  NO_PRICE = 'INV702',
  GIFT_NOT_AVAILABLE = 'GFS800',
  SHIPPING_METHOD_UNAVAILABLE = 'SHP404',
  ITEM_NOT_AVAILABLE = 'ORD404',
  ITEM_OOS = 'INV700',
  ITEMS_OOS = 'INV406',
  MAX_GC_LIMIT_REACHED = 'GFC410',
  GC_CHECK_BALANCE_ERROR = 'GFC799',
  GC_INVALID = 'GFC800',
  GC_EXPIRED = 'GFC812',
  GC_INVALID2 = 'GFC700',
  GC_NOT_ALLOWED = 'GFC725',
  GC_ZERO_BALANCE = 'GFC740',
  GC_ALREADY_APPLIED = 'GFC755',
  GC_INVALID3 = 'GFC760',
  CC_EXPIRY_DATE_INVALID = 'FE309',
  PAYMENT_FRAUD_VERIFICATION_EXCEPTION = 'PYT402',
  PAYMENT_INSTRUMENT_NOT_FOUND = 'PYT404',
  CC_ALREADY_EXISTS = 'PYT300',
  ADDRESS_NOT_FOUND = 'PYT405',
  ADDRESS_FIELDS_MISSING = 'PYT406',
  BILLING_ADDRESS_MISSING = 'BIL400',
  PAYMENT_ERROR = 'PAY400',
  CANT_SHIP_THERE = 'PAY401',
  TAX_CALCULATION_FAIL = 'TAX309',
  TAX_UPDATED = 'TAX310',
  TOTALS_CALCULATION_FAIL = 'ORD415',
  ITEMS_NO_LONGER_AVAILABLE = 'INV405',
  SOME_PRODUCTS_NO_INVENTORY = 'INV409',
  ITEMS_NO_LONGER_AVAILABLE2 = 'INV407',
  ADDED_MAX_QUANTITY = 'INV408',
  PROMO_INVALID = 'PRM130',
  PROMO_NOT_AVAILABLE_NOW = 'PRM135',
  PROMO_LIMIT_EXCEEDED = 'PRM140',
  PROMO_ALREADY_REDEEMED = 'PRM150',
  PROMO_EXPIRED = 'PRM155',
  PROMO_INVALID2 = 'PRM160',
  PROMO_ALREADY_APPLIED = 'PRM416',
  SHIPPING_ADDRESS_INVALID = 'CHK380',
  SHIPPING_POBOX_UNAVAILABLE = 'SHP500',
  PHONE_NUMBER_EMPTY = 'SHP410',
  GIFT_NOT_AVAILABLE2 = 'GFO404',
  GC_NON_APPLICABLE = 'GFO405',
  GWP_PRODUCT = 'GWP403',
  ADDRESS_INVALID = 'CHK320',
  PAYMENT_NOT_ENOUGH = 'PYT400',
  PROMO_FAIL = 'PRM175',
  BILLING_PHONE_MISSING = 'BILL410',
  REWARD_NOT_AUTHENTICATED = 'REW401',
  REWARD_NON_LOYALTY = 'REW130',
  REWARD_LOYALTY_NONCONFIRMED = 'REW135',
  REWARD_INSUFFICIENT_BALANCE = 'REW140',
  GC_MISSING_CODE = 'GFC400',
  LIST_PRODUCT_NOT_FOUND = 'PLP400',
  PROFILE_UPDATE_FAIL = 'PRO412',
  PROFILE_UPDATE_FAIL2 = 'PRO418',
  BILLING_ADDRESS_DELETE = 'PRO414',
  PASSWORD_CHANGE_FAIL = 'PRO415',
  PROFILE_NOT_FOUND = 'PRO404',
  PASSWORDS_DONT_MATCH = 'FE340',
  PASSWORD_TOO_SHORT = 'FE872',
  ADRESS_EXISTS = 'CCA400',
  INVALID_ADDRESS_ID = 'CCA401',
  UNKNOWN_CUSTOMER = 'CCN404',
  ORDER_NOT_FOUND = 'OHS4004',
  INVALID_CREDENTIALS = 'AUT401',
  ORDER_NOT_FOUND2 = 'ORD409',
  ORDER_INVALID_EMAIL = 'ORD400',
  LOGIN_IN_USE = 'AUT439',
  CUSTOMER_LIST_UNKNOWN = 'AUT440',
  PASSWORD_CHANGE_FAIL_INCORRECT = 'AUT450',
  PASSWORD_CHANGE_FAIL_SAME_PREVIOUS = 'AUT451',
  PASSWORD_CHANGE_FAIL_SAME_PREVIOUS2 = 'AUT452',
  INVALID_EMAIL_FORMAT = 'AUT453',
  CHEETAH_MEMBER_NOT_FOUND = '4044',
  USER_LOCKED = 'AUT423',
  LAST_LOGIN_ATTEMPT = 'AUT426',
  INVALID_AGE = 'PRO410',
  MISSING_BIRTHDATE = 'PRO411',
  PHONE_NUMBER_ALREADY_IN_USE2 = 'AUT444',
  LOGIN_IN_USE2 = 'PRO400',
  DUPLICATED_TOKEN = 'AUT409',
  CANNOT_AUTHORIZE = 'AUT404',
  INVALID_CREDENTIALS2 = 'AUT418',
  LOGIN_SYSTEM_ERROR = 'AUT500',
  LOGIN_AUTHORIZATION_FAIL = 'AUT432',
  MISSING_CLIENT_ID = 'API400',
  INVALID_CREDENTIALS3 = 'AUT438',
  INVALID_CREDENTIALS4 = 'AUT437',
  PASSWORD_RESET_LINK_EXPIRED = 'AUT402',
  LOCKED_ACCOUNT = 'AUT441',
  PHONE_NUMBER_ALREADY_IN_USE = 'AUT442',
  CAPTCHA_FAIL = 'AUT403',
  GENERAL = 'GEN500',
  API_NOT_FOUND = 'API404',
  API_METHOD_NOT_ALLOWED = 'API405',
  API_NOT_ACCEPTABLE = 'API406',
  API_UNSUPPORTED_MEDIA_TYPE = 'API415',
  EMPLOYEE_STORE_NOT_FOUND = 'STR404',
  RETURN_CREATION_ERROR = 'RTN500',
  ATTR_MISSING_REQUESTTYPE = 'ATTR100',
  ATTR_MISSING_ORGANISATION = 'ATTR200',
  ATTR_MISSING_EMPTY_PAYLOAD = 'ATTR300',
  REVIEWS_MAX_LENGTH = 'REV410'
}
