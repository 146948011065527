export const CATEGORY_URL_REGEX = /^\/[a-z]{2}-[\da-z]{2,3}\/c\/[\da-z/\-]*-(\d{4,10})$/

export const PRODUCT_URL_REGEX = /^\/[a-z]{2}-[\da-z]{2,3}\/p\/[\da-z/\-]*-([A-Z\d]{8,11})/

export const CATEGORY_15_URL_REGEX = /^\/([a-z]{2}-[\da-z]{2,3})\/([\da-z/\-]*)-(c)(\d{4,6})$/

export const PRODUCT_15_URL_REGEX = /^\/([a-z]{2}-[\da-z]{2,3})\/([\da-z/\-]*)-(p)([A-Za-z\d]{8,11})$/

export const PRODUCT_STYLE_ID_REGEX = /(^[A-Z\d]{8}$)/

export const PRODUCT_ID_REGEX = /(^[A-Z\d]{8,11}$)/

export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY'

export const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY'

export const DATE_MIN = '01/01/1900'
