<template>
  <cms-collection ref="elementRef" :content="mappedContent" @click="handlePromotionClick" />
</template>

<script lang="ts" setup>
const { content } = defineProps<{ content: any }>()

const { promotionTracking, name } = content

const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const getSlideMaxWidthForBreakpoint = (br: string) => {
  if (content.breakpoints[br].type === 'carousel')
    return br === 'sm' ? '16.25rem' : '26.5rem'

  return null
}

const mappedContent = {
  ...content,
  breakpoints: Object.assign({}, ...Object.keys(content.breakpoints).map((br) => ({
    [br]: {
      ...content.breakpoints[br],
      slidePadding: content.breakpoints[br].slidePadding ?? 'small',
      showArrows: br !== 'sm',
      slideMaxWidth: getSlideMaxWidthForBreakpoint(br)
    }
  }))),
  items: (content.targets || []).map((target) => ({
    type: 'VfCanvasCTAButton',
    target: {
      ...target,
      size: {
        sm: {
          size: target.size?.sm?.size ?? 'sm'
        },
        md: {
          size: target.size?.md?.size ?? 'md'
        },
        lg: {
          size: target.size?.lg?.size ?? 'md'
        }
      }
    }
  }))
}
</script>
