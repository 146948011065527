/**
 * Determines the correct channel header to use for a given API request.
 * MAPP - Used for mobile app orders
 * ECOMM - default value
 * @returns The channel identifier
 * @category Utils
 */

const defaultChannel = 'ECOMM'

export const getApiChannel = (): 'ECOMM' | 'MAPP' => {
  if (import.meta.server) return defaultChannel

  return sessionStorage.getItem('app-webview') === 'true'
    ? 'MAPP'
    : defaultChannel
}
