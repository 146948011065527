<template>
  <div ref="containerRef">
    <base-iframe
      ref="frameRef"
      class="m-a"
      :src
      :style="{ 'width': pxToRem(width), 'transform-origin': 'left top', ...frameConfig }"
    />
  </div>
</template>

<script setup lang="ts">
type FrameConfig = {
  height: string
  transform: string
}

const props = defineProps<{
  width: number
  src: string
}>()

const { width: windowWidth } = useWindowSize()

const containerRef = ref()
const frameRef = ref()
const frameConfig = reactive({} as FrameConfig)

const calculateWidth = () => Math.max(props.width, containerRef.value.clientWidth)

const recalculate = () => {
  const containerWidth = containerRef.value.clientWidth
  const scale = containerWidth / calculateWidth()
  frameConfig.height = `calc(100% / ${scale})`
  frameConfig.transform = `scale(${scale})`
}

defineExpose({
  frameRef
})

watch([() => props.width, windowWidth], recalculate)

onMounted(recalculate)
</script>
