import type { DefineComponent, TransitionGroupProps } from 'vue'
import { Teleport } from 'vue'
import type { DialogBaseOptions } from '#types/config/components/dialog'
import { ClientOnly } from '#components'

type EventAttributes<E extends Record<string, [v?: any]>> = {
  [K in keyof E as `on${Capitalize<string & K>}`]?: (...args: E[K]) => void
}

interface DialogPromiseOptions {
  wrapper?: MaybeRefOrGetter<any> // Find better type (e.g. new (...args: any[]) => any)
  teleport?: MaybeRefOrGetter<boolean>
  prefix?: string
  lazy?: boolean
  /**
   * Determines if the promise can be called only once at a time.
   *
   * @default false
   */
  singleton?: boolean

  /**
   * Transition props for the promise.
   */
  transition?: TransitionGroupProps

}

type DialogPromise<Return, Attrs, WrapperProps> = DefineComponent<WrapperProps & { lazy?: boolean }> & {
  new(): {
    $slots: {
      default: (_: Attrs) => any
    }
  }
} & {
  open: (props?: Attrs, wrapperProps?: WrapperProps) => Promise<Return>
  keep: (keep?: boolean) => DialogPromise<Return, Attrs, WrapperProps>
  close: () => Promise<void>
}

interface PromiseState {
  resolve: (v: any) => any
  promise: Promise<any>
}

interface DialogState {
  type: Ref<'panel' | 'modal'>
  component?: any
  reopened?: boolean
  args?: any[]
}

export const InjectKeyDialog: InjectionKey<DialogState> = Symbol('dialog')
let lastPromiseState: PromiseState
let keptPromiseState: PromiseState | undefined
const onOpenCallbacks: (() => void)[] = []
const onCloseCallbacks: (() => void)[] = []

/**
 * Registers a callback to be called when a dialog is opened.
 * @param callback - The callback to be called when a dialog is opened.
 * @returns A function to unregister the callback.
 * @category Utils
 */
export function onDialogOpen(callback: () => void) {
  onOpenCallbacks.push(callback)

  return () => {
    const index = onOpenCallbacks.indexOf(callback)
    if (index > -1) onOpenCallbacks.splice(index, 1)
  }
}

/**
 * Registers a callback to be called when a dialog is closed.
 * @param callback - The callback to be called when a dialog is closed.
 * @returns A function to unregister the callback.
 * @category Utils
 */
export function onDialogClose(callback: () => void) {
  onCloseCallbacks.push(callback)

  return () => {
    const index = onCloseCallbacks.indexOf(callback)
    if (index > -1) onCloseCallbacks.splice(index, 1)
  }
}

/**
 * Asynchronously closes a dialog based on the given PromiseState.
 * @param promiseState - The PromiseState object representing the state of the promise.
 * @returns A Promise that resolves once the dialog is closed.
 * @category Utils
 */
async function closeDialog(promiseState: PromiseState): Promise<void> {
  if (promiseState) {
    promiseState.resolve?.(null)
    await promiseState.promise.catch(() => {})
  }
}

/**
 * Utility function to close dialogs.
 * @category Utils
 * @returns A Promise that resolves once the dialog is closed.
 */
export async function closeDialogs(): Promise<void> {
  if (lastPromiseState) return closeDialog(lastPromiseState)
}

/**
 * Hook to access the current dialog state and control dialog behavior.
 * @template Props - Type for dialog component props.
 * @template Emits - Type for dialog event emitters.
 * @returns An object containing current dialog state and methods to control dialog behavior.
 */
export function useCurrentDialog<Props, Emits extends Record<string, [v?: any]>>() {
  const dialog = inject<DialogState | null>(InjectKeyDialog, null)

  if (!dialog) throw new Error('`useCurrentDialog` must be used inside a dialog')

  const [dialogAttrs, wrapperAttrs] = dialog.args || []

  return {
    reopened: dialog.reopened,
    reopen(attrs?: Partial<Props & EventAttributes<Emits>>): Promise<Emits['resolve'][0]> {
      return dialog.component?.open({ ...dialogAttrs, ...attrs }, wrapperAttrs, true)
    }
  }
}

/**
 * Creates a dialog promise with specified options.
 * @template Return - The return type of the dialog promise.
 * @template Attrs - Type of additional attributes passed to the dialog.
 * @template WrapperProps - Type of properties for the dialog wrapper component.
 * @param {DialogPromiseOptions} options - Options for configuring the dialog promise.
 * @returns {DialogPromise<Return, Attrs, WrapperProps>} - The created dialog promise.
 * @category Utils
 */
export function createDialog<
  Return,
  Attrs = void,
  WrapperProps = void
>(options: DialogPromiseOptions): DialogPromise<Return, Attrs, WrapperProps>
export function createDialog<
  Name extends string,
  WrapperProps,
  Dialogs extends Record<string, [any, any]>,
  Props = Dialogs[Name][0],
  Emits extends Record<string, [v: any]> = Dialogs[Name][1],
>(
  name: Name,
  options: DialogPromiseOptions
): DialogPromise<
  Emits['resolve'][0],
  (Props extends void ? unknown : Props) & (Emits extends void ? unknown : EventAttributes<Emits>),
  WrapperProps
>

/**
 * Creates a dialog component with the specified options.
 * @param {any} arg1 - The first argument, which can be either a string representing the dialog name or an object containing dialog options.
 * @param {any} [arg2] - The second argument, which is optional and only used if the first argument is a string (dialog name).
 * @returns {any} - The created dialog component.
 * @category Utils
 */
export function createDialog(arg1: any, arg2?: any): any {
  const { $viewport } = useNuxtApp()
  const { dialogOptions: defaultOptions } = useAppConfig().components.dialog.default

  const [options, name] = typeof arg1 === 'string' ? [arg2, arg1] : [arg1]
  const { position, prefix = 'dialog', size, teleport = true, wrapper, ..._options } = options

  const fullOptions = { ...defaultOptions, ..._options }
  const TemplatePromise = createTemplatePromise(fullOptions)

  let promiseState: PromiseState
  let keepLast = false
  const dialogState: {
    type: Ref<'panel' | 'modal'>
    component: any
    position: DialogBaseOptions['position']
    size: DialogBaseOptions['size']
    reopened: boolean
    args: any[]
  } = {
    type: ref(getValueForBreakpoint<'panel' | 'modal'>($viewport.breakpoint, fullOptions.type)!),
    component: null,
    position,
    size,
    reopened: false,
    args: []
  }

  const component = defineComponent({
    setup(_, { slots, attrs }) {
      const Comp = name ? resolveComponent(`${attrs.lazy ? 'lazy-' : ''}${prefix}-${name}`) : null
      dialogState.component = component
      provide(InjectKeyDialog, dialogState)

      // Use ClientOnly, because Nuxt doesn't support Teleport for custom targets
      // and "body" target can't determine the correct starting location for hydration.
      // See: https://vuejs.org/guide/scaling-up/ssr.html#teleports
      return () => h(ClientOnly, () => {
        const _TemplatePromise = h(TemplatePromise, null, {
          default: ({ args, resolve, reject, promise }) => {
            const [compAttrs, wrapperAttrs] = args
            const _resolve = (...args) => {
              onCloseCallbacks.forEach((cb) => cb())

              if (keptPromiseState) {
                lastPromiseState = keptPromiseState
                keptPromiseState = undefined
              }

              return resolve(...args)
            }
            const _reject = (...args) => {
              onCloseCallbacks.forEach((cb) => cb())

              if (keptPromiseState) {
                lastPromiseState = keptPromiseState
                keptPromiseState = undefined
              }

              return reject(...args)
            }
            const _compAttrs = { ...compAttrs, ...attrs, position: fullOptions.position, type: fullOptions.type }
            const slotAttrs = {
              ...Object.fromEntries(Object.entries(_compAttrs).map(([key, value]) => [key, unref(value)])),
              onResolve: _resolve,
              onReject: _reject
            }
            const _wrapper = toValue(wrapper)
            const defaultSlot = () => slots.default ? slots.default(slotAttrs) : Comp ? h(Comp, slotAttrs) : null

            // If a position config is passed with the createDialog initialization, override the default with that config
            if (dialogState.position && _wrapper?.props.position)
              _wrapper.props.position.default = dialogState.position

            // If a size config is passed with the createDialog initialization, override the default with that config
            if (dialogState.size && _wrapper?.props.size)
              _wrapper.props.size.default = dialogState.size

            dialogState.args = args
            promiseState = {
              resolve: _resolve,
              promise
            }
            lastPromiseState = promiseState
            return _wrapper
              ? h(
                _wrapper,
                {
                  ...attrs,
                  ...wrapperAttrs,
                  onResolve: _resolve,
                  onReject: _reject
                },
                defaultSlot
              )
              : defaultSlot()
          }
        })
        return h(Teleport, { to: '#dialogs', disabled: !toValue(teleport) }, _TemplatePromise)
      })
    }
  })

  component.keep = (keep = true) => {
    keepLast = keep
    keptPromiseState = lastPromiseState
    return component
  }

  component.open = async (attrs, wrapperAttrs, reopened = false) => {
    dialogState.reopened = reopened

    if (lastPromiseState && !keepLast)
      await closeDialogs()

    keepLast = false

    onOpenCallbacks.forEach((cb) => cb())

    if (import.meta.dev) {
      // only in develop mode, checks after the dialog is open if the dialog component is wrapped in the dialog wrapper
      setTimeout(() => {
        const dialogsContainer = document.querySelector('#dialogs')
        const wrapperClasses = dialogsContainer?.querySelectorAll('[data-id="dialog-layout"]')

        if (dialogsContainer?.children?.length !== wrapperClasses?.length)
          showError('Any dialog must be wrapped in the <vf-dialog-layout> component.')
      }, 1000)
    }

    // @ts-expect-error There's a breaking type change in Vue 3.3 <https://github.com/vuejs/core/pull/7963>
    return TemplatePromise.start(attrs, { ...defaultOptions, ...wrapperAttrs })
  }

  component.close = () => closeDialog(promiseState)

  return markRaw(component)
}
