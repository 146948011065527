<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div v-style:bg="backgroundColor">
      <div class="relative flex grid-area-stack">
        <div
          v-if="hasCaption"
          v-style:c="captionColor || subtitleColor"
          class="absolute right-0 mr-6"
          :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
          style="max-width: 9.375rem"
        >
          {{ captionText }}
        </div>
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          class="w-full"
          fit="cover"
          :lazy
        />
        <vf-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          class="w-full"
          fit="cover"
          loop
          muted
        />
        <div v-style="gradient" class="absolute bottom-0 w-full" />
        <base-link
          v-if="mediaLink"
          aria-hidden="true"
          class="absolute-0"
          tabindex="-1"
          :target="mediaLink.targetAttribute"
          :to="mediaLink.url"
        />
      </div>
      <div class="relative grid" :class="brandClasses.grid">
        <div
          v-style:bg="contentBackgroundColor"
          :class="[contentAlignment, alignmentLarge === 'left' ? 'left-0' : 'right-0', brandClasses.container]"
        >
          <div class="w-full f-col gap-4">
            <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
              {{ eyebrowText }}
            </div>
            <base-picture
              v-if="images"
              fit="contain"
              :props-img="{ class: 'object-left' }"
              style="max-height: 9.5rem"
              v-bind="images"
            />
            <base-picture
              v-else-if="eyebrowLogo"
              :alt="eyebrowLogo.alt"
              fit="contain"
              :props-img="{ class: 'object-left' }"
              :src="eyebrowLogo.url"
              style="max-height: 9.5rem"
            />
            <div class="f-col gap-2">
              <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
                {{ overline }}
              </p>
              <h2
                v-if="title"
                v-style:c="titleColor"
                :class="[titleStyle, { 'sr-only': hideTitle }]"
                data-test-id="cms-banner-title"
              >
                <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
                  {{ title }}
                </base-link>
                <template v-else>
                  {{ title }}
                </template>
              </h2>
              <p
                v-if="subtitle"
                v-style:c="subtitleColor"
                :class="subtitleStyle"
                data-test-id="cms-banner-text"
              >
                {{ subtitle }}
              </p>
              <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
            </div>
          </div>
          <div class="f-col gap-4" style="justify-content: inherit">
            <div
              v-if="linkType !== 'No-CTA' && targets.length"
              class="pointer-within flex flex-col gap-4 wrap md:flex-row"
              :class="[equalTargetSizeClass, contentAlignment]"
              style="justify-content: inherit"
              :style="`--cols: repeat(${targets.length},minmax(0,1fr))`"
            >
              <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="{ ...target, ...getTextLinkStyles(content) }" />
            </div>
            <cms-rich-text
              v-if="richTextBelowTargets"
              v-style:c="richTextBelowTargetsColor || subtitleColor"
              :content="{ richText: richTextBelowTargets }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { BannerOverlayContent } from '#types/components/cms/banner-overlay'
import type { TextAlignment } from '#types/components/cms/text-alignment'
import type { Responsive } from '#types/common'

const { content } = defineProps<{
  content: BannerOverlayContent
}>()

const { brandClasses } = useAppConfig().components.cms.bannerOverlay

const {
  alignmentLarge,
  backgroundColor,
  contentBackgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowLogoResponsive,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  textAlignmentLarge,
  textAlignmentSmall,
  targets = [],
  title,
  titleColor,
  titleStyle,
  hideTitle,
} = content

const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const { images } = getMedia(eyebrowLogoResponsive)

const gradientStyles = { full: 35, focused: 50, off: 0 }

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const equalTargetSizeClass = [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:cols-$cols ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:cols-$cols lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

const textAlignmentSmallClasses: Record<TextAlignment, string> = {
  left: '<md:items-start <md:text-left',
  center: '<md:items-center <md:text-center',
  right: '<md:items-end <md:justify-end <md:text-right'
}

const textAlignmentLargeClasses: Record<TextAlignment, string> = {
  left: 'md:items-start md:text-left',
  center: 'md:items-center md:text-center',
  right: 'md:items-end md:justify-end md:text-right'
}

const contentAlignment = [
  textAlignmentSmallClasses[textAlignmentSmall],
  textAlignmentLargeClasses[textAlignmentLarge],
]

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
