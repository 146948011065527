/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { DecideCreateData, DecideCreatePayload } from "./data-contracts";

export class Decide extends HttpClient {
  static paths = { decideCreate: "/api/engine/v1/decide/:brand" };
  static mockDataPaths = { decideCreate: "decide" };
  static mockedPaths = { decideCreate: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "monetate");
  }

  /**
   * @description Send monetate events
   *
   * @tags Decide
   * @name DecideCreate
   * @request POST:/api/engine/v1/decide/{brand}
   */
  decideCreate = <
    ResT = DecideCreateData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    brand: MaybeRefOrGetter<string>,
    data: DecideCreatePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/api/engine/v1/decide/:brand")
        ? computed(() => mockPath("Decide", "decideCreate", `brand:${toValue(brand)}`))
        : computed(() => `/api/engine/v1/decide/${toValue(brand)}`),
      method: "POST",
      body: data,
      key: "/api/engine/v1/decide/:brand",
      apiName: "Decide.decideCreate",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * @description Send monetate events
   *
   * @tags Decide
   * @name DecideCreate
   * @request POST:/api/engine/v1/decide/{brand}
   */
  $decideCreate = (brand: string, data: DecideCreatePayload, requestParams: ApiOptions = {}) =>
    this.$request<DecideCreateData>({
      path: isMocked("/api/engine/v1/decide/:brand")
        ? mockPath("Decide", "decideCreate", `brand:${toValue(brand)}`)
        : `/api/engine/v1/decide/${brand}`,
      method: "POST",
      body: data,
      apiName: "Decide.decideCreate",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
