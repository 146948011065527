import type { InjectionKey } from 'vue'
import type { BenefitBarContent } from '#types/components/cms/benefit-bar'
import type { PromoBarContent } from '#types/components/cms/promo-bar'
import type { MegaMenuContent } from '#types/components/cms/mega-menu'
import type { Section } from '#types/page'
import type { NavigationLink } from '#types/navigation'

interface HeaderContext {
  menuOpened: Ref<boolean>
  menuHovered: Ref<boolean>
  megaMenu: MegaMenuContent
  suggestionsOpened: Ref<boolean>
  showPromoBar: Ref<boolean>
  sections: {
    megaMenu: Section<MegaMenuContent>
    promoBar: Section<PromoBarContent>
    benefitBar: Section<BenefitBarContent>
    openedSearchView: Section
    openedSearchViewBelowRecommendations: Section
  }
  links: Ref<NavigationLink[]>
  isSomethingElseSticky: ComputedRef<boolean>
}

export const HeaderContextKey: InjectionKey<HeaderContext> = Symbol('HeaderContext')
