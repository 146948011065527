/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { GetContactUsCategoriesData, SendCaseData, SendCasePayload } from "./data-contracts";

export class ContactUs extends HttpClient {
  static paths = {
    sendCase: "/consumers-v3/contactus",
    getContactUsCategories: "/consumers-v3/contactus/categorySubject",
  };
  static mockDataPaths = {};
  static mockedPaths = { sendCase: false, getContactUsCategories: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "contact-us");
  }

  /**
   * No description
   *
   * @tags Contact-us
   * @name SendCase
   * @summary Post a contact us lead
   * @request POST:/consumers-v3/contactus
   */
  sendCase = <ResT = SendCaseData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: SendCasePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/contactus")
        ? computed(() => mockPath("ContactUs", "sendCase", ``))
        : computed(() => `/consumers-v3/contactus`),
      method: "POST",
      body: data,
      key: "/consumers-v3/contactus",
      apiName: "ContactUs.sendCase",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contact-us
   * @name SendCase
   * @summary Post a contact us lead
   * @request POST:/consumers-v3/contactus
   */
  $sendCase = (data: SendCasePayload, requestParams: ApiOptions = {}) =>
    this.$request<SendCaseData>({
      path: isMocked("/consumers-v3/contactus") ? mockPath("ContactUs", "sendCase", ``) : `/consumers-v3/contactus`,
      method: "POST",
      body: data,
      apiName: "ContactUs.sendCase",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Contact-us
   * @name GetContactUsCategories
   * @summary Get Categories and Subjects for Contact-us form
   * @request GET:/consumers-v3/contactus/categorySubject
   */
  getContactUsCategories = <
    ResT = GetContactUsCategoriesData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/contactus/categorySubject")
        ? computed(() => mockPath("ContactUs", "getContactUsCategories", ``))
        : computed(() => `/consumers-v3/contactus/categorySubject`),
      method: "GET",
      key: "/consumers-v3/contactus/categorySubject",
      apiName: "ContactUs.getContactUsCategories",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contact-us
   * @name GetContactUsCategories
   * @summary Get Categories and Subjects for Contact-us form
   * @request GET:/consumers-v3/contactus/categorySubject
   */
  $getContactUsCategories = (requestParams: ApiOptions = {}) =>
    this.$request<GetContactUsCategoriesData>({
      path: isMocked("/consumers-v3/contactus/categorySubject")
        ? mockPath("ContactUs", "getContactUsCategories", ``)
        : `/consumers-v3/contactus/categorySubject`,
      method: "GET",
      apiName: "ContactUs.getContactUsCategories",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
