<template>
  <div ref="elementRef" class="relative grid h-full" @click="handlePromotionClick">
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      class="full grid-area-stack"
      fit="cover"
      :lazy
      :props-img="{ class: 'h-full' }"
    />
    <vf-video
      v-else-if="responsiveMedia.video"
      v-bind="responsiveMedia.video"
      ref="videoRef"
      :autoplay="!lazy"
      class="full grid-area-stack"
      fit="cover"
      loop
      :meta="responsiveMedia.video.sm"
      muted
    />
    <base-link
      v-if="mediaLink"
      aria-hidden="true"
      class="absolute-0"
      tabindex="-1"
      :target="mediaLink.targetAttribute"
      :to="mediaLink.url"
    />
    <div class="pointer-events-none relative grid-area-stack" :class="variantContentClass[variant] || variantContentClass.default">
      <div class="h-full f-col" :class="alignment[contentAlignment]">
        <h2
          v-if="title"
          v-style:c="titleColor"
          class="pointer-within mb-1"
          :class="[titleStyle, { 'sr-only': hideTitle }]"
          data-test-id="cms-banner-title"
        >
          <base-link v-if="mediaLink" :target="mediaLink.targetAttribute" :to="mediaLink.url">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h2>
        <p
          v-if="subtitle"
          v-style:c="subtitleColor"
          :class="subtitleStyle"
          data-test-id="cms-banner-text"
        >
          {{ subtitle }}
        </p>
        <div
          v-if="targets.length"
          class="pointer-within f-col gap-4 md:flex-row md:wrap not-first:pt-4"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey, type SectionProvider } from '#content/components/cms/section/context'
import type { BannerEspotAlignment, BannerEspotContent } from '#types/components/cms/banner-espot'
import type { ButtonSize } from '#types/components/vf/button'

const { content } = defineProps<{
  content: BannerEspotContent
}>()

const {
  contentAlignment,
  media,
  mediaTarget,
  name,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
  hideTitle,
  variant
} = content

const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const videoRef = ref()
const responsiveMedia = getMedia(media)
const size = { sm: { size: 'tiny' as ButtonSize } }

const mediaLink = mediaTarget || targets[0]

const variantContentClass = {
  'default': 'p-2 md:p-4',
  'full-width': 'p-6 md:p-8'
}

const alignment: Partial<Record<BannerEspotAlignment, string>> = {
  bottom: 'text-center justify-end items-center',
  center: 'text-center center',
  left: 'justify-center',
  top: 'text-center items-center'
}

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
