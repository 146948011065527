import type { CartItem, ShippingOption } from '#types/cart'

/**
 *
 * @param item
 * @param options
 * @param options.$t
 * @param options.enableRelativePdpUrl
 * @returns cart item with mapped shipping options and properties in FE friendly format
 */
export const transformCartItem = (item: CartItem, { $t, enableRelativePdpUrl }) => {
  const shippingOptions = (item.shippingOptions || []).map((option) => ({
    ...option,
    shippingMethod: {
      ...option.shippingMethod,
      // The shipping options label on cart page needs to show a static text rather than the label from API response
      label: getShippingMethodLabel($t, option.shippingMethod)
    }
  }))

  if (!shippingOptions.length) {
    const { isDigitalProduct, deliveryTime } = item
    shippingOptions.push({
      selected: true,
      available: true,
      shippingMethod: {
        code: isDigitalProduct ? 'digital' : 'standard',
        label: isDigitalProduct ? $t.digitalProducts : $t.shipToHome,
        deliveryTime
      } as ShippingOption['shippingMethod'] & any
    })
  }

  return {
    ...item,
    pdpUrl: enableRelativePdpUrl ? getRelativeUrl(item.pdpUrl) : item.pdpUrl,
    shippingOptions,
    selectedShippingOption: shippingOptions.find(({ selected }) => selected)
  }
}
