import { parseURL } from 'ufo'
import type { FetchOptions, FetchResponse } from 'ofetch'
import type { LogConfig, LogMeta } from '#types/logger'

export interface ApiLogParams {
  apiName?: string
  path: ComputedRef<string>
  method: string
  options?: FetchOptions
  params: Record<string, string>
  error?: Error
  response?: FetchResponse<any>
  logKey: string
  logConfig: LogConfig
}

const getDurationStartMarkName = (logKey: string) => `api:${logKey}:start`
const getDurationEndMarkName = (logKey: string) => `api:${logKey}:end`
const getDurationMeasureName = (logKey: string) => `api:${logKey}:duration`

export const handleRequestStartForLogging = ({ logKey }: ApiLogParams) => {
  try {
    performance.mark(getDurationStartMarkName(logKey))
  }
  catch (error) {
    log.error(error)
  }
}

const getCallDuration = (logKey: string) => {
  const startMark = getDurationStartMarkName(logKey)
  const endMark = getDurationEndMarkName(logKey)
  const measureName = getDurationMeasureName(logKey)

  performance.mark(endMark)

  const { duration } = performance.measure(measureName, startMark, endMark)

  performance.clearMarks(startMark)
  performance.clearMarks(endMark)
  performance.clearMeasures(measureName)

  return duration
}

const measureCallPerformance = (meta: LogMeta) => {
  if (meta.api?.responseTime
    && (import.meta.server || window.__NUXT__!.state.$sfeatureFlags?.enableClientIngest)) {
    try {
      ingestPerformanceMeasures([{
        measure: {
          name: 'api_performance',
          duration: meta.api.responseTime || 0
        },
        labels: {
          action: 'network',
          api_name: meta.api.apiName,
          location: import.meta.server ? 'server' : 'client',
          response_code: meta.api.statusCode
        }
      }])
    }
    catch (e) {
      log.error(e)
    }
  }
}

const getLogMeta = ({ path, method, options, params, response, error, logKey, apiName }: ApiLogParams): LogMeta => ({
  channel: 'api',
  stackTrace: error?.stack,
  api: {
    apiName,
    path: path.value,
    host: parseURL(response?.url).host,
    method: unref(method || 'get'),
    queryParams: unref(params),
    queryString: options?.query,
    responseTime: response && getCallDuration(logKey),
    statusCode: response?.status,
    baseUrl: options?.baseURL,
    referrer: options?.headers?.['referer' as const],
    transactionId: options?.headers?.['x-transaction-id']
  }
})

export const addRequest = (
  req: {
    path: string
    method: string
    headers?: HeadersInit
    baseURL?: string
    query?: Record<string, any>
  },
  server
) => {
  const host = server ? 'server' : 'client'
  const query = new URLSearchParams(req.query).toString()
  let baseURL = new Headers(req.headers).get('baseURL') || req.baseURL

  if (req.path.includes('/fapi'))
    baseURL = baseURL?.replace('/api', '')

  useNuxtApp().$logRequest(host, req.method, `${baseURL}${req.path}?${query}`)
}

export const logRequestError = (logParams: ApiLogParams) => {
  try {
    const isPreload = !!logParams.options?.headers?.['Preload' as const]
    let logLevel = 'error'

    performance.clearMarks(getDurationStartMarkName(logParams.logKey))

    if (logParams.error?.name === 'AbortError' || isPreload) { // Generally happens with debounced user input, e.g. search suggestions
      logLevel = 'info'

      // The aborted request will be retried, we restart the measure so it can apply to the last successful request
      handleRequestStartForLogging(logParams)
    }

    log[logLevel](`API Request Error: ${logParams.error}`, getLogMeta(logParams), logParams.logConfig)
  }
  catch (error) {
    log.error(error)
  }
}

export const logResponseError = (logParams: ApiLogParams) => {
  try {
    const errorMessage = logParams.error || logParams.response?.statusText
    log.error(`API Response Error: ${errorMessage}`, getLogMeta(logParams), logParams.logConfig)
  }
  catch (error) {
    log.error(error)
  }
}

export const logResponseSuccess = (logParams: ApiLogParams, logSuccess = true) => {
  if (logParams?.response?.ok) {
    try {
      if (logSuccess) {
        const meta = getLogMeta(logParams)

        log.info('API Success', meta, logParams.logConfig)
        measureCallPerformance(meta)
      }
      else {
        performance.clearMarks(getDurationStartMarkName(logParams.logKey))
      }
    }
    catch (error) {
      log.error(error)
    }
  }
}
