import type { SwappedObject } from '#types/common'

/**
 * Swaps the keys of a nested object, transposing the keys and values.
 *
 * This function takes an object with nested objects and produces a new object where
 * the outer object's keys become the inner objects' keys and vice versa.
 * It also supports custom key mapping through an optional parameter.
 *
 * @typeParam T - The type of the input object.
 *                Must extend a record with string keys and values of any type.
 *
 * @param obj - The input object whose keys are to be swapped.
 * @param map - An optional parameter for custom key mapping.
 *              Use a format where outer keys are paired with new keys, e.g., { url: 'src' }.
 * @returns A new object with the keys swapped.
 *          The nested objects' keys become the outer object's keys,
 *          and the outer object's keys become the nested objects' keys.
 *          When a map is provided, it swaps based on the specified mapping.
 *
 * @example
 * ```typescript
 * const input = { a: { x: 1, y: 2 }, b: { x: 3, y: 4 } };
 * const result = swapObjectKeys(input);
 * console.log(result); // { x: { a: 1, b: 3 }, y: { a: 2, b: 4 } }
 *
 * const source = { sm: { url: '' }, md: { url: '' }, lg: { url: '' } }
 * const mappedResult = swapObjectKeys(source, { url: 'src' })
 * console.log(mappedResult); // { src: { sm: '', md: '', lg: '' } }
 * ```
 */

export const swapObjectKeys = <T extends Record<string, any>>(
  obj: T,
  map?: Record<string, string>
): SwappedObject<T> => {
  const result = Object.create(null) as SwappedObject<T>

  if (map) {
    const [[outerKey, innerKey]] = Object.entries(map)
    for (const outer in obj) {
      if (outerKey in obj[outer]) {
        if (!result[innerKey]) result[innerKey] = {} as any
        result[innerKey][outer] = obj[outer][outerKey]
      }
    }
    return result
  }

  for (const key1 in obj) {
    for (const key2 in obj[key1]) {
      if (!result[key2]) result[key2] = {} as any
      result[key2][key1] = obj[key1][key2]
    }
  }

  return result
}
