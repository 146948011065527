import type { PromoSummary } from '#root/api/clients/cart/data-contracts'
import type { ApproachingDiscount, ApproachingPromotion } from '#types/cart'

export const useApproachingPromotionsStore = defineStore('approachingPromotions', () => {
  const { $t } = useNuxtApp()
  const approachingPromotions = ref<ApproachingPromotion[]>([])

  const splitMessages = (calloutMessage?: string): string[] => {
    if (!calloutMessage) return []
    // the callout message property is expected to have messaging for all stages split by pipe character "|"
    return calloutMessage?.split('|').map((msg: string) => msg.trim()) || []
  }

  const reset = () => {
    approachingPromotions.value = []
  }

  const isGiftWithPurchasePromotion = (promo: ApproachingDiscount | PromoSummary) => promo.promotionId.includes('GWP')

  const getGiftWithPurchaseMessage = (promo: ApproachingDiscount) => {
    if (promo.promotionId.includes('GUEST')) {
      return $t.approachingGiftWithProduct.guest
    }
    else if (promo.promotionId.includes('REGISTERED')) {
      return $t.approachingGiftWithProduct.registered
    }
    else {
      return replaceAll($t.approachingGiftWithProduct.default, {
        distanceFromConditionThreshold: useFormattedPrice(promo.distanceFromConditionThreshold, useCurrencyCode())
      })
    }
  }

  const collectApproachingPromotions = (approachingDiscounts: ApproachingDiscount[] = []) => {
    // process not yet applied promos with unmet activation criteria
    approachingDiscounts.forEach((promo) => {
      if (isGiftWithPurchasePromotion(promo)) {
        const message = getGiftWithPurchaseMessage(promo)
        if (message) {
          approachingPromotions.value.push({
            contexts: promo.approachingDisplayLevel || [],
            id: promo.promotionId,
            message,
          })
        }
      }
      else {
        const messages = splitMessages(promo.calloutMsg)
        if (messages.length !== 3) return // skip if promotion is misconfigured
        const message = promo.distanceFromConditionThreshold
          ? replaceAll(messages[1], {
            distanceFromConditionThreshold: useFormattedPrice(promo.distanceFromConditionThreshold, useCurrencyCode())
          })
          : messages[0]
        approachingPromotions.value.push({ id: promo.promotionId, message, contexts: promo.approachingDisplayLevel })
      }
    })
  }

  const collectAppliedPromotions = (promoSummary: PromoSummary[] = []) => {
    promoSummary.forEach((promo) => {
      if (isGiftWithPurchasePromotion(promo)) {
        approachingPromotions.value.push({
          contexts: promo.approachingDisplayLevel || [],
          id: promo.promotionId,
          message: $t.approachingGiftWithProduct.earned,
        })
      }
      else {
        if (!promo?.calloutMessage) return
        const messages = splitMessages(promo.calloutMessage)
        if (messages.length !== 3) return // skip if promotion is misconfigured
        approachingPromotions.value.push(
          { id: promo.promotionId, message: messages[2], contexts: promo.approachingDisplayLevel || [] }
        )
      }
    })
  }

  const process = ({ approachingDiscount, promoSummary }: {
    approachingDiscount: ApproachingDiscount[]
    promoSummary: PromoSummary[]
  }) => {
    reset()
    collectApproachingPromotions(approachingDiscount)
    collectAppliedPromotions(promoSummary)
  }

  const get = (context?: ApproachingDiscount['approachingDisplayLevel'][number]) => {
    return context
      ? approachingPromotions.value.filter(({ contexts }) => !contexts.length || contexts.includes(context))
      : approachingPromotions.value
  }

  const dismiss = (id: string) => {
    const promo = approachingPromotions.value.find((promo) => promo.id === id)
    if (!promo) return
    approachingPromotions.value.splice(approachingPromotions.value.indexOf(promo), 1)
  }

  return {
    dismiss,
    get,
    process,
    reset,
    splitMessages
  }
})
