<template>
  <div>
    <base-collapse
      v-if="content.children"
      v-model="isExpanded"
      class-toggle="w-full"
      @click.stop="handleCollapse(`${l1} - ${content.title}`)"
    >
      <template #toggle="{ expanded }">
        <span class="flex items-center px-4 between" :class="brandClasses.title">
          <span :class="presets?.[content.menuStyle!]">{{ content.title }}</span>
          <vf-icon v-if="content.children" :dir="expanded ? 'up' : 'down'" name="chevron" size="lg" />
        </span>
      </template>
      <ul v-if="content.children?.length" class="text-sm ">
        <cms-mega-menu-l3-mobile
          v-if="$feature.showShopAllL3sOnMobile && !content.hideShopAll?.sm"
          :content="{
            id: `${content.id}-shop-all`,
            type: 'VfCanvasMegaMenuL3',
            title: `${$t.megaMenuShopAll} ${content.title}`,
            target: { id: `${content.id}-target`, url: content.target?.url, type: 'CMExternalChannel' },
          }"
        />
        <li v-for="menuItem in content.children" :key="menuItem.id">
          <cms-section :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }" />
        </li>
      </ul>
    </base-collapse>
    <base-link
      v-else
      :class="[presets?.[content.menuStyle!], brandClasses.link]"
      :target="content.target?.targetAttribute"
      :to="content.target?.url"
      @click.stop="$emit('click', `${l1} - ${content.title}`)"
    >
      {{ content.title }}
    </base-link>
  </div>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey } from './context'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{
  content: MenuItem
}>()

const emit = defineEmits<{
  click: [label: string]
  close: [label: string]
  expand: [label: string]
}>()

const { brandClasses, presets } = useAppConfig().components.cms.megaMenuL2Mobile

const l1 = inject(MegaMenuL1ContextKey)
const isExpanded = ref(false)

const handleCollapse = (fullTitle: string) => {
  isExpanded.value ? emit('expand', fullTitle) : emit('close', fullTitle)
}
</script>
