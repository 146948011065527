<template v-slot="{ open, close, opened, toggle }">
  <div class="flex between">
    <vf-link
      v-if="!content.hideShopAll?.lg"
      class="flex"
      :class="[classLink, brandClasses.shopAll]"
      :target="content.target?.targetAttribute"
      :to="content.target?.url"
      variant="quieter"
      @click="$emit('click', content.overrideShopAll || content.title)"
    >
      {{ content.overrideShopAll || `${$t.megaMenuShopAll} ${content.title}` }}
      <vf-icon :name="linkIcon" size="md" />
    </vf-link>
    <base-button v-if="showClose" :aria-label="$t.close" class="ml-a" @click="forceClose">
      <vf-icon name="close" :size="closeIconSize" />
    </base-button>
  </div>
</template>

<script setup lang="ts">
import { PopoverContextKey } from '#core/components/base/popover/context'
import type { MenuItem } from '#types/components/cms/mega-menu'
import type { CSSClass } from '#types/common'

defineProps<{
  classLink?: CSSClass
  content: MenuItem
  showClose?: boolean
}>()

defineEmits<{
  click: [label: string]
}>()

const {
  megaMenu: { closeIconSize, linkIcon },
  megaMenuL1Desktop: { brandClasses }
} = useAppConfig().components.cms

const { forceClose } = inject(PopoverContextKey)!
</script>
