/**
 * Get content alignment from string.
 * Example: '1-left' -> 1
 * Example: '3-right' -> 3
 * Example: 'left-left' -> null
 * Example: 'left-right' -> null
 */
export const getAlignment = (str: string): number | null => {
  const match = str.match(/^\d/)
  return match ? Number.parseInt(match[0]) : null
}
