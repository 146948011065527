import type { Dialogs } from '#build/dialogs'
import { createDialog as _createDialog } from '#core/utils/createDialog'
import type { DialogBaseOptions } from '#types/config/components/dialog'
import type { Props as ModalProps } from '#brands/components/vf/modal/Modal.vue'
import type { Props as PanelProps } from '#brands/components/vf/panel/Panel.vue'
import { VfModal, VfPanel } from '#components'

/**
 * Creates a dialog component based on the provided options.
 * @param name The name of the dialog.
 * @param options An optional object containing dialog configuration options.
 * @param options.type type property.
 * @param options.position position property.
 * @param options.size size property.
 * @param options.teleport teleport property.
 * @param options.singleton singleton property.
 * @returns A dialog component.
 * @category Utils
 */
export function createDialog<Name extends keyof Dialogs, WrapperProps>(
  name: Name,
  options?: {
    type: MaybeRefOrGetter<DialogBaseOptions['type'] | undefined>
    position?: DialogBaseOptions['position']
    size?: DialogBaseOptions['size']
    teleport?: MaybeRefOrGetter<boolean>
    singleton?: boolean
  }
) {
  return _createDialog<Name, WrapperProps, Dialogs>(name, {
    transition: {
      name: 'nested',
      appear: true,
      duration: 250
    },
    ...options,
    wrapper: () => {
      const { $viewport } = useNuxtApp()
      const { dialogOptions: defaultOptions } = useAppConfig().components.dialog.default
      const wrapperValue = toValue(options?.type) ?? defaultOptions.type

      const _wrapper = isObject(wrapperValue)
        ? getValueForBreakpoint($viewport.breakpoint, wrapperValue)
        : wrapperValue

      return _wrapper
        ? {
            modal: VfModal,
            panel: VfPanel
          }[_wrapper]
        : null
    }
  })
}

/**
 * Creates a modal component based on the provided options.
 * @param name The name of the modal.
 * @param options An optional object containing modal configuration options.
 * @param options.position position property.
 * @param options.size size property.
 * @param options.singleton singleton property.
 * @returns A modal component.
 */
export function createModal<Name extends keyof Dialogs>(
  name: Name,
  options?: {
    position?: DialogBaseOptions['position']
    size?: DialogBaseOptions['size']
    singleton?: boolean
  }
) {
  return createDialog<Name, ModalProps>(name, { ...options, type: 'modal' })
}

/**
 * Creates a panel component based on the provided options.
 * @param name The name of the panel.
 * @param options An optional object containing panel configuration options.
 * @param options.position position property.
 * @param options.size size property.
 * @param options.singleton singleton property.
 * @returns A panel component.
 */
export function createPanel<Name extends keyof Dialogs>(
  name: Name,
  options?: {
    position?: DialogBaseOptions['position']
    size?: DialogBaseOptions['size']
    singleton?: boolean
  }
) {
  return createDialog<Name, PanelProps>(name, { ...options, type: 'panel' })
}
