/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  BasecampData,
  EspotsAccountData,
  EspotsAccountProfileData,
  EspotsCartData,
  EspotsCategoryData,
  EspotsCheckoutData,
  EspotsContactUsData,
  EspotsError4XxData,
  EspotsGenericData,
  EspotsGiftCardData,
  EspotsLoyaltyModalData,
  EspotsOrderConfirmationData,
  EspotsProductData,
  EspotsPromotionData,
  EspotsSearchData,
  EspotsSignUpData,
  EspotsSignUpModalData,
  EspotsStoreLocatorData,
  FooterData,
  FragmentData,
  MenuData,
  PageChildrenData,
  PageData,
  PopupsData,
  SearchData,
  ShowcaseData,
  WestendData,
} from "./data-contracts";

export class Content extends HttpClient {
  static paths = {
    espotsGeneric: "/:mode/caas/v1/canvas/augmentedpage/:siteId/:page",
    espotsAccount: "/:mode/caas/v1/canvas/augmentedpage/:siteId/account",
    espotsAccountProfile: "/:mode/caas/v1/canvas/augmentedpage/:siteId/account-profile",
    basecamp: "/:mode/caas/v1/canvas/augmentedpage/:siteId/basecamp",
    espotsCart: "/:mode/caas/v1/canvas/augmentedpage/:siteId/cart",
    espotsCheckout: "/:mode/caas/v1/canvas/augmentedpage/:siteId/checkout",
    espotsContactUs: "/:mode/caas/v1/canvas/augmentedpage/:siteId/contact-us",
    espotsError4Xx: "/:mode/caas/v1/canvas/augmentedpage/:siteId/error-4xx",
    espotsGiftCard: "/:mode/caas/v1/canvas/augmentedpage/:siteId/gift-card",
    espotsLoyaltyModal: "/:mode/caas/v1/canvas/augmentedpage/:siteId/loyalty-modal",
    espotsOrderConfirmation: "/:mode/caas/v1/canvas/augmentedpage/:siteId/order-confirmation",
    espotsPromotion: "/:mode/caas/v1/canvas/augmentedpage/:siteId/promotions",
    espotsSearch: "/:mode/caas/v1/canvas/augmentedpage/:siteId/search",
    espotsSignUp: "/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up",
    espotsSignUpModal: "/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up-modal",
    espotsStoreLocator: "/:mode/caas/v1/canvas/augmentedpage/:siteId/store-locator",
    westend: "/:mode/caas/v1/canvas/augmentedpage/:siteId/westend",
    espotsCategory: "/:mode/caas/v1/canvas/category/:siteId/:categoryId",
    search: "/:mode/caas/v1/canvas/search/:siteId/:searchType",
    footer: "/:mode/caas/v1/canvas/footer/:siteId",
    fragment: "/:mode/caas/v1/canvas/fragment/:fragmentId",
    menu: "/:mode/caas/v1/canvas/menu/:siteId",
    page: "/:mode/caas/v1/canvas/page/:siteId/:page",
    pageChildren: "/:mode/caas/v1/canvas/children/:siteId/:parentPageSlug",
    popups: "/:mode/caas/v1/canvas/popups/:siteId",
    espotsProduct: "/:mode/caas/v1/canvas/product/:siteId/:productId",
    showcase: "/:mode/caas/v1/canvas/showcase/:siteId/:docType",
  };
  static mockDataPaths = {
    espotsGeneric: "eSpotsGeneric",
    espotsAccount: "eSpotsAccount",
    espotsAccountProfile: "eSpotsAccountProfile",
    basecamp: "basecamp",
    espotsCart: "eSpotsCart",
    espotsCheckout: "eSpotsCheckout",
    espotsContactUs: "eSpotsContactUs",
    espotsError4Xx: "eSpotsError4xx",
    espotsGiftCard: "eSpotsGiftCard",
    espotsLoyaltyModal: "eSpotsLoyaltyModal",
    espotsOrderConfirmation: "eSpotsOrderConfirmation",
    espotsPromotion: "eSpotsPromotion",
    espotsSearch: "eSpotsSearch",
    espotsSignUp: "eSpotsSignUp",
    espotsSignUpModal: "eSpotsSignUpModal",
    espotsStoreLocator: "eSpotsStoreLocator",
    westend: "westend",
    espotsCategory: "eSpotsCategory",
    page: "page",
    pageChildren: "pageChildren",
    espotsProduct: "eSpotsProduct",
  };
  static mockedPaths = {
    espotsGeneric: false,
    espotsAccount: false,
    espotsAccountProfile: false,
    basecamp: false,
    espotsCart: false,
    espotsCheckout: false,
    espotsContactUs: false,
    espotsError4Xx: false,
    espotsGiftCard: false,
    espotsLoyaltyModal: false,
    espotsOrderConfirmation: false,
    espotsPromotion: false,
    espotsSearch: false,
    espotsSignUp: false,
    espotsSignUpModal: false,
    espotsStoreLocator: false,
    westend: false,
    espotsCategory: false,
    search: false,
    footer: false,
    fragment: false,
    menu: false,
    page: false,
    pageChildren: false,
    popups: false,
    espotsProduct: false,
    showcase: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "content");
  }

  /**
   * No description
   *
   * @tags Content
   * @name EspotsGeneric
   * @summary Generic E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/{page}
   */
  espotsGeneric = <
    ResT = EspotsGenericData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    page: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/:page")
        ? computed(() =>
            mockPath(
              "Content",
              "espotsGeneric",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.page:${toValue(page)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/${toValue(page)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/:page",
      apiName: "Content.espotsGeneric",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsGeneric
   * @summary Generic E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/{page}
   */
  $espotsGeneric = (mode: "publish" | "preview", siteId: string, page: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsGenericData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/:page")
        ? mockPath("Content", "espotsGeneric", `mode:${toValue(mode)}.siteId:${toValue(siteId)}.page:${toValue(page)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/${page}`,
      method: "GET",
      apiName: "Content.espotsGeneric",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsAccount
   * @summary Account Dashboard E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/account
   */
  espotsAccount = <
    ResT = EspotsAccountData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/account")
        ? computed(() => mockPath("Content", "espotsAccount", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/account`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/account",
      apiName: "Content.espotsAccount",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsAccount
   * @summary Account Dashboard E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/account
   */
  $espotsAccount = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsAccountData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/account")
        ? mockPath("Content", "espotsAccount", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/account`,
      method: "GET",
      apiName: "Content.espotsAccount",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsAccountProfile
   * @summary Account Profile E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/account-profile
   */
  espotsAccountProfile = <
    ResT = EspotsAccountProfileData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/account-profile")
        ? computed(() => mockPath("Content", "espotsAccountProfile", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/account-profile`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/account-profile",
      apiName: "Content.espotsAccountProfile",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsAccountProfile
   * @summary Account Profile E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/account-profile
   */
  $espotsAccountProfile = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsAccountProfileData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/account-profile")
        ? mockPath("Content", "espotsAccountProfile", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/account-profile`,
      method: "GET",
      apiName: "Content.espotsAccountProfile",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Basecamp
   * @summary Basecamp Page
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/basecamp
   */
  basecamp = <ResT = BasecampData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/basecamp")
        ? computed(() => mockPath("Content", "basecamp", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/basecamp`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/basecamp",
      apiName: "Content.basecamp",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Basecamp
   * @summary Basecamp Page
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/basecamp
   */
  $basecamp = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<BasecampData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/basecamp")
        ? mockPath("Content", "basecamp", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/basecamp`,
      method: "GET",
      apiName: "Content.basecamp",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCart
   * @summary Cart E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/cart
   */
  espotsCart = <ResT = EspotsCartData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/cart")
        ? computed(() => mockPath("Content", "espotsCart", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/cart`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/cart",
      apiName: "Content.espotsCart",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCart
   * @summary Cart E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/cart
   */
  $espotsCart = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsCartData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/cart")
        ? mockPath("Content", "espotsCart", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/cart`,
      method: "GET",
      apiName: "Content.espotsCart",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCheckout
   * @summary Checkout E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/checkout
   */
  espotsCheckout = <
    ResT = EspotsCheckoutData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/checkout")
        ? computed(() => mockPath("Content", "espotsCheckout", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/checkout`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/checkout",
      apiName: "Content.espotsCheckout",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCheckout
   * @summary Checkout E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/checkout
   */
  $espotsCheckout = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsCheckoutData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/checkout")
        ? mockPath("Content", "espotsCheckout", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/checkout`,
      method: "GET",
      apiName: "Content.espotsCheckout",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsContactUs
   * @summary Contact Us E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/contact-us
   */
  espotsContactUs = <
    ResT = EspotsContactUsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/contact-us")
        ? computed(() => mockPath("Content", "espotsContactUs", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/contact-us`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/contact-us",
      apiName: "Content.espotsContactUs",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsContactUs
   * @summary Contact Us E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/contact-us
   */
  $espotsContactUs = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsContactUsData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/contact-us")
        ? mockPath("Content", "espotsContactUs", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/contact-us`,
      method: "GET",
      apiName: "Content.espotsContactUs",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsError4Xx
   * @summary Error4xx E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/error-4xx
   */
  espotsError4Xx = <
    ResT = EspotsError4XxData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/error-4xx")
        ? computed(() => mockPath("Content", "espotsError4Xx", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/error-4xx`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/error-4xx",
      apiName: "Content.espotsError4Xx",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsError4Xx
   * @summary Error4xx E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/error-4xx
   */
  $espotsError4Xx = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsError4XxData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/error-4xx")
        ? mockPath("Content", "espotsError4Xx", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/error-4xx`,
      method: "GET",
      apiName: "Content.espotsError4Xx",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsGiftCard
   * @summary Gift Card E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/gift-card
   */
  espotsGiftCard = <
    ResT = EspotsGiftCardData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/gift-card")
        ? computed(() => mockPath("Content", "espotsGiftCard", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/gift-card`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/gift-card",
      apiName: "Content.espotsGiftCard",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsGiftCard
   * @summary Gift Card E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/gift-card
   */
  $espotsGiftCard = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsGiftCardData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/gift-card")
        ? mockPath("Content", "espotsGiftCard", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/gift-card`,
      method: "GET",
      apiName: "Content.espotsGiftCard",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsLoyaltyModal
   * @summary Loyalty Modal E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/loyalty-modal
   */
  espotsLoyaltyModal = <
    ResT = EspotsLoyaltyModalData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/loyalty-modal")
        ? computed(() => mockPath("Content", "espotsLoyaltyModal", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/loyalty-modal`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/loyalty-modal",
      apiName: "Content.espotsLoyaltyModal",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsLoyaltyModal
   * @summary Loyalty Modal E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/loyalty-modal
   */
  $espotsLoyaltyModal = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsLoyaltyModalData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/loyalty-modal")
        ? mockPath("Content", "espotsLoyaltyModal", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/loyalty-modal`,
      method: "GET",
      apiName: "Content.espotsLoyaltyModal",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsOrderConfirmation
   * @summary Order Confirmation E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/order-confirmation
   */
  espotsOrderConfirmation = <
    ResT = EspotsOrderConfirmationData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/order-confirmation")
        ? computed(() =>
            mockPath("Content", "espotsOrderConfirmation", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/order-confirmation`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/order-confirmation",
      apiName: "Content.espotsOrderConfirmation",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsOrderConfirmation
   * @summary Order Confirmation E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/order-confirmation
   */
  $espotsOrderConfirmation = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsOrderConfirmationData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/order-confirmation")
        ? mockPath("Content", "espotsOrderConfirmation", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/order-confirmation`,
      method: "GET",
      apiName: "Content.espotsOrderConfirmation",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsPromotion
   * @summary Promotions E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/promotions
   */
  espotsPromotion = <
    ResT = EspotsPromotionData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/promotions")
        ? computed(() => mockPath("Content", "espotsPromotion", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/promotions`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/promotions",
      apiName: "Content.espotsPromotion",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsPromotion
   * @summary Promotions E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/promotions
   */
  $espotsPromotion = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsPromotionData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/promotions")
        ? mockPath("Content", "espotsPromotion", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/promotions`,
      method: "GET",
      apiName: "Content.espotsPromotion",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSearch
   * @summary Search Results E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/search
   */
  espotsSearch = <
    ResT = EspotsSearchData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/search")
        ? computed(() => mockPath("Content", "espotsSearch", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/search`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/search",
      apiName: "Content.espotsSearch",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSearch
   * @summary Search Results E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/search
   */
  $espotsSearch = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsSearchData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/search")
        ? mockPath("Content", "espotsSearch", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/search`,
      method: "GET",
      apiName: "Content.espotsSearch",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSignUp
   * @summary Sign-Up / Registration E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/sign-up
   */
  espotsSignUp = <
    ResT = EspotsSignUpData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up")
        ? computed(() => mockPath("Content", "espotsSignUp", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/sign-up`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up",
      apiName: "Content.espotsSignUp",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSignUp
   * @summary Sign-Up / Registration E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/sign-up
   */
  $espotsSignUp = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsSignUpData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up")
        ? mockPath("Content", "espotsSignUp", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/sign-up`,
      method: "GET",
      apiName: "Content.espotsSignUp",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSignUpModal
   * @summary Sign-Up / Registration Modal E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/sign-up-modal
   */
  espotsSignUpModal = <
    ResT = EspotsSignUpModalData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up-modal")
        ? computed(() => mockPath("Content", "espotsSignUpModal", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/sign-up-modal`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up-modal",
      apiName: "Content.espotsSignUpModal",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsSignUpModal
   * @summary Sign-Up / Registration Modal E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/sign-up-modal
   */
  $espotsSignUpModal = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsSignUpModalData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/sign-up-modal")
        ? mockPath("Content", "espotsSignUpModal", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/sign-up-modal`,
      method: "GET",
      apiName: "Content.espotsSignUpModal",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsStoreLocator
   * @summary StoreLocator E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/store-locator
   */
  espotsStoreLocator = <
    ResT = EspotsStoreLocatorData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/store-locator")
        ? computed(() => mockPath("Content", "espotsStoreLocator", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/store-locator`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/store-locator",
      apiName: "Content.espotsStoreLocator",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsStoreLocator
   * @summary StoreLocator E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/store-locator
   */
  $espotsStoreLocator = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsStoreLocatorData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/store-locator")
        ? mockPath("Content", "espotsStoreLocator", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/store-locator`,
      method: "GET",
      apiName: "Content.espotsStoreLocator",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Westend
   * @summary Westend Page
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/westend
   */
  westend = <ResT = WestendData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/westend")
        ? computed(() => mockPath("Content", "westend", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/augmentedpage/${toValue(siteId)}/westend`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/augmentedpage/:siteId/westend",
      apiName: "Content.westend",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Westend
   * @summary Westend Page
   * @request GET:/{mode}/caas/v1/canvas/augmentedpage/{siteId}/westend
   */
  $westend = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<WestendData>({
      path: isMocked("/:mode/caas/v1/canvas/augmentedpage/:siteId/westend")
        ? mockPath("Content", "westend", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/augmentedpage/${siteId}/westend`,
      method: "GET",
      apiName: "Content.westend",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCategory
   * @summary Category E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/category/{siteId}/{category-id}
   */
  espotsCategory = <
    ResT = EspotsCategoryData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    categoryId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/category/:siteId/:categoryId")
        ? computed(() =>
            mockPath(
              "Content",
              "espotsCategory",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.categoryId:${toValue(categoryId)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/category/${toValue(siteId)}/${toValue(categoryId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/category/:siteId/:categoryId",
      apiName: "Content.espotsCategory",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsCategory
   * @summary Category E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/category/{siteId}/{category-id}
   */
  $espotsCategory = (mode: "publish" | "preview", siteId: string, categoryId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsCategoryData>({
      path: isMocked("/:mode/caas/v1/canvas/category/:siteId/:categoryId")
        ? mockPath(
            "Content",
            "espotsCategory",
            `mode:${toValue(mode)}.siteId:${toValue(siteId)}.categoryId:${toValue(categoryId)}`,
          )
        : `/${mode}/caas/v1/canvas/category/${siteId}/${categoryId}`,
      method: "GET",
      apiName: "Content.espotsCategory",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Search
   * @summary Search Content
   * @request GET:/{mode}/caas/v1/canvas/search/{siteId}/{searchType}
   */
  search = <ResT = SearchData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    searchType: MaybeRefOrGetter<"srp" | "article" | "page">,
    query?: {
      /** the search term */
      q?: string;
      /** limit results that live under a specific page context (the ID of a page) */
      contextId?: string;
      /** the ID of main tag that is used to filter */
      rootTagId?: string;
      /** the ID of the sub-tags that is used to filter (does not affect facets) */
      tagId?: string | string[];
      /** the ID of a content that will not be included as a result */
      excludeId?: string;
      /** start results from N */
      offset?: number;
      /** limit results to N */
      limit?: number;
      /** to control the order, append _asc and _desc to the sortBy value, e.g. score_desc */
      sortBy?:
        | "score"
        | "creationdate"
        | "freshness"
        | "title"
        | "externallydisplayeddate"
        | "score_asc"
        | "creationdate_asc"
        | "freshness_asc"
        | "title_asc"
        | "externallydisplayeddate_asc"
        | "score_desc"
        | "creationdate_desc"
        | "freshness_desc"
        | "title_desc"
        | "externallydisplayeddate_desc";
      /** when 'true', the results will be omitted, and only the count will be evaluated */
      countOnly?: boolean;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/search/:siteId/:searchType")
        ? computed(() =>
            mockPath(
              "Content",
              "search",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.searchType:${toValue(searchType)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/search/${toValue(siteId)}/${toValue(searchType)}`),
      method: "GET",
      query: query,
      key: "/:mode/caas/v1/canvas/search/:siteId/:searchType",
      apiName: "Content.search",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Search
   * @summary Search Content
   * @request GET:/{mode}/caas/v1/canvas/search/{siteId}/{searchType}
   */
  $search = (
    mode: "publish" | "preview",
    siteId: string,
    searchType: "srp" | "article" | "page",
    query?: {
      /** the search term */
      q?: string;
      /** limit results that live under a specific page context (the ID of a page) */
      contextId?: string;
      /** the ID of main tag that is used to filter */
      rootTagId?: string;
      /** the ID of the sub-tags that is used to filter (does not affect facets) */
      tagId?: string | string[];
      /** the ID of a content that will not be included as a result */
      excludeId?: string;
      /** start results from N */
      offset?: number;
      /** limit results to N */
      limit?: number;
      /** to control the order, append _asc and _desc to the sortBy value, e.g. score_desc */
      sortBy?:
        | "score"
        | "creationdate"
        | "freshness"
        | "title"
        | "externallydisplayeddate"
        | "score_asc"
        | "creationdate_asc"
        | "freshness_asc"
        | "title_asc"
        | "externallydisplayeddate_asc"
        | "score_desc"
        | "creationdate_desc"
        | "freshness_desc"
        | "title_desc"
        | "externallydisplayeddate_desc";
      /** when 'true', the results will be omitted, and only the count will be evaluated */
      countOnly?: boolean;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<SearchData>({
      path: isMocked("/:mode/caas/v1/canvas/search/:siteId/:searchType")
        ? mockPath(
            "Content",
            "search",
            `mode:${toValue(mode)}.siteId:${toValue(siteId)}.searchType:${toValue(searchType)}`,
          )
        : `/${mode}/caas/v1/canvas/search/${siteId}/${searchType}`,
      method: "GET",
      query: query,
      apiName: "Content.search",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Footer
   * @summary Footer Content
   * @request GET:/{mode}/caas/v1/canvas/footer/{siteId}
   */
  footer = <ResT = FooterData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/footer/:siteId")
        ? computed(() => mockPath("Content", "footer", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/footer/${toValue(siteId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/footer/:siteId",
      apiName: "Content.footer",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Footer
   * @summary Footer Content
   * @request GET:/{mode}/caas/v1/canvas/footer/{siteId}
   */
  $footer = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<FooterData>({
      path: isMocked("/:mode/caas/v1/canvas/footer/:siteId")
        ? mockPath("Content", "footer", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/footer/${siteId}`,
      method: "GET",
      apiName: "Content.footer",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Fragment
   * @summary Fragment Content
   * @request GET:/{mode}/caas/v1/canvas/fragment/{fragmentId}
   */
  fragment = <ResT = FragmentData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    fragmentId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/fragment/:fragmentId")
        ? computed(() => mockPath("Content", "fragment", `mode:${toValue(mode)}.fragmentId:${toValue(fragmentId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/fragment/${toValue(fragmentId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/fragment/:fragmentId",
      apiName: "Content.fragment",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Fragment
   * @summary Fragment Content
   * @request GET:/{mode}/caas/v1/canvas/fragment/{fragmentId}
   */
  $fragment = (mode: "publish" | "preview", fragmentId: string, requestParams: ApiOptions = {}) =>
    this.$request<FragmentData>({
      path: isMocked("/:mode/caas/v1/canvas/fragment/:fragmentId")
        ? mockPath("Content", "fragment", `mode:${toValue(mode)}.fragmentId:${toValue(fragmentId)}`)
        : `/${mode}/caas/v1/canvas/fragment/${fragmentId}`,
      method: "GET",
      apiName: "Content.fragment",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Menu
   * @summary Mega Menu Content
   * @request GET:/{mode}/caas/v1/canvas/menu/{siteId}
   */
  menu = <ResT = MenuData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/menu/:siteId")
        ? computed(() => mockPath("Content", "menu", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/menu/${toValue(siteId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/menu/:siteId",
      apiName: "Content.menu",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Menu
   * @summary Mega Menu Content
   * @request GET:/{mode}/caas/v1/canvas/menu/{siteId}
   */
  $menu = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<MenuData>({
      path: isMocked("/:mode/caas/v1/canvas/menu/:siteId")
        ? mockPath("Content", "menu", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/menu/${siteId}`,
      method: "GET",
      apiName: "Content.menu",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Page
   * @summary Page Content
   * @request GET:/{mode}/caas/v1/canvas/page/{siteId}/{page}
   */
  page = <ResT = PageData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    page: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/page/:siteId/:page")
        ? computed(() =>
            mockPath("Content", "page", `mode:${toValue(mode)}.siteId:${toValue(siteId)}.page:${toValue(page)}`),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/page/${toValue(siteId)}/${toValue(page)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/page/:siteId/:page",
      apiName: "Content.page",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Page
   * @summary Page Content
   * @request GET:/{mode}/caas/v1/canvas/page/{siteId}/{page}
   */
  $page = (mode: "publish" | "preview", siteId: string, page: string, requestParams: ApiOptions = {}) =>
    this.$request<PageData>({
      path: isMocked("/:mode/caas/v1/canvas/page/:siteId/:page")
        ? mockPath("Content", "page", `mode:${toValue(mode)}.siteId:${toValue(siteId)}.page:${toValue(page)}`)
        : `/${mode}/caas/v1/canvas/page/${siteId}/${page}`,
      method: "GET",
      apiName: "Content.page",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name PageChildren
   * @summary PageChildren Content
   * @request GET:/{mode}/caas/v1/canvas/children/{siteId}/{parentPageSlug}
   */
  pageChildren = <
    ResT = PageChildrenData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    parentPageSlug: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/children/:siteId/:parentPageSlug")
        ? computed(() =>
            mockPath(
              "Content",
              "pageChildren",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.parentPageSlug:${toValue(parentPageSlug)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/children/${toValue(siteId)}/${toValue(parentPageSlug)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/children/:siteId/:parentPageSlug",
      apiName: "Content.pageChildren",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name PageChildren
   * @summary PageChildren Content
   * @request GET:/{mode}/caas/v1/canvas/children/{siteId}/{parentPageSlug}
   */
  $pageChildren = (
    mode: "publish" | "preview",
    siteId: string,
    parentPageSlug: string,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<PageChildrenData>({
      path: isMocked("/:mode/caas/v1/canvas/children/:siteId/:parentPageSlug")
        ? mockPath(
            "Content",
            "pageChildren",
            `mode:${toValue(mode)}.siteId:${toValue(siteId)}.parentPageSlug:${toValue(parentPageSlug)}`,
          )
        : `/${mode}/caas/v1/canvas/children/${siteId}/${parentPageSlug}`,
      method: "GET",
      apiName: "Content.pageChildren",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Popups
   * @summary Popups Collection
   * @request GET:/{mode}/caas/v1/canvas/popups/{siteId}
   */
  popups = <ResT = PopupsData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/popups/:siteId")
        ? computed(() => mockPath("Content", "popups", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`))
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/popups/${toValue(siteId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/popups/:siteId",
      apiName: "Content.popups",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Popups
   * @summary Popups Collection
   * @request GET:/{mode}/caas/v1/canvas/popups/{siteId}
   */
  $popups = (mode: "publish" | "preview", siteId: string, requestParams: ApiOptions = {}) =>
    this.$request<PopupsData>({
      path: isMocked("/:mode/caas/v1/canvas/popups/:siteId")
        ? mockPath("Content", "popups", `mode:${toValue(mode)}.siteId:${toValue(siteId)}`)
        : `/${mode}/caas/v1/canvas/popups/${siteId}`,
      method: "GET",
      apiName: "Content.popups",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsProduct
   * @summary Product E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/product/{siteId}/{product-id}
   */
  espotsProduct = <
    ResT = EspotsProductData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    productId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/product/:siteId/:productId")
        ? computed(() =>
            mockPath(
              "Content",
              "espotsProduct",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.productId:${toValue(productId)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/product/${toValue(siteId)}/${toValue(productId)}`),
      method: "GET",
      key: "/:mode/caas/v1/canvas/product/:siteId/:productId",
      apiName: "Content.espotsProduct",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name EspotsProduct
   * @summary Product E-Spot Content
   * @request GET:/{mode}/caas/v1/canvas/product/{siteId}/{product-id}
   */
  $espotsProduct = (mode: "publish" | "preview", siteId: string, productId: string, requestParams: ApiOptions = {}) =>
    this.$request<EspotsProductData>({
      path: isMocked("/:mode/caas/v1/canvas/product/:siteId/:productId")
        ? mockPath(
            "Content",
            "espotsProduct",
            `mode:${toValue(mode)}.siteId:${toValue(siteId)}.productId:${toValue(productId)}`,
          )
        : `/${mode}/caas/v1/canvas/product/${siteId}/${productId}`,
      method: "GET",
      apiName: "Content.espotsProduct",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Showcase
   * @summary Get components by Type
   * @request GET:/{mode}/caas/v1/canvas/showcase/{siteId}/{docType}
   */
  showcase = <ResT = ShowcaseData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mode: MaybeRefOrGetter<"publish" | "preview">,
    siteId: MaybeRefOrGetter<string>,
    docType: MaybeRefOrGetter<string>,
    query?: {
      /**
       * Which layout variant of component to get
       * @example "imageStandard"
       */
      layoutVariant?: string;
      /** limit results to N */
      limit?: number;
      /** force CMS to another env */
      cms?: "uat" | "production";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/:mode/caas/v1/canvas/showcase/:siteId/:docType")
        ? computed(() =>
            mockPath(
              "Content",
              "showcase",
              `mode:${toValue(mode)}.siteId:${toValue(siteId)}.docType:${toValue(docType)}`,
            ),
          )
        : computed(() => `/${toValue(mode)}/caas/v1/canvas/showcase/${toValue(siteId)}/${toValue(docType)}`),
      method: "GET",
      query: query,
      key: "/:mode/caas/v1/canvas/showcase/:siteId/:docType",
      apiName: "Content.showcase",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name Showcase
   * @summary Get components by Type
   * @request GET:/{mode}/caas/v1/canvas/showcase/{siteId}/{docType}
   */
  $showcase = (
    mode: "publish" | "preview",
    siteId: string,
    docType: string,
    query?: {
      /**
       * Which layout variant of component to get
       * @example "imageStandard"
       */
      layoutVariant?: string;
      /** limit results to N */
      limit?: number;
      /** force CMS to another env */
      cms?: "uat" | "production";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ShowcaseData>({
      path: isMocked("/:mode/caas/v1/canvas/showcase/:siteId/:docType")
        ? mockPath("Content", "showcase", `mode:${toValue(mode)}.siteId:${toValue(siteId)}.docType:${toValue(docType)}`)
        : `/${mode}/caas/v1/canvas/showcase/${siteId}/${docType}`,
      method: "GET",
      query: query,
      apiName: "Content.showcase",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
