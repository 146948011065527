/**
 * Calculates the height of an element, including its vertical margins.
 * Useful for cases when an element is absolutely position,
 * so its parent's bounding rectangle can't calculate the height correctly.
 * @category Utils
 */
export function getOuterHeight(el: HTMLElement) {
  if (!el) return 0
  const style = getComputedStyle(el)

  return (
    el.clientHeight
    + Number.parseInt(style.marginTop)
    + Number.parseInt(style.marginBottom)
  )
}
