import type { CountryCode } from '#types/locale'
import type { PhoneCode } from '#types/config/phoneCode'

/**
 * @param phoneCode
 * @param countryCode
 * @returns the mask for the phone number if present, otherwise a default mask based on the region
 */
export const getPhoneMaskByPhoneCode = (phoneCode: string, countryCode?: Uppercase<CountryCode>): string | string[] => {
  const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]
  const fallbackMask = (getRegion(countryCode || useCountryCode()) === 'NORA' ? '(XXX) XXX - XXXX' : 'XXXX - XXX - XXX')
  // For some countries the phoneCode overlaps and its formated XX-XX to deduce what is the country of origin
  const isLocalizedPhoneCode = phoneCode.includes('-')
  if (isLocalizedPhoneCode)
    return phoneCodes.find(({ code, dialCode }) => phoneCode === `${dialCode}-${code}`)?.mask || fallbackMask

  const matchingPhoneCodes = phoneCodes.filter(
    ({ code, dialCode }) => (!countryCode || code === countryCode) && dialCode === phoneCode
  )
  if (!matchingPhoneCodes.length) return fallbackMask
  if (matchingPhoneCodes.length === 1)
    return matchingPhoneCodes[0]?.mask || fallbackMask

  // rare edge case when phoneCode is not local format but there are more than one country matching
  return matchingPhoneCodes.find(
    ({ code }) => code === useCountryCode()
  )?.mask || fallbackMask
}
