/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  AuthData,
  CreateUserData,
  CreateUserPayload,
  GetMediaByCustomerData,
  GetMediaByStreamData,
  GetMediaByTagKeyData,
  GetStreamByMediaData,
  ReportMediaData,
  ReportMediaPayload,
  SocialNativeResponseMedia,
  StashMediaData,
  StashMediaPayload,
  UploadMediaData,
  UploadMediaPayload,
} from "./data-contracts";

export class SocialNative extends HttpClient {
  static paths = {
    auth: "/",
    getMediaByCategory: "/categories/media/recent",
    getMediaByCustomer: "/customers/:customerId/media/recent",
    getStreamByMedia: "/media/:mediaId/streams",
    getMediaByStream: "/streams/:streamId/media/recent",
    getMediaByTagKey: "/streams/media/recent",
    reportMedia: "/media/:mediaId/reports",
    stashMedia: "/stashes",
    uploadMedia: "/users/:userId/media",
    createUser: "/users",
  };
  static mockDataPaths = {};
  static mockedPaths = {
    auth: false,
    getMediaByCategory: false,
    getMediaByCustomer: false,
    getStreamByMedia: false,
    getMediaByStream: false,
    getMediaByTagKey: false,
    reportMedia: false,
    stashMedia: false,
    uploadMedia: false,
    createUser: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "social-native");
  }

  /**
   * No description
   *
   * @tags Social-native
   * @name Auth
   * @summary Return the information of the authenticated customer
   * @request GET:/
   */
  auth = <ResT = AuthData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/") ? computed(() => mockPath("SocialNative", "auth", ``)) : computed(() => `/`),
      method: "GET",
      query: query,
      key: "/",
      apiName: "SocialNative.auth",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name Auth
   * @summary Return the information of the authenticated customer
   * @request GET:/
   */
  $auth = (
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<AuthData>({
      path: isMocked("/") ? mockPath("SocialNative", "auth", ``) : `/`,
      method: "GET",
      query: query,
      apiName: "SocialNative.auth",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByCategory
   * @summary Get media by Category ID
   * @request GET:/categories/media/recent
   */
  getMediaByCategory = <
    ResT = SocialNativeResponseMedia,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
      /** this is used to pass categoryId */
      tag_key: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/categories/media/recent")
        ? computed(() => mockPath("SocialNative", "getMediaByCategory", ``))
        : computed(() => `/categories/media/recent`),
      method: "GET",
      query: query,
      key: "/categories/media/recent",
      apiName: "SocialNative.getMediaByCategory",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByCategory
   * @summary Get media by Category ID
   * @request GET:/categories/media/recent
   */
  $getMediaByCategory = (
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
      /** this is used to pass categoryId */
      tag_key: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<SocialNativeResponseMedia>({
      path: isMocked("/categories/media/recent")
        ? mockPath("SocialNative", "getMediaByCategory", ``)
        : `/categories/media/recent`,
      method: "GET",
      query: query,
      apiName: "SocialNative.getMediaByCategory",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByCustomer
   * @summary Get media by Customer ID
   * @request GET:/customers/{customerId}/media/recent
   */
  getMediaByCustomer = <
    ResT = GetMediaByCustomerData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    customerId: MaybeRefOrGetter<string>,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/customers/:customerId/media/recent")
        ? computed(() => mockPath("SocialNative", "getMediaByCustomer", `customerId:${toValue(customerId)}`))
        : computed(() => `/customers/${toValue(customerId)}/media/recent`),
      method: "GET",
      query: query,
      key: "/customers/:customerId/media/recent",
      apiName: "SocialNative.getMediaByCustomer",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByCustomer
   * @summary Get media by Customer ID
   * @request GET:/customers/{customerId}/media/recent
   */
  $getMediaByCustomer = (
    customerId: string,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetMediaByCustomerData>({
      path: isMocked("/customers/:customerId/media/recent")
        ? mockPath("SocialNative", "getMediaByCustomer", `customerId:${toValue(customerId)}`)
        : `/customers/${customerId}/media/recent`,
      method: "GET",
      query: query,
      apiName: "SocialNative.getMediaByCustomer",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetStreamByMedia
   * @summary Get a stream by its media ID
   * @request GET:/media/{mediaId}/streams
   */
  getStreamByMedia = <
    ResT = GetStreamByMediaData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    mediaId: MaybeRefOrGetter<string>,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/media/:mediaId/streams")
        ? computed(() => mockPath("SocialNative", "getStreamByMedia", `mediaId:${toValue(mediaId)}`))
        : computed(() => `/media/${toValue(mediaId)}/streams`),
      method: "GET",
      query: query,
      key: "/media/:mediaId/streams",
      apiName: "SocialNative.getStreamByMedia",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetStreamByMedia
   * @summary Get a stream by its media ID
   * @request GET:/media/{mediaId}/streams
   */
  $getStreamByMedia = (
    mediaId: string,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetStreamByMediaData>({
      path: isMocked("/media/:mediaId/streams")
        ? mockPath("SocialNative", "getStreamByMedia", `mediaId:${toValue(mediaId)}`)
        : `/media/${mediaId}/streams`,
      method: "GET",
      query: query,
      apiName: "SocialNative.getStreamByMedia",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByStream
   * @summary Get a stream by its unique ID
   * @request GET:/streams/{streamId}/media/recent
   */
  getMediaByStream = <
    ResT = GetMediaByStreamData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    streamId: MaybeRefOrGetter<string>,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/streams/:streamId/media/recent")
        ? computed(() => mockPath("SocialNative", "getMediaByStream", `streamId:${toValue(streamId)}`))
        : computed(() => `/streams/${toValue(streamId)}/media/recent`),
      method: "GET",
      query: query,
      key: "/streams/:streamId/media/recent",
      apiName: "SocialNative.getMediaByStream",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByStream
   * @summary Get a stream by its unique ID
   * @request GET:/streams/{streamId}/media/recent
   */
  $getMediaByStream = (
    streamId: string,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetMediaByStreamData>({
      path: isMocked("/streams/:streamId/media/recent")
        ? mockPath("SocialNative", "getMediaByStream", `streamId:${toValue(streamId)}`)
        : `/streams/${streamId}/media/recent`,
      method: "GET",
      query: query,
      apiName: "SocialNative.getMediaByStream",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByTagKey
   * @summary Get media by Tag Key
   * @request GET:/streams/media/recent
   */
  getMediaByTagKey = <
    ResT = GetMediaByTagKeyData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
      tag_key: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/streams/media/recent")
        ? computed(() => mockPath("SocialNative", "getMediaByTagKey", ``))
        : computed(() => `/streams/media/recent`),
      method: "GET",
      query: query,
      key: "/streams/media/recent",
      apiName: "SocialNative.getMediaByTagKey",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name GetMediaByTagKey
   * @summary Get media by Tag Key
   * @request GET:/streams/media/recent
   */
  $getMediaByTagKey = (
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
      /**
       * Number of elements to return
       * @default 20
       */
      count: number;
      /**
       * Return elements starting from this page
       * @default 1
       */
      page_number: number;
      tag_key: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetMediaByTagKeyData>({
      path: isMocked("/streams/media/recent")
        ? mockPath("SocialNative", "getMediaByTagKey", ``)
        : `/streams/media/recent`,
      method: "GET",
      query: query,
      apiName: "SocialNative.getMediaByTagKey",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name ReportMedia
   * @summary Report a media object using a media identifier
   * @request POST:/media/{mediaId}/reports
   */
  reportMedia = <ResT = ReportMediaData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    mediaId: MaybeRefOrGetter<string>,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: ReportMediaPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/media/:mediaId/reports")
        ? computed(() => mockPath("SocialNative", "reportMedia", `mediaId:${toValue(mediaId)}`))
        : computed(() => `/media/${toValue(mediaId)}/reports`),
      method: "POST",
      query: query,
      body: data,
      key: "/media/:mediaId/reports",
      apiName: "SocialNative.reportMedia",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name ReportMedia
   * @summary Report a media object using a media identifier
   * @request POST:/media/{mediaId}/reports
   */
  $reportMedia = (
    mediaId: string,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: ReportMediaPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReportMediaData>({
      path: isMocked("/media/:mediaId/reports")
        ? mockPath("SocialNative", "reportMedia", `mediaId:${toValue(mediaId)}`)
        : `/media/${mediaId}/reports`,
      method: "POST",
      query: query,
      body: data,
      apiName: "SocialNative.reportMedia",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name StashMedia
   * @summary Stash a media for later consumption
   * @request POST:/stashes
   */
  stashMedia = <ResT = StashMediaData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: StashMediaPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/stashes")
        ? computed(() => mockPath("SocialNative", "stashMedia", ``))
        : computed(() => `/stashes`),
      method: "POST",
      query: query,
      body: data,
      key: "/stashes",
      apiName: "SocialNative.stashMedia",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name StashMedia
   * @summary Stash a media for later consumption
   * @request POST:/stashes
   */
  $stashMedia = (
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: StashMediaPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<StashMediaData>({
      path: isMocked("/stashes") ? mockPath("SocialNative", "stashMedia", ``) : `/stashes`,
      method: "POST",
      query: query,
      body: data,
      apiName: "SocialNative.stashMedia",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name UploadMedia
   * @summary Upload a media object for a user.
   * @request POST:/users/{user_id}/media
   */
  uploadMedia = <ResT = UploadMediaData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    userId: MaybeRefOrGetter<string>,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: UploadMediaPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/users/:userId/media")
        ? computed(() => mockPath("SocialNative", "uploadMedia", `userId:${toValue(userId)}`))
        : computed(() => `/users/${toValue(userId)}/media`),
      method: "POST",
      query: query,
      body: data,
      key: "/users/:userId/media",
      apiName: "SocialNative.uploadMedia",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name UploadMedia
   * @summary Upload a media object for a user.
   * @request POST:/users/{user_id}/media
   */
  $uploadMedia = (
    userId: string,
    query: {
      /** Authentication token */
      auth_token: string;
      /**
       * possible value are 0 or 1, ||  1 to indicate that the API should not return the 404 status code, and wrap the error into a 200 code instead.
       * @default 1
       */
      wrap_responses: 0 | 1;
    },
    data: UploadMediaPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<UploadMediaData>({
      path: isMocked("/users/:userId/media")
        ? mockPath("SocialNative", "uploadMedia", `userId:${toValue(userId)}`)
        : `/users/${userId}/media`,
      method: "POST",
      query: query,
      body: data,
      apiName: "SocialNative.uploadMedia",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name CreateUser
   * @summary Create Social Native user
   * @request POST:/users
   */
  createUser = <ResT = CreateUserData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /** Authentication token */
      auth_token: string;
    },
    data: CreateUserPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/users") ? computed(() => mockPath("SocialNative", "createUser", ``)) : computed(() => `/users`),
      method: "POST",
      query: query,
      body: data,
      key: "/users",
      apiName: "SocialNative.createUser",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Social-native
   * @name CreateUser
   * @summary Create Social Native user
   * @request POST:/users
   */
  $createUser = (
    query: {
      /**
       * API version
       * @default "v2.2"
       */
      version: "v2.2";
      /** Authentication token */
      auth_token: string;
    },
    data: CreateUserPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CreateUserData>({
      path: isMocked("/users") ? mockPath("SocialNative", "createUser", ``) : `/users`,
      method: "POST",
      query: query,
      body: data,
      apiName: "SocialNative.createUser",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
