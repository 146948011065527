<template>
  <base-form
    v-slot="{ reset }"
    class="flex items-start justify-center gap-4 <md:w-full <md:wrap"
    :form
    validate-on="change"
    @submit="submit"
  >
    <vf-form-field
      v-slot="{ attrs }"
      class-wrapper="w-full md:w-100"
      name="docType"
      :rule="validateRequired('Doc type is required')"
    >
      <vf-select
        v-model="form.docType"
        :options="docTypes"
        placeholder="Select doctype"
        v-bind="attrs"
        @update:model-value="reset"
      >
        Doctype
      </vf-select>
    </vf-form-field>

    <vf-input v-model="form.layoutVariant" class="<md:w-full">
      Layout Variant
    </vf-input>

    <div class="w-full md:w-25">
      <vf-select
        v-model="form.limit"
        :options="limitOptions"
        @update:model-value="reset"
      >
        Limit
      </vf-select>
    </div>
    <vf-button class="w-full md:w-25" type="submit">
      Go
    </vf-button>
  </base-form>
</template>

<script setup lang="ts">
import mappings from '#content/mappings'

type Form = {
  docType: string
  layoutVariant?: string
  limit: string
}

const route = useRoute()
const router = useRouter()

const excludedComponents = [
  'VfCanvasPromoBar',
  'VfCanvasMegaMenu',
  'VfCanvasMegaMenuL1',
  'VfCanvasMegaMenuL2',
  'VfCanvasMegaMenuL3',
  'VfCanvasMegaMenuMobile',
]

const docTypes = Object.keys(mappings).filter((m) => !excludedComponents.includes(m))
const limitOptions = ['10', '20', '50']

const form = reactive({
  docType: route.query.docType,
  layoutVariant: route.query.layoutVariant,
  limit: route.query.limit || limitOptions[1],
} as Form)

const submit = () => {
  Object.assign(form, Object.fromEntries(Object.entries(form).map(([k, v]) => ([k, v || undefined]))))

  router.push({
    query: {
      ...route.query,
      ...form
    }
  })
}
</script>
