/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  AddAddressData,
  AddAddressPayload,
  CancelOrderData,
  CancelOrderPayload,
  CreateProfileData,
  CreateSmsSubscriptionData,
  DeleteAddressData,
  DeletePaymentInstrumentData,
  GetLoyaltyTokenData,
  GetLoyaltyTokenPayload,
  GetLoyaltyVouchersData,
  GetLoyaltyWelcomePromoStatusData,
  GetOrderDetailsData,
  GetOrderHistoryData,
  GetOrderHistoryPayload,
  GetPaymentInstrumentsData,
  GetProfileInfoData,
  GetSmsSubscriptionInfoData,
  InvoiceDownloadData,
  InvoiceDownloadPayload,
  LookupData,
  NotifyMobileData,
  NotifyMobilePayload,
  ProfileInfoPayload,
  ResendGiftcardEmailData,
  ResendGiftcardEmailPayload,
  RetrieveUserDataData,
  RetrieveUserDataPayload,
  ReturnLabelData,
  SavePaymentInstrumentData,
  SavePaymentInstrumentPayload,
  TrackOrderData,
  TrackOrderPayload,
  UpdateAddressData,
  UpdateAddressPayload,
  UpdatePaymentInstrumentData,
  UpdatePaymentInstrumentPayload,
  UpdateProfileData,
  UpdateSmsSubscriptionData,
} from "./data-contracts";

export class Consumer extends HttpClient {
  static paths = {
    getProfileInfo: "/consumers-v3/consumer",
    updateProfile: "/consumers-v3/consumer",
    createProfile: "/consumers-v3/consumer",
    getLoyaltyToken: "/consumers-v3/consumer/loyalty/token",
    getLoyaltyWelcomePromoStatus: "/consumers-v3/consumer/loyalty/promo/welcome",
    getLoyaltyVouchers: "/consumers-v3/consumer/loyalty/vouchers",
    lookup: "/consumers-v3/consumer/lookup",
    getSmsSubscriptionInfo: "/consumers-v3/consumer/subscription",
    updateSmsSubscription: "/consumers-v3/consumer/subscription",
    createSmsSubscription: "/consumers-v3/consumer/subscription",
    getPaymentInstruments: "/consumers-v3/consumer/payment_instruments",
    savePaymentInstrument: "/consumers-v3/consumer/payment_instruments",
    updatePaymentInstrument: "/consumers-v3/consumer/payment_instruments/:paymentInstrumentId",
    deletePaymentInstrument: "/consumers-v3/consumer/payment_instruments/:paymentInstrumentId",
    getOrderDetails: "/consumers-v3/consumer/purchase/:orderId",
    invoiceDownload: "/consumers-v3/consumer/purchase/invoice/download",
    getOrderHistory: "/consumers-v3/consumer/purchasehistory",
    returnLabel: "/consumers-v3/consumer/return/:orderId/returnLabel",
    trackOrder: "/consumers-v3/consumer/TrackOrder",
    addAddress: "/consumers-v3/consumer/address",
    updateAddress: "/consumers-v3/consumer/address/:addressId",
    deleteAddress: "/consumers-v3/consumer/address/:addressId",
    cancelOrder: "/consumers-v3/consumer/CancelOrder",
    resendGiftcardEmail: "/consumers-v3/consumer/resendGiftCardEmail",
    retrieveUserData: "/consumers-v3/consumer/retrieveUserData",
    notifyMobile: "/consumers-v3/consumer/notification",
  };
  static mockDataPaths = { getLoyaltyVouchers: "vouchers" };
  static mockedPaths = {
    getProfileInfo: false,
    updateProfile: false,
    createProfile: false,
    getLoyaltyToken: false,
    getLoyaltyWelcomePromoStatus: false,
    getLoyaltyVouchers: false,
    lookup: false,
    getSmsSubscriptionInfo: false,
    updateSmsSubscription: false,
    createSmsSubscription: false,
    getPaymentInstruments: false,
    savePaymentInstrument: false,
    updatePaymentInstrument: false,
    deletePaymentInstrument: false,
    getOrderDetails: false,
    invoiceDownload: false,
    getOrderHistory: false,
    returnLabel: false,
    trackOrder: false,
    addAddress: false,
    updateAddress: false,
    deleteAddress: false,
    cancelOrder: false,
    resendGiftcardEmail: false,
    retrieveUserData: false,
    notifyMobile: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "consumer");
  }

  /**
   * No description
   *
   * @tags Consumer
   * @name GetProfileInfo
   * @summary Get Profile
   * @request GET:/consumers-v3/consumer
   */
  getProfileInfo = <
    ResT = GetProfileInfoData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query?: {
      /** @default false */
      includeLoyaltySummary?: boolean;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer")
        ? computed(() => mockPath("Consumer", "getProfileInfo", ``))
        : computed(() => `/consumers-v3/consumer`),
      method: "GET",
      query: query,
      key: "/consumers-v3/consumer",
      apiName: "Consumer.getProfileInfo",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetProfileInfo
   * @summary Get Profile
   * @request GET:/consumers-v3/consumer
   */
  $getProfileInfo = (
    query?: {
      /** @default false */
      includeLoyaltySummary?: boolean;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetProfileInfoData>({
      path: isMocked("/consumers-v3/consumer") ? mockPath("Consumer", "getProfileInfo", ``) : `/consumers-v3/consumer`,
      method: "GET",
      query: query,
      apiName: "Consumer.getProfileInfo",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateProfile
   * @summary Update Profile
   * @request PUT:/consumers-v3/consumer
   */
  updateProfile = <
    ResT = UpdateProfileData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ProfileInfoPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer")
        ? computed(() => mockPath("Consumer", "updateProfile", ``))
        : computed(() => `/consumers-v3/consumer`),
      method: "PUT",
      body: data,
      key: "/consumers-v3/consumer",
      apiName: "Consumer.updateProfile",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateProfile
   * @summary Update Profile
   * @request PUT:/consumers-v3/consumer
   */
  $updateProfile = (data: ProfileInfoPayload, requestParams: ApiOptions = {}) =>
    this.$request<UpdateProfileData>({
      path: isMocked("/consumers-v3/consumer") ? mockPath("Consumer", "updateProfile", ``) : `/consumers-v3/consumer`,
      method: "PUT",
      body: data,
      apiName: "Consumer.updateProfile",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CreateProfile
   * @summary Create Profile
   * @request POST:/consumers-v3/consumer
   */
  createProfile = <
    ResT = CreateProfileData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ProfileInfoPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer")
        ? computed(() => mockPath("Consumer", "createProfile", ``))
        : computed(() => `/consumers-v3/consumer`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer",
      apiName: "Consumer.createProfile",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CreateProfile
   * @summary Create Profile
   * @request POST:/consumers-v3/consumer
   */
  $createProfile = (data: ProfileInfoPayload, requestParams: ApiOptions = {}) =>
    this.$request<CreateProfileData>({
      path: isMocked("/consumers-v3/consumer") ? mockPath("Consumer", "createProfile", ``) : `/consumers-v3/consumer`,
      method: "POST",
      body: data,
      apiName: "Consumer.createProfile",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyToken
   * @summary Get Loyalty Token
   * @request POST:/consumers-v3/consumer/loyalty/token
   */
  getLoyaltyToken = <
    ResT = GetLoyaltyTokenData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: GetLoyaltyTokenPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/loyalty/token")
        ? computed(() => mockPath("Consumer", "getLoyaltyToken", ``))
        : computed(() => `/consumers-v3/consumer/loyalty/token`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/loyalty/token",
      apiName: "Consumer.getLoyaltyToken",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyToken
   * @summary Get Loyalty Token
   * @request POST:/consumers-v3/consumer/loyalty/token
   */
  $getLoyaltyToken = (data: GetLoyaltyTokenPayload, requestParams: ApiOptions = {}) =>
    this.$request<GetLoyaltyTokenData>({
      path: isMocked("/consumers-v3/consumer/loyalty/token")
        ? mockPath("Consumer", "getLoyaltyToken", ``)
        : `/consumers-v3/consumer/loyalty/token`,
      method: "POST",
      body: data,
      apiName: "Consumer.getLoyaltyToken",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyWelcomePromoStatus
   * @summary Get Loyalty Welcome Promo Status
   * @request GET:/consumers-v3/consumer/loyalty/promo/welcome
   */
  getLoyaltyWelcomePromoStatus = <
    ResT = GetLoyaltyWelcomePromoStatusData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/loyalty/promo/welcome")
        ? computed(() => mockPath("Consumer", "getLoyaltyWelcomePromoStatus", ``))
        : computed(() => `/consumers-v3/consumer/loyalty/promo/welcome`),
      method: "GET",
      key: "/consumers-v3/consumer/loyalty/promo/welcome",
      apiName: "Consumer.getLoyaltyWelcomePromoStatus",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyWelcomePromoStatus
   * @summary Get Loyalty Welcome Promo Status
   * @request GET:/consumers-v3/consumer/loyalty/promo/welcome
   */
  $getLoyaltyWelcomePromoStatus = (requestParams: ApiOptions = {}) =>
    this.$request<GetLoyaltyWelcomePromoStatusData>({
      path: isMocked("/consumers-v3/consumer/loyalty/promo/welcome")
        ? mockPath("Consumer", "getLoyaltyWelcomePromoStatus", ``)
        : `/consumers-v3/consumer/loyalty/promo/welcome`,
      method: "GET",
      apiName: "Consumer.getLoyaltyWelcomePromoStatus",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyVouchers
   * @summary Get Loyalty Vouchers
   * @request GET:/consumers-v3/consumer/loyalty/vouchers
   */
  getLoyaltyVouchers = <
    ResT = GetLoyaltyVouchersData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/loyalty/vouchers")
        ? computed(() => mockPath("Consumer", "getLoyaltyVouchers", ``))
        : computed(() => `/consumers-v3/consumer/loyalty/vouchers`),
      method: "GET",
      key: "/consumers-v3/consumer/loyalty/vouchers",
      apiName: "Consumer.getLoyaltyVouchers",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetLoyaltyVouchers
   * @summary Get Loyalty Vouchers
   * @request GET:/consumers-v3/consumer/loyalty/vouchers
   */
  $getLoyaltyVouchers = (requestParams: ApiOptions = {}) =>
    this.$request<GetLoyaltyVouchersData>({
      path: isMocked("/consumers-v3/consumer/loyalty/vouchers")
        ? mockPath("Consumer", "getLoyaltyVouchers", ``)
        : `/consumers-v3/consumer/loyalty/vouchers`,
      method: "GET",
      apiName: "Consumer.getLoyaltyVouchers",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name Lookup
   * @summary Lookup for Existing User
   * @request GET:/consumers-v3/consumer/lookup
   */
  lookup = <ResT = LookupData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      email: string;
      captchaResponse: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/lookup")
        ? computed(() => mockPath("Consumer", "lookup", ``))
        : computed(() => `/consumers-v3/consumer/lookup`),
      method: "GET",
      query: query,
      key: "/consumers-v3/consumer/lookup",
      apiName: "Consumer.lookup",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name Lookup
   * @summary Lookup for Existing User
   * @request GET:/consumers-v3/consumer/lookup
   */
  $lookup = (
    query: {
      email: string;
      captchaResponse: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<LookupData>({
      path: isMocked("/consumers-v3/consumer/lookup")
        ? mockPath("Consumer", "lookup", ``)
        : `/consumers-v3/consumer/lookup`,
      method: "GET",
      query: query,
      apiName: "Consumer.lookup",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetSmsSubscriptionInfo
   * @summary Get SMS Subscription
   * @request GET:/consumers-v3/consumer/subscription
   */
  getSmsSubscriptionInfo = <
    ResT = GetSmsSubscriptionInfoData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? computed(() => mockPath("Consumer", "getSmsSubscriptionInfo", ``))
        : computed(() => `/consumers-v3/consumer/subscription`),
      method: "GET",
      key: "/consumers-v3/consumer/subscription",
      apiName: "Consumer.getSmsSubscriptionInfo",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetSmsSubscriptionInfo
   * @summary Get SMS Subscription
   * @request GET:/consumers-v3/consumer/subscription
   */
  $getSmsSubscriptionInfo = (requestParams: ApiOptions = {}) =>
    this.$request<GetSmsSubscriptionInfoData>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? mockPath("Consumer", "getSmsSubscriptionInfo", ``)
        : `/consumers-v3/consumer/subscription`,
      method: "GET",
      apiName: "Consumer.getSmsSubscriptionInfo",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateSmsSubscription
   * @summary Update SMS Subscription
   * @request PUT:/consumers-v3/consumer/subscription
   */
  updateSmsSubscription = <
    ResT = UpdateSmsSubscriptionData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ProfileInfoPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? computed(() => mockPath("Consumer", "updateSmsSubscription", ``))
        : computed(() => `/consumers-v3/consumer/subscription`),
      method: "PUT",
      body: data,
      key: "/consumers-v3/consumer/subscription",
      apiName: "Consumer.updateSmsSubscription",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateSmsSubscription
   * @summary Update SMS Subscription
   * @request PUT:/consumers-v3/consumer/subscription
   */
  $updateSmsSubscription = (data: ProfileInfoPayload, requestParams: ApiOptions = {}) =>
    this.$request<UpdateSmsSubscriptionData>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? mockPath("Consumer", "updateSmsSubscription", ``)
        : `/consumers-v3/consumer/subscription`,
      method: "PUT",
      body: data,
      apiName: "Consumer.updateSmsSubscription",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CreateSmsSubscription
   * @summary Create SMS Subscription
   * @request POST:/consumers-v3/consumer/subscription
   */
  createSmsSubscription = <
    ResT = CreateSmsSubscriptionData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ProfileInfoPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? computed(() => mockPath("Consumer", "createSmsSubscription", ``))
        : computed(() => `/consumers-v3/consumer/subscription`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/subscription",
      apiName: "Consumer.createSmsSubscription",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CreateSmsSubscription
   * @summary Create SMS Subscription
   * @request POST:/consumers-v3/consumer/subscription
   */
  $createSmsSubscription = (data: ProfileInfoPayload, requestParams: ApiOptions = {}) =>
    this.$request<CreateSmsSubscriptionData>({
      path: isMocked("/consumers-v3/consumer/subscription")
        ? mockPath("Consumer", "createSmsSubscription", ``)
        : `/consumers-v3/consumer/subscription`,
      method: "POST",
      body: data,
      apiName: "Consumer.createSmsSubscription",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetPaymentInstruments
   * @summary Get Saved Payment Instruments
   * @request GET:/consumers-v3/consumer/payment_instruments
   */
  getPaymentInstruments = <
    ResT = GetPaymentInstrumentsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query?: {
      /** The ID of the basket */
      payment_method_id?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/payment_instruments")
        ? computed(() => mockPath("Consumer", "getPaymentInstruments", ``))
        : computed(() => `/consumers-v3/consumer/payment_instruments`),
      method: "GET",
      query: query,
      key: "/consumers-v3/consumer/payment_instruments",
      apiName: "Consumer.getPaymentInstruments",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetPaymentInstruments
   * @summary Get Saved Payment Instruments
   * @request GET:/consumers-v3/consumer/payment_instruments
   */
  $getPaymentInstruments = (
    query?: {
      /** The ID of the basket */
      payment_method_id?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetPaymentInstrumentsData>({
      path: isMocked("/consumers-v3/consumer/payment_instruments")
        ? mockPath("Consumer", "getPaymentInstruments", ``)
        : `/consumers-v3/consumer/payment_instruments`,
      method: "GET",
      query: query,
      apiName: "Consumer.getPaymentInstruments",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name SavePaymentInstrument
   * @summary Save Payment Instrument
   * @request POST:/consumers-v3/consumer/payment_instruments
   */
  savePaymentInstrument = <
    ResT = SavePaymentInstrumentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: SavePaymentInstrumentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/payment_instruments")
        ? computed(() => mockPath("Consumer", "savePaymentInstrument", ``))
        : computed(() => `/consumers-v3/consumer/payment_instruments`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/payment_instruments",
      apiName: "Consumer.savePaymentInstrument",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name SavePaymentInstrument
   * @summary Save Payment Instrument
   * @request POST:/consumers-v3/consumer/payment_instruments
   */
  $savePaymentInstrument = (data: SavePaymentInstrumentPayload, requestParams: ApiOptions = {}) =>
    this.$request<SavePaymentInstrumentData>({
      path: isMocked("/consumers-v3/consumer/payment_instruments")
        ? mockPath("Consumer", "savePaymentInstrument", ``)
        : `/consumers-v3/consumer/payment_instruments`,
      method: "POST",
      body: data,
      apiName: "Consumer.savePaymentInstrument",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdatePaymentInstrument
   * @summary Update Payment Instrument
   * @request PATCH:/consumers-v3/consumer/payment_instruments/{payment_instrument_id}
   */
  updatePaymentInstrument = <
    ResT = UpdatePaymentInstrumentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    paymentInstrumentId: MaybeRefOrGetter<string>,
    data: UpdatePaymentInstrumentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/payment_instruments/:paymentInstrumentId")
        ? computed(() =>
            mockPath("Consumer", "updatePaymentInstrument", `paymentInstrumentId:${toValue(paymentInstrumentId)}`),
          )
        : computed(() => `/consumers-v3/consumer/payment_instruments/${toValue(paymentInstrumentId)}`),
      method: "PATCH",
      body: data,
      key: "/consumers-v3/consumer/payment_instruments/:paymentInstrumentId",
      apiName: "Consumer.updatePaymentInstrument",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdatePaymentInstrument
   * @summary Update Payment Instrument
   * @request PATCH:/consumers-v3/consumer/payment_instruments/{payment_instrument_id}
   */
  $updatePaymentInstrument = (
    paymentInstrumentId: string,
    data: UpdatePaymentInstrumentPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<UpdatePaymentInstrumentData>({
      path: isMocked("/consumers-v3/consumer/payment_instruments/:paymentInstrumentId")
        ? mockPath("Consumer", "updatePaymentInstrument", `paymentInstrumentId:${toValue(paymentInstrumentId)}`)
        : `/consumers-v3/consumer/payment_instruments/${paymentInstrumentId}`,
      method: "PATCH",
      body: data,
      apiName: "Consumer.updatePaymentInstrument",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name DeletePaymentInstrument
   * @summary Delete Payment Instrument
   * @request DELETE:/consumers-v3/consumer/payment_instruments/{payment_instrument_id}
   */
  deletePaymentInstrument = <
    ResT = DeletePaymentInstrumentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    paymentInstrumentId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/payment_instruments/:paymentInstrumentId")
        ? computed(() =>
            mockPath("Consumer", "deletePaymentInstrument", `paymentInstrumentId:${toValue(paymentInstrumentId)}`),
          )
        : computed(() => `/consumers-v3/consumer/payment_instruments/${toValue(paymentInstrumentId)}`),
      method: "DELETE",
      key: "/consumers-v3/consumer/payment_instruments/:paymentInstrumentId",
      apiName: "Consumer.deletePaymentInstrument",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name DeletePaymentInstrument
   * @summary Delete Payment Instrument
   * @request DELETE:/consumers-v3/consumer/payment_instruments/{payment_instrument_id}
   */
  $deletePaymentInstrument = (paymentInstrumentId: string, requestParams: ApiOptions = {}) =>
    this.$request<DeletePaymentInstrumentData>({
      path: isMocked("/consumers-v3/consumer/payment_instruments/:paymentInstrumentId")
        ? mockPath("Consumer", "deletePaymentInstrument", `paymentInstrumentId:${toValue(paymentInstrumentId)}`)
        : `/consumers-v3/consumer/payment_instruments/${paymentInstrumentId}`,
      method: "DELETE",
      apiName: "Consumer.deletePaymentInstrument",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetOrderDetails
   * @summary Get Order Details
   * @request GET:/consumers-v3/consumer/purchase/{orderId}
   */
  getOrderDetails = <
    ResT = GetOrderDetailsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    orderId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/purchase/:orderId")
        ? computed(() => mockPath("Consumer", "getOrderDetails", `orderId:${toValue(orderId)}`))
        : computed(() => `/consumers-v3/consumer/purchase/${toValue(orderId)}`),
      method: "GET",
      key: "/consumers-v3/consumer/purchase/:orderId",
      apiName: "Consumer.getOrderDetails",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetOrderDetails
   * @summary Get Order Details
   * @request GET:/consumers-v3/consumer/purchase/{orderId}
   */
  $getOrderDetails = (orderId: string, requestParams: ApiOptions = {}) =>
    this.$request<GetOrderDetailsData>({
      path: isMocked("/consumers-v3/consumer/purchase/:orderId")
        ? mockPath("Consumer", "getOrderDetails", `orderId:${toValue(orderId)}`)
        : `/consumers-v3/consumer/purchase/${orderId}`,
      method: "GET",
      apiName: "Consumer.getOrderDetails",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name InvoiceDownload
   * @summary Invoice Download
   * @request POST:/consumers-v3/consumer/purchase/invoice/download
   */
  invoiceDownload = <
    ResT = InvoiceDownloadData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      /** The response from CAPTCHA */
      captchaResponse: string;
    },
    data: InvoiceDownloadPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/purchase/invoice/download")
        ? computed(() => mockPath("Consumer", "invoiceDownload", ``))
        : computed(() => `/consumers-v3/consumer/purchase/invoice/download`),
      method: "POST",
      query: query,
      body: data,
      key: "/consumers-v3/consumer/purchase/invoice/download",
      apiName: "Consumer.invoiceDownload",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name InvoiceDownload
   * @summary Invoice Download
   * @request POST:/consumers-v3/consumer/purchase/invoice/download
   */
  $invoiceDownload = (
    query: {
      /** The response from CAPTCHA */
      captchaResponse: string;
    },
    data: InvoiceDownloadPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<InvoiceDownloadData>({
      path: isMocked("/consumers-v3/consumer/purchase/invoice/download")
        ? mockPath("Consumer", "invoiceDownload", ``)
        : `/consumers-v3/consumer/purchase/invoice/download`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Consumer.invoiceDownload",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetOrderHistory
   * @summary Get Order History
   * @request POST:/consumers-v3/consumer/purchasehistory
   */
  getOrderHistory = <
    ResT = GetOrderHistoryData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: GetOrderHistoryPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/purchasehistory")
        ? computed(() => mockPath("Consumer", "getOrderHistory", ``))
        : computed(() => `/consumers-v3/consumer/purchasehistory`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/purchasehistory",
      apiName: "Consumer.getOrderHistory",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name GetOrderHistory
   * @summary Get Order History
   * @request POST:/consumers-v3/consumer/purchasehistory
   */
  $getOrderHistory = (data: GetOrderHistoryPayload, requestParams: ApiOptions = {}) =>
    this.$request<GetOrderHistoryData>({
      path: isMocked("/consumers-v3/consumer/purchasehistory")
        ? mockPath("Consumer", "getOrderHistory", ``)
        : `/consumers-v3/consumer/purchasehistory`,
      method: "POST",
      body: data,
      apiName: "Consumer.getOrderHistory",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name ReturnLabel
   * @summary Return Label
   * @request POST:/consumers-v3/consumer/return/{orderId}/returnLabel
   */
  returnLabel = <ResT = ReturnLabelData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    orderId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/return/:orderId/returnLabel")
        ? computed(() => mockPath("Consumer", "returnLabel", `orderId:${toValue(orderId)}`))
        : computed(() => `/consumers-v3/consumer/return/${toValue(orderId)}/returnLabel`),
      method: "POST",
      key: "/consumers-v3/consumer/return/:orderId/returnLabel",
      apiName: "Consumer.returnLabel",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name ReturnLabel
   * @summary Return Label
   * @request POST:/consumers-v3/consumer/return/{orderId}/returnLabel
   */
  $returnLabel = (orderId: string, requestParams: ApiOptions = {}) =>
    this.$request<ReturnLabelData>({
      path: isMocked("/consumers-v3/consumer/return/:orderId/returnLabel")
        ? mockPath("Consumer", "returnLabel", `orderId:${toValue(orderId)}`)
        : `/consumers-v3/consumer/return/${orderId}/returnLabel`,
      method: "POST",
      apiName: "Consumer.returnLabel",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name TrackOrder
   * @summary Track Order
   * @request POST:/consumers-v3/consumer/TrackOrder
   */
  trackOrder = <ResT = TrackOrderData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: TrackOrderPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/TrackOrder")
        ? computed(() => mockPath("Consumer", "trackOrder", ``))
        : computed(() => `/consumers-v3/consumer/TrackOrder`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/TrackOrder",
      apiName: "Consumer.trackOrder",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name TrackOrder
   * @summary Track Order
   * @request POST:/consumers-v3/consumer/TrackOrder
   */
  $trackOrder = (data: TrackOrderPayload, requestParams: ApiOptions = {}) =>
    this.$request<TrackOrderData>({
      path: isMocked("/consumers-v3/consumer/TrackOrder")
        ? mockPath("Consumer", "trackOrder", ``)
        : `/consumers-v3/consumer/TrackOrder`,
      method: "POST",
      body: data,
      apiName: "Consumer.trackOrder",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name AddAddress
   * @summary Add Address
   * @request POST:/consumers-v3/consumer/address
   */
  addAddress = <ResT = AddAddressData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: AddAddressPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/address")
        ? computed(() => mockPath("Consumer", "addAddress", ``))
        : computed(() => `/consumers-v3/consumer/address`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/address",
      apiName: "Consumer.addAddress",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name AddAddress
   * @summary Add Address
   * @request POST:/consumers-v3/consumer/address
   */
  $addAddress = (data: AddAddressPayload, requestParams: ApiOptions = {}) =>
    this.$request<AddAddressData>({
      path: isMocked("/consumers-v3/consumer/address")
        ? mockPath("Consumer", "addAddress", ``)
        : `/consumers-v3/consumer/address`,
      method: "POST",
      body: data,
      apiName: "Consumer.addAddress",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateAddress
   * @summary Update Address
   * @request PUT:/consumers-v3/consumer/address/{addressId}
   */
  updateAddress = <
    ResT = UpdateAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    addressId: MaybeRefOrGetter<string>,
    data: UpdateAddressPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/address/:addressId")
        ? computed(() => mockPath("Consumer", "updateAddress", `addressId:${toValue(addressId)}`))
        : computed(() => `/consumers-v3/consumer/address/${toValue(addressId)}`),
      method: "PUT",
      body: data,
      key: "/consumers-v3/consumer/address/:addressId",
      apiName: "Consumer.updateAddress",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name UpdateAddress
   * @summary Update Address
   * @request PUT:/consumers-v3/consumer/address/{addressId}
   */
  $updateAddress = (addressId: string, data: UpdateAddressPayload, requestParams: ApiOptions = {}) =>
    this.$request<UpdateAddressData>({
      path: isMocked("/consumers-v3/consumer/address/:addressId")
        ? mockPath("Consumer", "updateAddress", `addressId:${toValue(addressId)}`)
        : `/consumers-v3/consumer/address/${addressId}`,
      method: "PUT",
      body: data,
      apiName: "Consumer.updateAddress",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name DeleteAddress
   * @summary Delete Address
   * @request DELETE:/consumers-v3/consumer/address/{addressId}
   */
  deleteAddress = <
    ResT = DeleteAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    addressId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/address/:addressId")
        ? computed(() => mockPath("Consumer", "deleteAddress", `addressId:${toValue(addressId)}`))
        : computed(() => `/consumers-v3/consumer/address/${toValue(addressId)}`),
      method: "DELETE",
      key: "/consumers-v3/consumer/address/:addressId",
      apiName: "Consumer.deleteAddress",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name DeleteAddress
   * @summary Delete Address
   * @request DELETE:/consumers-v3/consumer/address/{addressId}
   */
  $deleteAddress = (addressId: string, requestParams: ApiOptions = {}) =>
    this.$request<DeleteAddressData>({
      path: isMocked("/consumers-v3/consumer/address/:addressId")
        ? mockPath("Consumer", "deleteAddress", `addressId:${toValue(addressId)}`)
        : `/consumers-v3/consumer/address/${addressId}`,
      method: "DELETE",
      apiName: "Consumer.deleteAddress",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CancelOrder
   * @summary Cancel Order
   * @request POST:/consumers-v3/consumer/CancelOrder
   */
  cancelOrder = <ResT = CancelOrderData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** The response from CAPTCHA */
      captchaResponse: string;
    },
    data: CancelOrderPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/CancelOrder")
        ? computed(() => mockPath("Consumer", "cancelOrder", ``))
        : computed(() => `/consumers-v3/consumer/CancelOrder`),
      method: "POST",
      query: query,
      body: data,
      key: "/consumers-v3/consumer/CancelOrder",
      apiName: "Consumer.cancelOrder",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name CancelOrder
   * @summary Cancel Order
   * @request POST:/consumers-v3/consumer/CancelOrder
   */
  $cancelOrder = (
    query: {
      /** The response from CAPTCHA */
      captchaResponse: string;
    },
    data: CancelOrderPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CancelOrderData>({
      path: isMocked("/consumers-v3/consumer/CancelOrder")
        ? mockPath("Consumer", "cancelOrder", ``)
        : `/consumers-v3/consumer/CancelOrder`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Consumer.cancelOrder",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name ResendGiftcardEmail
   * @summary Resend Giftcard Email
   * @request POST:/consumers-v3/consumer/resendGiftCardEmail
   */
  resendGiftcardEmail = <
    ResT = ResendGiftcardEmailData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ResendGiftcardEmailPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/resendGiftCardEmail")
        ? computed(() => mockPath("Consumer", "resendGiftcardEmail", ``))
        : computed(() => `/consumers-v3/consumer/resendGiftCardEmail`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/resendGiftCardEmail",
      apiName: "Consumer.resendGiftcardEmail",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name ResendGiftcardEmail
   * @summary Resend Giftcard Email
   * @request POST:/consumers-v3/consumer/resendGiftCardEmail
   */
  $resendGiftcardEmail = (data: ResendGiftcardEmailPayload, requestParams: ApiOptions = {}) =>
    this.$request<ResendGiftcardEmailData>({
      path: isMocked("/consumers-v3/consumer/resendGiftCardEmail")
        ? mockPath("Consumer", "resendGiftcardEmail", ``)
        : `/consumers-v3/consumer/resendGiftCardEmail`,
      method: "POST",
      body: data,
      apiName: "Consumer.resendGiftcardEmail",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name RetrieveUserData
   * @summary Retrieve User Data
   * @request POST:/consumers-v3/consumer/retrieveUserData
   */
  retrieveUserData = <
    ResT = RetrieveUserDataData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: RetrieveUserDataPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/retrieveUserData")
        ? computed(() => mockPath("Consumer", "retrieveUserData", ``))
        : computed(() => `/consumers-v3/consumer/retrieveUserData`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/retrieveUserData",
      apiName: "Consumer.retrieveUserData",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name RetrieveUserData
   * @summary Retrieve User Data
   * @request POST:/consumers-v3/consumer/retrieveUserData
   */
  $retrieveUserData = (data: RetrieveUserDataPayload, requestParams: ApiOptions = {}) =>
    this.$request<RetrieveUserDataData>({
      path: isMocked("/consumers-v3/consumer/retrieveUserData")
        ? mockPath("Consumer", "retrieveUserData", ``)
        : `/consumers-v3/consumer/retrieveUserData`,
      method: "POST",
      body: data,
      apiName: "Consumer.retrieveUserData",
      mobileAppTracking: "false",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name NotifyMobile
   * @summary Notify Users on Mobile when item is back in stock
   * @request PUT:/consumers-v3/consumer/notification
   */
  notifyMobile = <
    ResT = NotifyMobileData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: NotifyMobilePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/notification")
        ? computed(() => mockPath("Consumer", "notifyMobile", ``))
        : computed(() => `/consumers-v3/consumer/notification`),
      method: "PUT",
      body: data,
      key: "/consumers-v3/consumer/notification",
      apiName: "Consumer.notifyMobile",
      mobileAppTracking: "false",
      ...params,
    });
  /**
   * No description
   *
   * @tags Consumer
   * @name NotifyMobile
   * @summary Notify Users on Mobile when item is back in stock
   * @request PUT:/consumers-v3/consumer/notification
   */
  $notifyMobile = (data: NotifyMobilePayload, requestParams: ApiOptions = {}) =>
    this.$request<NotifyMobileData>({
      path: isMocked("/consumers-v3/consumer/notification")
        ? mockPath("Consumer", "notifyMobile", ``)
        : `/consumers-v3/consumer/notification`,
      method: "PUT",
      body: data,
      apiName: "Consumer.notifyMobile",
      mobileAppTracking: "false",
      ...requestParams,
    });
}
