import type { MediaMeta } from '#types/seo'
import type { Responsive } from '#types/common'
import type { ResponsiveMedia } from '#types/content'
import type { CMSPicture, CMSVideo } from '#types/media'

interface Image {
  src: Responsive
  width: Responsive<number>
  height: Responsive<number>
  alt: string
  meta: Partial<MediaMeta>
}

interface Video {
  src: Responsive
  width: Responsive<number>
  height: Responsive<number>
  poster: Responsive
  meta: Pick<MediaMeta, 'title' | 'description'>
}

type MediaEntries<T> = [keyof Responsive, T][]

type ResponsiveMediaObject = { images: Image } | { video: Video }

/**
 * Get media URL for provided URL.
 * @description It's needed because CoreMedia media URLs are not absolute.
 * We can get rid of this once we have full migration to Cloudinary.
 * @param url
 * @param baseUrl
 */
export const getUrl = (url: string | undefined, baseUrl: string): string => {
  if (!url) return ''
  return url.startsWith('http') ? url : `${baseUrl}${url}`
}

/**
 * Extract metadata from the first media entry.
 * @param media
 */
const getMeta = (media: MediaEntries<CMSPicture | CMSVideo>) => {
  const [[_, def = {} as CMSPicture | CMSVideo]] = media

  return {
    alt: (def as CMSPicture).alt,
    meta: {
      title: def.seoTitle,
      description: def.seoDescription
    }
  }
}

/**
 * Generates responsive image sources based on the provided images and URL transformation function.
 *
 * @param images - The array of responsive image entries.
 * @param baseUrl - Base media url.
 * @returns A responsive image object with transformed URLs and metadata.
 * @category Utils
 */
const getPicture = (images: MediaEntries<CMSPicture>, baseUrl: string): Image => {
  return {
    ...images.reduce((acc, [br, image]) => {
      acc.src[br] = getUrl(image.url, baseUrl)
      acc.width[br] = image.width
      acc.height[br] = image.height
      return acc
    }, { src: {}, width: {}, height: {} } as Pick<Image, 'src' | 'width' | 'height'>),
    ...getMeta(images)
  }
}

/**
 * Generates responsive video sources based on the provided videos and URL transformation function.
 *
 * @param videos - The array of responsive video entries.
 * @param baseUrl - Base media url.
 * @returns A responsive object containing transformed video sources with metadata.
 * @category Utils
 */
const getVideo = (videos: MediaEntries<CMSVideo>, baseUrl: string): Video => {
  const { meta } = getMeta(videos)

  const obj = videos.reduce((acc, [br, video]) => ({
    ...acc,
    [br]: {
      src: getUrl(video.url, baseUrl),
      width: video.width,
      height: video.height,
      poster: video.posterUrl,
      tracks: video.tracks
    }
  }), {})

  return {
    ...swapObjectKeys(obj),
    meta
  } as Video
}

/**
 * Map CMS Media response to base components props structure
 * @param media {ResponsiveMedia}
 * @param baseUrl {string}
 * @category Utils
 */
export const getResponsiveMedia = (
  media: ResponsiveMedia | undefined = {} as any,
  baseUrl: string,
): ResponsiveMediaObject => {
  if (media === null) return {} as ResponsiveMediaObject

  const defaultMedia = media.sm || media.md || media.lg
  const mediaType = defaultMedia?.type

  if (!mediaType) return {} as ResponsiveMediaObject

  const mediaEntries = Object.entries(media)
    .filter(([_, entry]) => entry?.type === mediaType)

  switch (mediaType) {
    case 'CMPicture':
      return { images: getPicture(mediaEntries as MediaEntries<CMSPicture>, baseUrl) }
    case 'CMVideo':
      return { video: getVideo(mediaEntries as MediaEntries<CMSVideo>, baseUrl) }
  }
}
