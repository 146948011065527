<template>
  <div v-show="!hidden" ref="benefitsBarRef">
    <div
      v-style:bg="content.backgroundColor"
      class="relative p-4"
      :style="{ 'background-image': bgImage, 'background-size': 'cover' }"
    >
      <div
        class="f-col container md:flex-row"
        :class="{
          'md:justify-center md:text-center': content.alignment === 'center',
        }"
      >
        <div class="flex items-center" :class="{ 'justify-center': content.alignment === 'center' }">
          <base-link :to="content.mediaTarget?.url">
            <span class="sr-only">{{ content.mediaTarget?.text }}</span>
            <base-picture v-if="images" v-bind="images" class="mb-4 mr-4 max-w-40 md:mb-0" />
          </base-link>
        </div>
        <div>
          <h2 v-if="content.title" v-style:c="content.titleColor" :class="content.titleStyle">
            <base-link :to="content.mediaTarget?.url">
              {{ content.title }}
            </base-link>
          </h2>
          <p v-if="content.subtitle" v-style:c="content.subtitleColor" :class="content.subtitleStyle">
            <span>{{ content.subtitle }}</span>
            <base-link v-if="content.infoIcon" :to="content.infoIcon.url">
              <vf-icon class="ml-1 i-block" name="info" />
            </base-link>
          </p>
          <div
            v-if="content.targets?.length"
            v-style:c="content.subtitleColor"
            class="mt-1 flex gap-4 wrap"
            :class="{ 'md:justify-center': content.alignment === 'center' }"
          >
            <vf-link
              v-for="{ id, url, text } in content.targets"
              :key="id"
              :to="url"
            >
              {{ text }}
            </vf-link>
          </div>
        </div>
      </div>
      <base-button
        v-if="content.showCloseButton"
        v-style:c="content.titleColor"
        class="absolute right-0 top-0 self-center p-3"
        @click="hidden = true"
      >
        <span class="sr-only">{{ $t.hide }}</span>
        <vf-icon name="close" />
      </base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BenefitBarContent } from '#types/components/cms/benefit-bar'

const props = defineProps<{
  content: BenefitBarContent
}>()

const benefitsBarRef = useState<HTMLDivElement>('benefitsBarRef')

const { getMedia, getMediaUrl } = useCms()

const hidden = useCookie<boolean>('benefitBarClosed')

const { images } = getMedia({ sm: props.content.logo })

const bgImage = computed(
  () => props.content.backgroundImage ? `url(${getMediaUrl(props.content.backgroundImage.url)})` : ''
)
</script>
