import breakpoints from '#core/design-system/breakpoints.json'
import type { Responsive } from '#types/common'
import type { InlineGridItem, InlineGridSection } from '#types/components/product/list'

/**
 * Converts inline-grid eSpot items array into an object with keys as positions in the grid.
 * Each column covered represents one index, so if an eSpot covers 4 columns this takes up 4 indices
 * and future eSpots based on position will need to account for that.
 * If variant is full width CMS user must use relative positioning with left alignment.
 * Each value is a "sections" object with a name, id, some positioning information and an items array.
 * @param items An array of inline grid items.
 * @param cols The number of columns for each breakpoint.
 * @param productsLength The total number of products.
 * @returns An array of inline grid sections.
 * @category Utils
 */
export const getResponsivePosition = (
  items: InlineGridItem[],
  cols: Responsive<number>,
  productsLength: number
): InlineGridSection[] => {
  const offset: Record<string, number> = { sm: 0, md: 0, lg: 0 }
  const brs = Object.keys(breakpoints) as (keyof Responsive)[]

  return [...items].map((item) => ({
    name: `inline-grid-${item.id}`,
    items: [item.promotionTracking ? { ...item.component, promotionTracking: item.promotionTracking } : item.component],
    id: item.id,
    ...brs.reduce(({ column, display, position, row, size }, breakpoint) => {
      const columns = cols[breakpoint] || 0
      const responsivePosition = getValueForBreakpoint(breakpoint, item?.responsivePosition)
      const [pos, colSpan, rowSpan] = (responsivePosition?.[`col${columns}`] || '0/1/1').split('/')
      const _position = +pos
      const _colSpan = +(colSpan || '1')
      const _rowSpan = +(rowSpan || '1')

      row[breakpoint] = `${Math.ceil(_position / columns) || 0} / span ${_rowSpan}`
      column[breakpoint] = `${_position % columns || columns} / span ${_colSpan}`
      position[breakpoint] = _position
      offset[breakpoint] += _colSpan
      size[breakpoint] = _colSpan

      const isOutOfBounds = _position > (productsLength + offset[breakpoint])

      display[breakpoint] = (isOutOfBounds || item.hideOnBreakpoint?.[breakpoint]) ? 'none' : 'block'

      return { column, display, position, row, size }
    }, {
      column: {},
      display: {},
      position: {},
      row: {},
      size: {}
    })
  } as InlineGridSection))
}
