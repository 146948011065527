export const useHeaderStore = defineStore('header', () => {
  const { excludeStickyRoute, showOnScrollUp } = useAppConfig().components.vf.header
  const { hooks } = useNuxtApp()
  const router = useRouter()
  const headerRef = ref()
  const headerContentRef = ref()
  const promoBarRef = ref()
  const navigationRef = ref()
  const utilityRef = ref()
  const expanded = ref(true)
  const minimized = ref(false)
  const pageScrolledUp = ref(false)
  const transparent = ref(false)

  if (showOnScrollUp) {
    const { directions } = useScroll(document)
    const { bottom, top } = toRefs(directions)

    watchDebounced(top, (value) => {
      if (value) pageScrolledUp.value = true
    }, { debounce: 200, maxWait: 500 })

    watchDebounced(bottom, (value) => {
      if (value) pageScrolledUp.value = false
    }, { debounce: 200, maxWait: 500 })
  }

  const headerBounds = useElementBounding(headerRef)
  const promoBarBounds = useElementBounding(promoBarRef)
  const navigationBounds = useElementBounding(navigationRef)
  const utilityBounds = useElementBounding(utilityRef)

  const isSticky = computed(() =>
    !excludeStickyRoute.some((prefix) => router.currentRoute.value.name?.toString().startsWith(prefix))
  )

  const height = computed(() => transparent.value ? getOuterHeight(headerContentRef.value) : headerBounds.height.value)
  const margin = computed(() => isSticky.value ? headerBounds.bottom.value - 1 || 0 : 0)
  const offset = computed(() => pageScrolledUp.value ? margin.value : 0)

  hooks.hook('page:finish', () => {
    expanded.value = true
  })

  return {
    pageScrolledUp,
    expanded,
    minimized,
    transparent,
    sticky: {
      active: isSticky,
      margin,
      offset
    },
    refs: {
      header: headerRef,
      headerContent: headerContentRef,
      navigation: navigationRef,
      promoBar: promoBarRef,
      utility: utilityRef
    },
    height: {
      full: computed(() => height.value + promoBarBounds.height.value),
      header: headerBounds.height,
      navigation: navigationBounds.height,
      promoBar: promoBarBounds.height,
      utility: utilityBounds.height
    }
  }
})
