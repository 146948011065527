import type { GiftOption } from '#types/cart'

/**
 * Maps gift option for digital products to match API contract.
 * @param giftOption The Gift Option object.
 * @param giftOption.email email property
 * @param giftOption.to to property
 * @param giftOption.message message property
 * @returns Gift Option Object for digital product (like gift card).
 * @category Utils
 */
export default function getDigitalGiftOption(giftOption?: GiftOption) {
  if (!giftOption) return {}
  const { email = '', to = '', message = '' } = giftOption || {}
  return {
    isDigitalProduct: true,
    giftOption: { email, to, message }
  }
}
