<template>
  <article class="relative h-full f-col space-y-2">
    <base-link class="grow" :to="url">
      <base-picture
        :alt="image.alt"
        v-bind="size"
        class="w-full"
        :src="getResponsiveImageTransformations(image.src, {
          ...size,
          ...transformations,
        })"
      />
    </base-link>
    <div v-if="rating?.showScore" class="flex items-center gap-1">
      <vf-rating size="xs" :value="rating?.score" />
    </div>
    <base-link class="grow" :to="url">
      <p class="line-clamp-2 text-sm fw-medium ">
        {{ title }}
      </p>
    </base-link>
    <product-pricing class="grow text-sm" :currency :price />
    <vf-button
      v-if="quickshop"
      class="relative mt-4"
      :class="brandClasses.addButton"
      data-test-id="product-card-add"
      :size="addButtonSize"
      variant="primary"
      @click="DialogQuickshop.open({ product, closeOnAdd: closeQuickshopOnAdd })"
    >
      <vf-icon class="mr-1" name="plus" />
      {{ $t.add }}
    </vf-button>
    <dialog-quickshop class-content="<lg:pb-0" position="bottom" :size="$viewport.lg ? 'lg' : undefined" />
  </article>
</template>

<script lang="ts" setup>
import type { ProductVariants } from '#root/api/clients/product/data-contracts'
import type { ImageItem } from '#types/media'
import type { MonetateRating } from '#types/p13n'
import type { PriceFull } from '#types/product'

const { title, url, image, rating, id, currency, price, quickshop } = withDefaults(defineProps<{
  /**
   * Product name or title
   */
  title: string
  /**
   * Path to product detail page
   */
  url: string
  /**
   * Default product image
   */
  image: ImageItem
  /**
   * Product rating data
   */
  rating?: MonetateRating
  /**
   * Product id
   */
  id: string
  /**
   * Product price currency
   */
  currency: string
  /**
   * Product price data
   */
  price: PriceFull
  /**
   * Quickshop Toggle
   */
  quickshop: boolean
}>(), { quickshop: true })

const {
  addButtonSize,
  brandClasses,
  presets: { size, transformations } = {}
} = useAppConfig().components.cms.productRecommendations
const { dialogOptions } = useAppConfig().components.product.card
const { $viewport } = useNuxtApp()
const route = useRoute()

const product = computed(() => ({
  id,
  name: title,
  url,
  price,
  currency,
  attributes: [],
  variants: {} as ProductVariants
}))

const closeQuickshopOnAdd = route.name?.toString().includes('cart')

const DialogQuickshop = createDialog('quickshop', dialogOptions)
</script>
