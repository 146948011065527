<template>
  <cms-shared-button v-if="target" ref="elementRef" v-bind="target" @click="handlePromotionClick" />
</template>

<script lang="ts" setup>
import type { CtaButtonContent } from '#types/components/cms/cta-button'

const { content } = defineProps<{ content: CtaButtonContent }>()

const { promotionTracking, target, name } = content

const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)
</script>
