import type { PromoSummary } from '#root/api/clients/cart/data-contracts'

/**
 * Returns callout message for an applied promo. If it is defined as an approaching
 * promotion, it returns promotion name. Otherwise it returns raw callout message.
 * @param promo Applied promo object.
 * @returns Promo messaging string.
 */
export const getAppliedPromoCalloutMessage = (promo: PromoSummary) => {
  const messages = promo.calloutMessage?.split('|').map((message) => message.trim())
  const isApproachingPromo = messages?.length === 3
  return isApproachingPromo ? (promo.promotionName || messages[2]) : promo.calloutMessage
}
